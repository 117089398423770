import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";

import "./styles.scss";

import * as actions from "../../actions/global_parameters.actions";
import * as paths from "../../config/paths";
import { ifUnauthorized } from "../../utils/authentication";
import Preloader from "../../components/Preloader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Timer from "../../components/Timer/Timer";

import Button from "../../components/Buttons/Button";
import Barcode from "react-barcode";

function Success(props) {
  const [showPreloader, setShowPreloader] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [orderState, setOrderState] = useState("");
  const history = useHistory();
  const [orderType, setOrderType] = useState();
  const [idDetailOrder, setIdDetailOrder] = useState(null);
  const [dimension, setDimension]=useState("")


  useEffect(() => {
    ifUnauthorized(history, verifyPayment);
  }, []);

  const SECONDS_TO_REDIRECT = 300;

  const verifyPayment = () => {
    const query = new URLSearchParams(props.location.search);
    const sessionId = query.get("session_id");
    if (sessionId) {
      actions
        .checkoutStatus({ session_id: `${sessionId}` })
        .then((response) => {
          if (response.type === "SUCCESSFULLY") {
            console.log("SUCCESS PAYMENT");
            console.log(response);
            setPaymentSuccess(true);
            setOrderNumber(response.payload.data.attributes.order_number);
            setOrderState(
              response.payload.data.relationships.money_transaction.meta.data
                .relationships.transaction_state.meta.data.attributes.name
            );
            setIdDetailOrder(response.payload.data.id);
            setOrderType(response.payload.data.relationships.order_type);
            setDimension(
              response.payload.data.relationships.order_type.meta.data.relationships.metadata_attributes.meta.data.find(
                (d) => d.attributes.key === "Dimensiones"
              )
            );
          }
          setShowPreloader(false);
        })
        .catch((error) => {
          console.log(error.response);
          setShowPreloader(false);
        });
    } else {
      history.push(paths.HOME);
    }
  };

  const onSuccessTimer = () => {
    history.push(paths.ORDERS.DETAIL.PATH(idDetailOrder));
  };

  return (
    <div className="container container-success">
      <div className="custom-block custom-block__form custom-block__form--style">
        {showPreloader ? (
          <Preloader />
        ) : paymentSuccess ? (
          <div className="payment-message-box">
            <div className="button-print">
              <Button
                className="wizard-next-button"
                onClick={() => window.print()}
              >
                Imprimir Pantalla
              </Button>
            </div>

            <div className="header-payment-container">
              <h4>Su pago ha sido procesado con éxito!</h4>
              <FontAwesomeIcon icon={["far", "check-circle"]} size="2x" />
            </div>
            <div className="barcode">
              <Barcode value={orderNumber} />
            </div>
            <div className="details-payment-container">
              {/* <div className="details-payment-info">
                <div className="details-payment-container-info">
                  <label className="l-detail-payment-title">
                    Número del orden:
                  </label>
                </div>
                <div className="details-payment-container-info">
                  <label className="l-detail-payment-description">
                    {orderNumber}
                  </label>
                </div>
              </div>
              <div className="details-payment-info">
                <div className="details-payment-container-info">
                  <label className="l-detail-payment-title">
                    Estado del pedido:{" "}
                  </label>
                </div>
                <div className="details-payment-container-info">
                  <label className="l-detail-payment-description">
                    {orderState}
                  </label>
                </div>
              </div> */}

              <div className="view-delivery-box2">
                <div className="step-title2">
                  Debe seguir los pasos para hacernos llegar su caja a nuestro
                  almacén
                </div>
                <div>
                  <strong>Paso 1: </strong>
                  <br />
                  Ud. ha pagado por el envío de una{" "}
                  {orderType ? orderType.meta.data.attributes.name : ""} con las
                  dimensiones de{" "}
                  {
                    dimension?dimension.attributes.value:""
                  }
                  , la cual puede comprarla en las oficinas de UPS o comprarlas
                  en el sitio web de{" "}
                  <a
                    href="https://www.theupsstore.com/pack-ship"
                    target="_blank"
                  >
                    The UPS Store.
                  </a>{" "}
                  Favor de tomar nota de las dimensiones especificadas
                </div>
                <br />
                <div>
                  <strong>Paso 2: </strong>
                  <ul>
                    <li>
                      Debe <strong>IMPRIMIR</strong> esta pantalla
                    </li>
                    <li>
                      El código de barras en la parte de abajo debe recortarlo y
                      pegarlo en el exterior de la caja que va enviar
                    </li>
                    <li>
                      El resto de esta hoja debe colocarlo dentro de su caja
                    </li>
                  </ul>
                </div>

                <div>
                  <strong>Paso 3: </strong>
                  <span>
                    Favor enviar la caja a la dirección de nuestro almacén:
                  </span>
                </div>
                <span>EasyBolBox</span>
                <span>1601 NW 93 Ave</span>
                <span>
                  Miami, Fl. 33172.{" "}
                  <a
                    href="https://goo.gl/maps/oGqB1uQwLS5UA3zB7"
                    target="_blank"
                  >
                    Ver en mapa
                  </a>
                </span>
                <br />
                {/* <div>
                  <strong>Paso 4: </strong>
                  <span>
                    Favor enviar los siguientes DATOS para el seguimiento de su
                    caja:
                  </span>
                  <ul>
                    <li>Nombre completo, teléfono, email del REMITENTE</li>
                    <li>
                      Foto del número de orden (tracking number del servicio
                      utilizado "UPS")
                    </li>
                    <li>
                      Confirmar su número de contacto (teléfono, whatsapp)
                    </li>
                  </ul>
                  <span>Puede enviar estos datos por email o whatsapp:</span>
                  <ul>
                    <li type="none">
                      Email:{" "}
                      <a href="mailto:contact@easybolbox.com" target="_blank">
                        contact@easybolbox.com
                      </a>
                    </li>
                    <li type="none">
                      Whatsapp:{" "}
                      <a
                        href="https://wa.me/14084060306?text=Hello%20EasyBolBox"
                        target="_blank"
                      >
                        {" "}
                        +1 (408) 406-0306
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div>
                  <strong>Paso 4: </strong>
                  <span>
                    Ante cualquier duda o consulta, favor comunicarse a los
                    siguientes teléfonos de EEUU o al Correo:
                  </span>
                  <ul>
                    <li type="none">
                      Email:{" "}
                      <a href="mailto:contact@easybolbox.com" target="_blank">
                        contact@easybolbox.com
                      </a>
                    </li>
                    <li type="none">
                      Whatsapp:{" "}
                      <a
                        href="https://wa.me/14084060306?text=Hello%20EasyBolBox"
                        target="_blank"
                      >
                        {" "}
                        +1 (408) 406-0306
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <strong>Nota: </strong>
                  <span>
                    Favor enviar estos datos a la brevedad posible, caso
                    contrario pueden generarse gastos adicionales de
                    almacenamiento.
                  </span>
                </div>
                <h5 className="text-danger mt-3">
                  Debe cortar esta sección y pegarlo fuera de su caja.
                </h5>
                <div className="d-flex flex-row justify-content-center">
                  <FontAwesomeIcon icon={["fas", "cut"]} size="lg" />
                  <div className="barcode-down">
                    <h2
                      className="text-center font-weight-bold"
                      style={{ color: "#043777" }}
                    >
                      EasyBolBox{" "}
                      <div style={{ color: "#db7c04" }}>Tracking Number</div>
                    </h2>

                    <Barcode value={orderNumber} />
                  </div>
                </div>
              </div>
            </div>

            <Timer
              seconds={SECONDS_TO_REDIRECT}
              onSuccessTimer={onSuccessTimer}
            ></Timer>
          </div>
        ) : (
          <div className="payment-message-box">
            <div className="header-payment-container fail">
              <h4>Ha ocurrido un problema con su pago, vuelva a intentarlo.</h4>
              <FontAwesomeIcon icon={["far", "times-circle"]} size="2x" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(Success);
