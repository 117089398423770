import React, { useState } from 'react'
import { withRouter, useHistory, useLocation } from 'react-router-dom'

import './styles.scss'

import * as paths from '../../config/paths'
import * as authActions from '../../actions/auth.actions'
import Preloader from '../../components/Preloader'
import FacebookButton from '../../components/Buttons/FacebookButton'
import GoogleButton from '../../components/Buttons/GoogleButton'
import NavbarLogo from '../../assets/img/ebb.png'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Login(props) {
  const [showPreloader, setShowPreloader] = useState(false)
  const history = useHistory()
  const query = useQuery()
  const facebookLogin = () => {
    setShowPreloader(true)
    window.FB.login(function (response) {
      if (response.authResponse) {
        window.FB.api('/me', function (facebookUser) {
          authActions.checkForUser({ provider: 'facebook', identifier: facebookUser.id })
            .then(response => {
              if (response.type === 'SUCCESSFULLY') {
                setShowPreloader(false)
                const { payload } = response
                localStorage.setItem('user_token', payload.token)
                localStorage.setItem('user_email', payload.customer.email)
                let boxTypeDefault = query.get('order_selected')
                // if (boxTypeDefault) {
                //   localStorage.setItem('boxTypeDefault', boxTypeDefault)
                // }
                localStorage.removeItem('recoveredUser')
                localStorage.setItem('currentUser', JSON.stringify(payload.customer));
                if (boxTypeDefault) {
                  history.push({
                    pathname: paths.HOME,
                    search: "?order_selected=" + boxTypeDefault
                  })
                } else {
                  history.push(paths.HOME);
                }
              }
            })
            .catch(error => {
              if (error.response) {
                setShowPreloader(false)
                let { data } = error.response
                if (data.msg === 'THE USER DOES NOT EXIST') {
                  let user = {
                    id: facebookUser.id,
                    first_name: facebookUser.first_name,
                    last_name: facebookUser.last_name,
                    email: facebookUser.email,
                    provider: 'facebook'
                  }
                  localStorage.setItem('recoveredUser', JSON.stringify(user))
                  let boxTypeDefault = query.get('order_selected')
                  if (boxTypeDefault) {
                    history.push({
                      pathname: paths.AUTH.WIZARD,
                      search: "?order_selected=" + boxTypeDefault
                    })
                  } else {
                    history.push(paths.AUTH.WIZARD);
                  }
                }
              }
            })
        }, { fields: 'id, email, first_name, last_name ' })
      } else {
        setShowPreloader(false)
      }
    }, { scope: 'email' })
  }

  const googleLogin = () => {
    setShowPreloader(true)
    let authInstance = window.gapi.auth2.getAuthInstance()
    authInstance.signIn()
      .then(googleUser => {
        authActions.checkForUser({ provider: 'google', identifier: googleUser.getId() })
          .then(response => {
            if (response.type === 'SUCCESSFULLY') {
              setShowPreloader(false)
              const { payload } = response
              localStorage.setItem('user_token', payload.token)
              localStorage.setItem('user_email', payload.customer.email)
              localStorage.removeItem('recoveredUser')
              let boxTypeDefault = query.get('order_selected');
              if (boxTypeDefault) {
                history.push({
                  pathname: paths.HOME,
                  search: "?order_selected=" + boxTypeDefault
                })
              } else {
                history.push(paths.HOME);
              }
              localStorage.setItem('currentUser', JSON.stringify(payload.customer));
            }
          })
          .catch(error => {
            if (error.response) {
              let { data } = error.response
              if (data.msg === 'THE USER DOES NOT EXIST') {
                setShowPreloader(false)
                let user = {
                  id: googleUser.getBasicProfile().getId(),
                  first_name: googleUser.getBasicProfile().getGivenName(),
                  last_name: googleUser.getBasicProfile().getFamilyName(),
                  email: googleUser.getBasicProfile().getEmail(),
                  provider: 'google'
                }
                localStorage.setItem('recoveredUser', JSON.stringify(user))
                let boxTypeDefault = query.get('order_selected');
                if (boxTypeDefault) {
                  history.push({
                    pathname: paths.AUTH.WIZARD,
                    search: "?order_selected=" + boxTypeDefault
                  })
                } else {
                  history.push(paths.AUTH.WIZARD);
                }
              }
            }
          })
      })
      .catch(error => {
        setShowPreloader(false)
        console.log(error.response)
      })
  }

  return (
    <div className="div-tag div-tag--login-container">
      {showPreloader && <Preloader />}
      <div className="div-tag div-tag--login-block">
        <div style={{ textAlign: 'center', margin: '10px; 0 10px; 0' }}>
          <img height={100} src={NavbarLogo} className="App-logo" alt="logo" />
        </div>
        <h1 className="h1-tag h1-tag--login-title">Iniciar Sesión en EasyBolBox</h1>
        <FacebookButton className="facebook-button" onClick={() => facebookLogin()} />
        <div className="div-tag div-tag--separator">
          <div className="div-tag div-tag--separator--line"></div>
          <p className="p-tag div-tag--separator--text">ó</p>
          <div className="div-tag div-tag--separator--line"></div>
        </div>

        <GoogleButton className="google-button" onClick={() => googleLogin()} />
      </div>
    </div>
  )
}

export default withRouter(Login)
