import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";

import "./styles.scss";

import * as actions from "../../actions/orders.actions";
import * as paths from "../../config/paths";
import { ifUnauthorized } from "../../utils/authentication";
import * as alerts from "../../utils/alertify";
import Preloader from "../../components/Preloader";
import Button from "../../components/Buttons/Button";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_ID}`);

function OrderDetail(props) {
  const [showPreloader, setShowPreloader] = useState(true);
  const [order, setOrder] = useState({});
  const [detailActions, setDetailActions] = useState([]);
  const [trackingAction, setTrackingAction] = useState(false);
  const history = useHistory();
  const [dimension, setDimension]=useState("")

  useEffect(() => {
    ifUnauthorized(history, getDetail);
  }, []);

  const getDetail = () => {
    actions
      .getOrderDetail({ order_id: props.match.params.id })
      .then((response) => {
        if (response.type === "SUCCESSFULLY") {
          const { data } = response.payload;
          console.log("ORDER DATA");
          console.log(data);
          setDimension(
            data.relationships.order_type.meta.data.relationships.metadata_attributes.meta.data.find(
              (d) => d.attributes.key === "Dimensiones"
            )
          );
          setOrder({
            id: data.id,
            datetime: data.attributes.order_datetime,
            number: data.attributes.order_number,
            additionalNote: data.attributes.additional_note,
            weight: data.attributes.weight,
            stateId: data.relationships.order_state.meta.data.id,
            stateName: data.relationships.order_state.meta.data.attributes.name,
            itemCategory:
              data.relationships.item_category.meta.data.attributes.name,
            type: data.relationships.order_type.meta.data.attributes.name,
            dimensions: data.relationships.order_type.meta.data.relationships.metadata_attributes.meta.data[0].attributes.value,
            originName: data.relationships.origin.meta.data.attributes.name,
            originLastName: data.relationships.origin.meta.data.attributes.last_name,
            originPhone: data.relationships.origin.meta.data.attributes.phone,
            originEmail: data.relationships.origin.meta.data.attributes.email,
            originAddress1:
              data.relationships.origin.meta.data.attributes.street,
            originAddress2:
              data.relationships.origin.meta.data.attributes.street2,
            originPostcode:
              data.relationships.origin.meta.data.attributes.postal_code,
            originLat: data.relationships.origin.meta.data.attributes.lat,
            originLng: data.relationships.origin.meta.data.attributes.lng,
            originCountry:
              data.relationships.origin.meta.data.relationships.country.meta
                .data.attributes.name,
            originState:
              data.relationships.origin.meta.data.relationships.state.meta.data
                .attributes.name,
            destinationName:
              data.relationships.destination.meta.data.attributes.name,
            destinationLastName:
              data.relationships.destination.meta.data.attributes.last_name,
            destinationPhone:
              data.relationships.destination.meta.data.attributes.phone,
            destinationEmail:
              data.relationships.destination.meta.data.attributes.email,
            destinationAddress1:
              data.relationships.destination.meta.data.attributes.street,
            destinationAddress2:
              data.relationships.destination.meta.data.attributes.street2,
            destinationPostcode:
              data.relationships.destination.meta.data.attributes.postal_code,
            destinationLat:
              data.relationships.destination.meta.data.attributes.lat,
            destinationLng:
              data.relationships.destination.meta.data.attributes.lng,
            destinationCountry:
              data.relationships.destination.meta.data.relationships.country
                .meta.data.attributes.name,
            destinationState:
              data.relationships.destination.meta.data.relationships.state.meta
                .data.attributes.name,
            transactionId: data.relationships.money_transaction.meta.data.id,
            transactionNumber:
              data.relationships.money_transaction.meta.data.attributes
                .transaction_number,
            transactionAmount:
              data.relationships.money_transaction.meta.data.attributes
                .total_amount,
            transactionName:
              data.relationships.money_transaction.meta.data.relationships
                .transaction_state.meta.data.attributes.name,
            sessionId:
              data.relationships.money_transaction.meta.data.attributes
                .metadata &&
              data.relationships.money_transaction.meta.data.attributes.metadata
                .stripe_info
                ? data.relationships.money_transaction.meta.data.attributes
                    .metadata.stripe_info.session_stripe_id
                : "",
            items: data.relationships.items.meta.data.map((item) => {
              return { ...item.attributes };
            }),
          });
          actions
            .getOrderDetailActions({ order_id: props.match.params.id })
            .then((response) => {
              if (response.type === "SUCCESSFULLY") {
                setDetailActions(response.payload);
                actions
                  .getOrderTrackingActions({ order_id: props.match.params.id })
                  .then((response) => {
                    setTrackingAction(response.payload.can_ready_box);
                    setShowPreloader(false);
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setShowPreloader(false);
                  });
              }
            })
            .catch((error) => {
              console.log(error.response);
              setShowPreloader(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setShowPreloader(false);
      });
  };

  const originLocation = () => {
    window.open(
      `https://maps.google.com?q=${order.originLat},${order.originLng}`
    );
  };

  const destinationLocation = () => {
    window.open(
      `https://maps.google.com?q=${order.destinationLat},${order.destinationLng}`
    );
  };

  const onPaymentConfirm = async () => {
    if (order.sessionId !== "") {
      setShowPreloader(true);
      const stripe = await stripePromise;
      stripe
        .redirectToCheckout({
          sessionId: order.sessionId,
        })
        .then((res) => {
          console.log("stripe response");
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      alert("No se puede proceder al pago.");
    }
  };

  const onStateChange = (state) => {
    alerts.promptOrder(
      "Cambiar el estado de su orden.", // titile
      "Razón para efectuar la operación", // message
      "", //default value
      async (evt, value) => {
        setShowPreloader(true);
        let params = {
          order_id: props.match.params.id,
          identifier: state,
          description: value,
        };
        actions
          .changeOrderState(params)
          .then((response) => {
            if (response.type === "SUCCESSFULLY") {
              setShowPreloader(false);
              history.push(paths.ORDERS.LIST);
            }
          })
          .catch((error) => {
            console.log(error);
            setShowPreloader(false);
          });
        alerts.notifyMessage("Se ha guardado la información.");
      }, // function triggered after click ok
      (evt, value) => {
        alerts.notifyError("Acción cancelada.");
      }
    ); // function triggered after click cancel
  };

  const renderButtons = () => {
    return (
      <div className="text-right">
        {detailActions.length > 0 &&
        detailActions.find((el) => el === "do_direct_payment") ? (
          <Button
            className="order-payment-button"
            onClick={() => onPaymentConfirm()}
          >
            Pagar
          </Button>
        ) : null}
        {/* {detailActions.length > 0 && detailActions.find(el => el === "cancel") ?
                    <Button
                        className="order-cancel-button"
                        onClick={() => onStateChange(detailActions.find(el => el === "cancel"))}>
                        Cancelar
                    </Button> : null} */}
        {/* {!detailActions.find(el => el === "do_direct_payment") && trackingAction ?
                    <Button
                        className="payment-button"
                        onClick={() => onStateChange("can_ready_box")}>
                        Pedido listo
                    </Button> : null} */}
      </div>
    );
  };

  const howSend=(id)=>{
    history.push(paths.ORDERS.SEND.PATH(id))
  }

  return (
    <div className="order-detail-box container">
      {showPreloader && <Preloader />}
      {renderButtons()}
      {console.log("ORDER DETAIL")}
      {console.log(props)}
      <div className="text-right">
        <Button className="order-ready-button" onClick={() => {howSend(order.id)}}>
          Cómo Enviar
        </Button>
      </div>
      <h4 className="main-title text-center">Datos del envío</h4>
      <div className="information-box">
        <div className="flex-wrap-box">
          <span className="flex-wrap-content1">
            <b>Número de Orden:</b> {order.number}
          </span>
          <span className="flex-wrap-content1">
            <b>Fecha:</b> {dayjs(order.datetime).format("DD/MM/YYYY - hh:mm A")}
          </span>
          <span className="flex-wrap-content1">
            <b>Tipo de contenido:</b> {order.itemCategory}
          </span>
          <span className="flex-wrap-content1">
            <b>Dimensiones del envío:</b> {`${order.type} (${dimension?dimension.attributes.value:""})`}
          </span>
          <span className="flex-wrap-content1">
            <b>Estado de transacción:</b> {order.transactionName}
          </span>
          <span className="flex-wrap-content1">
            <b>Monto de transacción:</b> $ {order.transactionAmount}
          </span>
        </div>
        <b>Nota adicional:</b>
        <p>{order.additionalNote}</p>
      </div>
      <div className="flex-wrap-box">
        <div className="flex-wrap-content1 information-box">
          <h4 className="title2">Datos de ubicación de origen</h4>
          <div className="">
            <span>
              <b>Nombre: </b>
              {order.originName}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Apellido: </b>
              {order.originLastName}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Teléfono: </b>
              {order.originPhone}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Email: </b>
              {order.originEmail}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Línea de dirección #1: </b>
              {order.originAddress1}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Línea de dirección #2: </b>
              {order.originAddress2}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Código Postal: </b>
              {order.originPostcode}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Ubicación: </b>
              {order.originState}, {order.originCountry}
            </span>
            {/* <span><b>Ubicación: </b><a className="google-maps-location" onClick={() => originLocation()}>{order.originState}, {order.originCountry}</a></span> */}
          </div>
        </div>
        <div className="flex-wrap-content1 information-box">
          <h4 className="title2">Datos de ubicación de destino</h4>
          <div className="">
            <span>
              <b>Nombre: </b>
              {order.destinationName}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Apellido: </b>
              {order.destinationLastName}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Teléfono: </b>
              {order.destinationPhone}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Email: </b>
              {order.destinationEmail}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Línea de dirección #1: </b>
              {order.destinationAddress1}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Línea de dirección #2: </b>
              {order.destinationAddress2}.
            </span>
          </div>
          <div className="">
            <span>
              <b>Ubicación: </b>
              {order.destinationState}, {order.destinationCountry}
            </span>
          </div>
        </div>
      </div>
      <h4 className="title2">Productos:</h4>
      {order.items ? (
        <div className="row item-row">
          {order.items.map((item, index) => {
            return (
              <div key={index} className="col-md-6 item-col">
                <div className="item-content">
                  <label>Producto # {index + 1}</label>
                  <div className="">
                    <span>
                      <b>Nombre: </b>
                      {item.name}.
                    </span>
                  </div>
                  <div className="">
                    <span>
                      <b>Descripción: </b>
                      {item.description}.
                    </span>
                  </div>
                  <div className="">
                    <span>
                      <b>Fabricado en: </b>
                      {item.brand}.
                    </span>
                  </div>
                  {order.itemCategory === "Ropa" ? (
                    <div className="">
                      <span>
                        <b>Material: </b>
                        {item.material}.
                      </span>
                    </div>
                  ) : null}
                  {order.itemCategory === "Ropa" ? (
                    <div className="">
                      <span>
                        <b>Género: </b>
                        {item.gender}.
                      </span>
                    </div>
                  ) : null}
                  {order.type == "Caja Salud" ? (
                    ""
                  ) : (
                    <div>
                      <div className="">
                        <span>
                          <b>Cantidad: </b>
                          {item.qty}.
                        </span>
                      </div>
                      <div className="">
                        <span>
                          <b>Precio por unidad: $ </b>
                          {item.unit_price}.
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>No existen items.</p>
      )}
    </div>
  );
}

export default OrderDetail;
