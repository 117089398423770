import React from "react";
import "./styles.scss";

function Contact(props) {
  return (
    <div className="div-container-contact pt-5 pt-lg-0">
      <div className="div-tag div-tag--login-block col-md-6 col-lg-3 mb-5 mb-lg-0 m-2">
        <h1 className="h1-tag h1-tag--wizard-title">Oficina Central en USA</h1>
        <div>
          <div>
            <h3 className="font-weight-bold text-dark small-text">
              Dirección:{" "}
            </h3>
            <label>1758 Juntion Ave. Suite F San Jose, CA 95122</label>
          </div>
          <div>
            <h3 className="font-weight-bold text-dark small-text">
              Teléfono:{" "}
            </h3>
            <div>+1(888) 7EASYBOL</div>
            <label>+1(888) 732-7926</label>
          </div>
          <div>
            <h3 className="font-weight-bold text-dark small-text">Email: </h3>
            <label>contact@easybolbox.com</label>
          </div>
        </div>
      </div>

      <div className="div-tag div-tag--login-block col-md-6 col-lg-3 mb-5 mb-lg-0 m-2">
        <h1 className="h1-tag h1-tag--wizard-title">Depósitos en Miami</h1>
        <div>
          <div>
            <h3 className="font-weight-bold text-dark small-text">
              Dirección:{" "}
            </h3>
            <label>1601 NW 93 Ave Miami, FL 33172 2920, EEUU.</label>
          </div>
          <div>
            <h3 className="font-weight-bold text-dark small-text">
              Teléfono:{" "}
            </h3>
            <div>+1(888) 7EASYBOL</div>
            <label>+1(888) 732-7926</label>
          </div>
          <div>
            <h3 className="font-weight-bold text-dark small-text">Email: </h3>
            <label>contact@easybolbox.com</label>
          </div>
        </div>
      </div>

      <div className="div-tag div-tag--login-block col-md-6 col-lg-3 mb-5 mb-lg-0 m-2">
        <h1 className="h1-tag h1-tag--wizard-title">
          Oficina Central en Bolivia
        </h1>
        <div>
          <div>
            <h3 className="font-weight-bold text-dark small-text">
              Dirección:{" "}
            </h3>
            <label>
              Edificio Top Center, Piso 8, Oficina J, Av. Beni #155, entre 1er y
              2do anillo, Santa Cruz - BOLIVIA
            </label>
          </div>
          <div>
            <h3 className="font-weight-bold text-dark small-text">
              Teléfono:{" "}
            </h3>
            <label>+591 77833777</label>
            {/* <div>
                            +591 33240018
                            </div> */}
          </div>
          <div>
            <h3 className="font-weight-bold text-dark small-text">Email: </h3>
            <div>soporte@easybolbox.com</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
