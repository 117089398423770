import React from "react";
import "./styles.scss";

const Preloader = props => (
    <div className="preloader-container">
        <div className="preloader">
            <div className="dot1" />
            <div className="dot2" />
        </div>
    </div>
);

export default Preloader;
