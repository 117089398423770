import React from "react";
import { withRouter, Route, Redirect } from "react-router-dom";

import * as path from "../../../config/paths";

const AuthRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            !localStorage.getItem("user_token") ? (
                <Component {...props} />
            ) : (
                    <Redirect to={path.HOME} />
                )
        }
    />
)

export default withRouter(AuthRoute);