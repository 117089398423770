import React, { useState, useEffect } from "react";
import { withRouter, Redirect, useHistory, useLocation } from "react-router-dom";

import "./styles.scss";

import * as paths from "../../config/paths";
import * as authActions from "../../actions/auth.actions";
import Preloader from "../../components/Preloader";
import TextInput from "../../components/Inputs/TextInput";
import Button from "../../components/Buttons/Button";

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function Wizard(props) {
    const [showPrelaoder, setShowPrelaoder] = useState(false);
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const query = useQuery()
    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem("recoveredUser")) {
            let user = JSON.parse(localStorage.getItem("recoveredUser"));
            setName(user.first_name);
            setLastName(user.last_name);
            setEmail(user.email);
        }
    }, []);

    const onChange = event => {
        switch (event.target.id) {
            case "name":
                setName(event.target.value);
                break;
            case "last_name":
                setLastName(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                break;
            case "phone":
                setPhone(event.target.value);
                break;
            default:
                break;
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        setShowPrelaoder(true);
        if (name !== "" && lastName !== "" && email !== "") {
            let data = JSON.parse(localStorage.getItem("recoveredUser"));
            let userData = {
                provider: data.provider,
                identifier: data.id,
                name: name,
                last_name: lastName,
                email: email,
                phone: phone
            }
            authActions.login(userData)
                .then(response => {
                    if (response.type === "SUCCESSFULLY") {
                        localStorage.setItem("user_token", response.payload.token);
                        localStorage.setItem("user_email", response.payload.customer.email);
                        localStorage.removeItem("recoveredUser");
                        localStorage.setItem('currentUser', JSON.stringify(response.payload.customer));
                        setShowPrelaoder(false);
                        let boxTypeDefault = query.get('order_selected');
                        if (boxTypeDefault) {
                            history.push({
                                pathname: paths.HOME,
                                search: "?order_selected=" + boxTypeDefault
                            })
                        } else {
                            history.push(paths.HOME);
                        }
                    }
                })
                .catch(error => {
                    console.log(error.response);
                })
        } else {
            alert("Rellene los campos");
        }
    }

    return (
        localStorage.getItem("recoveredUser") ?
            <div className="div-tag div-tag--login-container">
                {showPrelaoder && <Preloader />}
                <div className="div-tag div-tag--login-block">
                    <h1 className="h1-tag h1-tag--wizard-title">Bienvenido a EasyBolBox</h1>
                    <p className="p-tag p-tag--wizard-description">Por favor, introduzca sus datos a continuación.</p>
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label className="font-weight-bold text-dark small-text">Nombre: *</label>
                            <TextInput
                                className="form-control"
                                id="name"
                                value={name}
                                onChange={onChange} />
                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold text-dark small-text">Apellido: *</label>
                            <TextInput
                                className="form-control"
                                id="last_name"
                                value={lastName}
                                onChange={onChange} />
                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold text-dark small-text">Email: *</label>
                            <TextInput
                                type="email"
                                className="form-control"
                                id="email"
                                value={email}
                                onChange={onChange} />
                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold text-dark small-text">Teléfono:</label>
                            <TextInput
                                className="form-control"
                                id="phone"
                                value={phone}
                                onChange={onChange} />
                        </div>
                        <Button
                            type="submit"
                            className="btn btn-primary float-right btn-wizard-confirm">
                            Continuar
                        </Button>
                    </form>
                </div>
            </div> :
            <Redirect to={paths.AUTH.LOGIN} />
    );
}

export default withRouter(Wizard);
