import React, { Component } from "react";

import Button from "./Button";
import GoogleImage from "../../assets/img/google.svg";

import "./styles.scss";

class GoogleButton extends Component {
    componentDidMount() {
        this._isMounted = true;
        const authScript = document.createElement('script');
        authScript.id = `auth-method-google`
        authScript.src = "https://apis.google.com/js/platform.js";
        authScript.async = true;
        authScript.defer = true;
        window.document.body.appendChild(authScript);
        authScript.addEventListener('load', () => {
            // console.log('Google auth script loaded');
            window.gapi.load('auth2', function () {
                window.gapi.auth2.init({ client_id: `${process.env.REACT_APP_GOOGLE_ID}` })
                    .then(function (response) {
                    }, function (response) {
                    })
            })
        });
    }

    render() {
        return (
            <Button className={this.props.className} onClick={this.props.onClick}>
                <img className="img-tag--button" src={GoogleImage} />
                Ingresar con Google
            </Button>
        );
    }
}

export default GoogleButton;
