import axios from "axios";

import * as apiRoutes from "../config/apiRoutes";

export const getProfileInfo = async () => {
    const res = await axios.get(apiRoutes.GET_PROFILE, {
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}

export const updateProfileInfo = async (updateParams) => {
    const res = await axios.post(apiRoutes.UPDATE_PROFILE, updateParams, {
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}