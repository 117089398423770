import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./styles.scss";

import * as actions from "../../actions/profile.actions";
import * as paths from "../../config/paths";
import Preloader from "../../components/Preloader";
import Button from "../../components/Buttons/Button";
import TextInput from "../../components/Inputs/TextInput";

function Profile(props) {
    const [showPreloader, setShowPreloader] = useState(true);
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [edit, setEdit] = useState(false);
    const history = useHistory();

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = () => {
        actions.getProfileInfo()
            .then(response => {
                if (response.type === "SUCCESSFULLY") {
                    const { payload } = response;
                    setInfo(payload);
                    setShowPreloader(false);
                }
            })
            .catch(error => {
                console.log(error.response);
                if (error.response.data.msg === "unauthorized") {
                    localStorage.removeItem("user_token");
                    localStorage.removeItem("user_email");
                    history.push(paths.AUTH.LOGIN);
                }
            })
    }

    const setInfo = (payload) => {
        setName(payload.name);
        setLastName(payload.last_name);
        setEmail(payload.email);
        setPhone(payload.phone);
    }

    const handleChange = event => {
        const { id, value } = event.target;
        switch (id) {
            case "name":
                if (value.length <= 50) {
                    setName(value);
                }
                break;
            case "last_name":
                if (value.length <= 50) {
                    setLastName(value);
                }
                break;
            case "email":
                if (value.length <= 200) {
                    setEmail(value);
                }
                break;
            case "phone":
                if (value.length <= 200) {
                    setPhone(value);
                }
                break;
            default:
                break;
        }
    }

    const changeToEdit = () => {
        setEdit(true);
    }

    const saveEdit = (event) => {
        event.preventDefault();
        setShowPreloader(true);
        if (name !== "" && lastName !== "" && email !== "") {
            const params = {
                name: name,
                last_name: lastName,
                phone: phone,
                email: email
            };
            actions.updateProfileInfo(params)
                .then(response => {
                    if (response.type === "SUCCESSFULLY") {
                        setInfo(response.payload.data.attributes);
                        setEdit(false);
                        setShowPreloader(false);
                    }
                })
                .catch(error => {
                    console.log(error.response);
                    setShowPreloader(false);
                    if (error.response.data.msg === "unauthorized") {
                        localStorage.removeItem("user_token");
                        history.push(paths.AUTH.LOGIN);
                    }
                })
        } else {
            alert("No deje los campos vacíos.");
        }

        setEdit(false);
    }

    return (
        <div className="div-tag div-tag--login-container">
            {showPreloader && <Preloader />}
            <div className="div-tag div-tag--login-block">
                <h1 className="h1-tag h1-tag--wizard-title">Información del usuario</h1>
                {!edit ?
                    <div>
                        <div>
                            <label className="font-weight-bold text-dark small-text">Nombres: </label> {name}
                        </div>
                        <div>
                            <label className="font-weight-bold text-dark small-text">Apellidos: </label> {lastName}
                        </div>
                        <div>
                            <label className="font-weight-bold text-dark small-text">Email: </label> {email}
                        </div>
                        <div>
                            <label className="font-weight-bold text-dark small-text">Teléfono: </label> {phone}
                        </div>
                        <Button className="btn btn-primary float-right" onClick={() => changeToEdit()}>Editar</Button>
                    </div> :
                    <form onSubmit={saveEdit}>
                        <div className="form-group">
                            <label className="font-weight-bold text-dark small-text">Nombres *</label>
                            <TextInput
                                className="form-control"
                                id="name"
                                value={name}
                                maxLength={50}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold text-dark small-text">Apellidos *</label>
                            <TextInput
                                className="form-control"
                                id="last_name"
                                value={lastName}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold text-dark small-text">Email *</label>
                            <TextInput
                                type="email"
                                className="form-control"
                                id="email"
                                value={email}
                                onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold text-dark small-text">Teléfono</label>
                            <TextInput
                                className="form-control"
                                id="phone"
                                value={phone}
                                onChange={handleChange} />
                        </div>
                        <Button
                            type="submit"
                            className="btn btn-primary float-right btn-wizard-confirm">
                            Guardar datos
                            </Button>
                    </form>}
            </div>
        </div>
    )
}

export default Profile;