export const DOMAIN = process.env.REACT_APP_DOMAIN;

export const SERVER_URL = `${DOMAIN}/engine_api_ebb/api/v1`;

export const CHECK_FOR_CUSTOMER = `${SERVER_URL}/check_user`;
export const LOGIN = `${SERVER_URL}/authorize`;

export const GLOBAL_PARAMETERS = `${SERVER_URL}/model_dependencies`;
export const UPDATE_ORDER = `${SERVER_URL}/update_order`;
export const CHECKOUT_STATUS = `${SERVER_URL}/checkout_status`;
export const VALIDATE_ADDRESS = `${SERVER_URL}/validate_address`;
export const ORDER_TOTAL_AMOUNT = `${SERVER_URL}/order_total_amount`;

export const GET_PROFILE = `${SERVER_URL}/profile`;
export const UPDATE_PROFILE = `${SERVER_URL}/profile-update`;

export const TRACKING_LOG = `${SERVER_URL}/tracking-order`;

export const GET_ADDRESSES = `${SERVER_URL}/my_addresses`;
export const ADDRESS_DETAIL = `${SERVER_URL}/address_detail`;
export const UPDATE_ADDRESS = `${SERVER_URL}/update-account-address`;

export const GET_ORDERS = `${SERVER_URL}/my_orders`;
export const ORDER_DETAIL = `${SERVER_URL}/order_detail`;
export const ORDER_DETAIL_ACTIONS = `${SERVER_URL}/order_action_state`;
export const ORDER_TRACKING_ACTIONS = `${SERVER_URL}/order_action_tracking`;
export const CHANGE_ORDER_STATE = `${SERVER_URL}/change_order_state`;
