import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const RadioButton = props => {
    return (
        props.options.map((option, index) => (
            <div key={index} className="container-radio">
                <input
                    id={`${option.name}-${option.id}`}
                    type="radio"
                    className={props.className}
                    name={props.name}
                    value={option.id}
                    checked={props.value == option.id}
                    onChange={props.onChange}
                    required={props.required}
                />
                <div className="container-info-address">
                    <label className="radio-label" htmlFor={`${option.name}-${option.id}`} >
                        <b>{option.name}</b><i>{option.address1 ? " " + option.address1 + " - " + option.stateName + ", " + option.countryName : ""}</i>
                    </label>
                </div>
            </div>
        ))

    )
}

RadioButton.propTypes = {
    options: PropTypes.array,
    className: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
}

RadioButton.defaultProps = {
    options: [],
    className: "",
    name: "",
    value: 0,
    onChange: () => { },
    required: false,
}

export default RadioButton;
