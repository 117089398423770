import React, { useEffect, useState } from "react";
import Button from "../../components/Buttons/Button";
import Barcode from "react-barcode";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ifUnauthorized } from "../../utils/authentication";
import * as actions from "../../actions/orders.actions";
import Preloader from "../../components/Preloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";

function StepsToSend(props) {
  const [showPreloader, setShowPreloader] = useState(true);
  const [order, setOrder] = useState({});
  const [detailActions, setDetailActions] = useState([]);
  const [trackingAction, setTrackingAction] = useState(false);
  const [dimension, setDimension]=useState("")
  const history = useHistory();

  useEffect(() => {
    ifUnauthorized(history, getDetail);
  }, []);

  const getDetail = () => {
    actions
      .getOrderDetail({ order_id: props.match.params.id })
      .then((response) => {
        if (response.type === "SUCCESSFULLY") {
          const { data } = response.payload;
          console.log("ORDER DATA StepsToSend");
          console.log(data);
          setOrder({
            id: data.id,
            datetime: data.attributes.order_datetime,
            number: data.attributes.order_number,
            additionalNote: data.attributes.additional_note,
            weight: data.attributes.weight,
            stateId: data.relationships.order_state.meta.data.id,
            stateName: data.relationships.order_state.meta.data.attributes.name,
            itemCategory:
              data.relationships.item_category.meta.data.attributes.name,
            type: data.relationships.order_type.meta.data.attributes.name,
            // dimensions:
            //   data.relationships.order_type.meta.data.relationships.metadata_attributes.meta.data.find(
            //     (d) => d.attributes.key === "Dimensiones"
            //   ),
            originName: data.relationships.origin.meta.data.attributes.name,
            originPhone: data.relationships.origin.meta.data.attributes.phone,
            originEmail: data.relationships.origin.meta.data.attributes.email,
            originAddress1:
              data.relationships.origin.meta.data.attributes.street,
            originAddress2:
              data.relationships.origin.meta.data.attributes.street2,
            originPostcode:
              data.relationships.origin.meta.data.attributes.postal_code,
            originLat: data.relationships.origin.meta.data.attributes.lat,
            originLng: data.relationships.origin.meta.data.attributes.lng,
            originCountry:
              data.relationships.origin.meta.data.relationships.country.meta
                .data.attributes.name,
            originState:
              data.relationships.origin.meta.data.relationships.state.meta.data
                .attributes.name,
            destinationName:
              data.relationships.destination.meta.data.attributes.name,
            destinationPhone:
              data.relationships.destination.meta.data.attributes.phone,
            destinationEmail:
              data.relationships.destination.meta.data.attributes.email,
            destinationAddress1:
              data.relationships.destination.meta.data.attributes.street,
            destinationAddress2:
              data.relationships.destination.meta.data.attributes.street2,
            destinationPostcode:
              data.relationships.destination.meta.data.attributes.postal_code,
            destinationLat:
              data.relationships.destination.meta.data.attributes.lat,
            destinationLng:
              data.relationships.destination.meta.data.attributes.lng,
            destinationCountry:
              data.relationships.destination.meta.data.relationships.country
                .meta.data.attributes.name,
            destinationState:
              data.relationships.destination.meta.data.relationships.state.meta
                .data.attributes.name,
            transactionId: data.relationships.money_transaction.meta.data.id,
            transactionNumber:
              data.relationships.money_transaction.meta.data.attributes
                .transaction_number,
            transactionAmount:
              data.relationships.money_transaction.meta.data.attributes
                .total_amount,
            transactionName:
              data.relationships.money_transaction.meta.data.relationships
                .transaction_state.meta.data.attributes.name,
            sessionId:
              data.relationships.money_transaction.meta.data.attributes
                .metadata &&
              data.relationships.money_transaction.meta.data.attributes.metadata
                .stripe_info
                ? data.relationships.money_transaction.meta.data.attributes
                    .metadata.stripe_info.session_stripe_id
                : "",
            items: data.relationships.items.meta.data.map((item) => {
              return { ...item.attributes };
            }),
          });
          setDimension(
            data.relationships.order_type.meta.data.relationships.metadata_attributes.meta.data.find(
              (d) => d.attributes.key === "Dimensiones"
            )
          );
          actions
            .getOrderDetailActions({ order_id: props.match.params.id })
            .then((response) => {
              if (response.type === "SUCCESSFULLY") {
                setDetailActions(response.payload);
                actions
                  .getOrderTrackingActions({ order_id: props.match.params.id })
                  .then((response) => {
                    setTrackingAction(response.payload.can_ready_box);
                    setShowPreloader(false);
                  })
                  .catch((error) => {
                    console.log(error.response);
                    setShowPreloader(false);
                  });
              }
            })
            .catch((error) => {
              console.log(error.response);
              setShowPreloader(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setShowPreloader(false);
      });
  };

  return (
    <React.Fragment>
      <div className="order-detail-box container">
        {showPreloader && <Preloader />}
        <div className="view-delivery-box2">
          <div className="button-print">
          {console.log("order")}
          {console.log(dimension)}
          {console.log(order)}

            <Button
              className="wizard-next-button"
              onClick={() => window.print()}
            >
              Imprimir Pantalla
            </Button>
          </div>
          <div className="step-title2">
            Debe seguir los siguientes pasos para hacernos llegar su caja a
            nuestro almacén
          </div>
          <div className="barcode">
            <Barcode value={order.number} />
          </div>
          <div>
            <strong>Paso 1: </strong>
            <br />
            Ud. ha pagado por el envío de una {order?order.type:""} con las
            dimensiones de {dimension?dimension.attributes.value:""}, la cual puede
            comprarla en las oficinas de UPS o comprarlas en el sitio web de{" "}
            <a href="https://www.theupsstore.com/pack-ship" target="_blank">
              The UPS Store.
            </a>{" "}
            Favor de tomar nota de las dimensiones especificadas
          </div>
          <br />
          <div>
            <strong>Paso 2: </strong>
            <ul>
              <li>
                Debe <strong>IMPRIMIR</strong> esta pantalla
              </li>
              <li>
                El código de barras en la parte de abajo debe recortarlo y
                pegarlo en el exterior de la caja que va enviar
              </li>
              <li>El resto de esta hoja debe colocarlo dentro de su caja</li>
            </ul>
          </div>

          <div>
            <strong>Paso 3: </strong>
            <span>Favor enviar la caja a la dirección de nuestro almacén:</span>
          </div>
          <span>EasyBolBox</span>
          <span>1601 NW 93 Ave</span>
          <span>
            Miami, Fl. 33172.{" "}
            <a href="https://goo.gl/maps/oGqB1uQwLS5UA3zB7" target="_blank">
              Ver en mapa
            </a>
          </span>
          <br />
          {/* <div>
                  <strong>Paso 4: </strong>
                  <span>
                    Favor enviar los siguientes DATOS para el seguimiento de su
                    caja:
                  </span>
                  <ul>
                    <li>Nombre completo, teléfono, email del REMITENTE</li>
                    <li>
                      Foto del número de orden (tracking number del servicio
                      utilizado "UPS")
                    </li>
                    <li>
                      Confirmar su número de contacto (teléfono, whatsapp)
                    </li>
                  </ul>
                  <span>Puede enviar estos datos por email o whatsapp:</span>
                  <ul>
                    <li type="none">
                      Email:{" "}
                      <a href="mailto:contact@easybolbox.com" target="_blank">
                        contact@easybolbox.com
                      </a>
                    </li>
                    <li type="none">
                      Whatsapp:{" "}
                      <a
                        href="https://wa.me/14084060306?text=Hello%20EasyBolBox"
                        target="_blank"
                      >
                        {" "}
                        +1 (408) 406-0306
                      </a>
                    </li>
                  </ul>
                </div> */}
          <div>
            <strong>Paso 4: </strong>
            <span>
              Ante cualquier duda o consulta, favor comunicarse a los siguientes
              teléfonos de EEUU o al Correo:
            </span>
            <ul>
              <li type="none">
                Email:{" "}
                <a href="mailto:contact@easybolbox.com" target="_blank">
                  contact@easybolbox.com
                </a>
              </li>
              <li type="none">
                Whatsapp:{" "}
                <a
                  href="https://wa.me/14084060306?text=Hello%20EasyBolBox"
                  target="_blank"
                >
                  {" "}
                  +1 (408) 406-0306
                </a>
              </li>
            </ul>
          </div>
          <div>
            <strong>Nota: </strong>
            <span>
              Favor enviar estos datos a la brevedad posible, caso contrario
              pueden generarse gastos adicionales de almacenamiento.
            </span>
          </div>
          <h5 className="text-danger mt-5">
            Debe cortar esta sección y pegarlo fuera de su caja.
          </h5>
          <div className="d-flex flex-row justify-content-center">
            <FontAwesomeIcon icon={["fas", "cut"]} size="lg" />
            <div className="barcode-down">
              <h2
                className="text-center font-weight-bold"
                style={{ color: "#043777" }}
              >
                EasyBolBox{" "}
                <div style={{ color: "#db7c04" }}>Tracking Number</div>
              </h2>

              <Barcode value={order.number} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StepsToSend;
