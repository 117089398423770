import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


import * as actions from "../../actions/addresses.actions";
import * as paths from "../../config/paths";
import { ifUnauthorized } from "../../utils/authentication";
import Preloader from "../../components/Preloader";
import "./styles.scss";

function Addresses(props) {
    const [showPreloader, setShowPreloader] = useState(true);
    const [addresses, setAddresses] = useState([]);
    const history = useHistory();

    useEffect(() => {
        ifUnauthorized(history, getAddresses);
    }, []);

    const getAddresses = () => {
        actions.myAddresses()
            .then(response => {
                if (response.type === "SUCCESSFULLY") {
                    setAddresses(response.payload.data.map(address => {
                        return {
                            id: address.id,
                            name: address.attributes.name,
                            phone: address.attributes.phone,
                            email: address.attributes.email,
                            address1: address.attributes.street,
                            address2: address.attributes.street2,
                            postcode: address.attributes.postal_code,
                            addressScope: address.attributes.address_scope,
                            // lat: address.attributes.lat,
                            // lng: address.attributes.lng,
                            countryId: address.relationships.country.meta.data.id,
                            countryName: address.relationships.country.meta.data.attributes.name,
                            stateId: address.relationships.state.meta.data.id,
                            stateName: address.relationships.state.meta.data.attributes.name,
                        };
                    }));
                }
                setShowPreloader(false);
            })
            .catch(error => {
                console.log(error.response);
                setShowPreloader(false);
            });
    }

    const goToNewAddress = () => {
        history.push(paths.ADDRESSES.NEW);
    }

    const goToEditAddress = (addressId) => {
        history.push(paths.ADDRESSES.EDIT.PATH(addressId));
    }

    // const location = (lat, lng) => {
    //     window.open(`https://maps.google.com?q=${lat},${lng}`);
    // }

    return (
        <div className="div-tag div-tag--address-border-top">
            {showPreloader && <Preloader />}
            <div className="div-tag div-tag--address-container">
                <h2 className="h2-tag h2-tag--address-title-1">Mis Direcciones</h2>
                <a
                    className="a-tag a-tag--address-add"
                    onClick={() => goToNewAddress()}>
                    Añadir Dirección
                        </a>
                <div className="div-tag div-tag--addresses-list">
                    {addresses.map((address, index) => {
                        return (
                            <div key={index} className="div-tag div-tag--address-block">
                                <div className="div-tag--address-info-block">
                                    <p className="p-tag p-tag--address-1">
                                        {address.name} ({address.addressScope === "origin" ? "Origen" : "Destino"})
                                        </p>
                                    <p className="p-tag p-tag--address-2"><b>Teléfono:</b> {address.phone}</p>
                                    <p className="p-tag p-tag--address-2"><b>Email:</b> {address.email}</p>
                                    <p className="p-tag p-tag--address-2"><b>Dirección #1:</b> {address.address1}</p>
                                    <p className="p-tag p-tag--address-2"><b>Dirección #2:</b> {address.address2}</p>
                                    {address.addressScope =='origin'?
                                    <p className="p-tag p-tag--address-2"><b>Código postal:</b> {address.postcode}</p>
                                    :""
                                    }
                                    <p className="p-tag p-tag--address-2">Lugar: {address.stateName}, {address.countryName}</p>
                                </div>
                                <div className="div-tag div-tag--address-settings">
                                    <div className="div-tag div-tag--address-settings-separator"></div>
                                    <a
                                        className="a-tag a-tag--address-edit"
                                        onClick={() => goToEditAddress(address.id)} >
                                        Editar
                                    </a>
                                    {/* <a href="#" className="a-tag a-tag--address-delete">Eliminar</a> */}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default Addresses;
