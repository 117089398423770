import axios from "axios";

import * as apiRoutes from "../config/apiRoutes";

export const checkForUser = async (userData) => {
    const res = await axios.post(apiRoutes.CHECK_FOR_CUSTOMER, userData);
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}

export const login = async (userData) => {
    const res = await axios.post(apiRoutes.LOGIN, userData);
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}