import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const Button = props => (
    <button
        type={props.type}
        className={props.className}
        onClick={props.onClick}
        disabled={props.disabled}>
        {props.children}
    </button>
);

Button.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
}

Button.defaultProps = {
    type: "button",
    className: "",
    onClick: () => { },
    disabled: false,
}

export default Button;