import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const TextArea = props => {
    return (
        <textarea
            className={props.className}
            id={props.id}
            value={props.value}
            onChange={props.onChange}
            rows={props.rows}
            placeholder={props.placeholder}
            required={props.required}
            maxLength={props.maxLength}
            minLength={props.minLength}
        />
    )
}

TextArea.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    rows: PropTypes.number
}

TextArea.defaultProps = {
    className: "",
    id: "",
    value: "",
    onChange: () => { },
    rows: 10,
    maxLength: 250,
}

export default TextArea;