import React, { useEffect, useState } from "react";

import TextInput from "../../components/Inputs/TextInput";
import SelectInput from "../../components/Inputs/SelectInput";
import Button from "../../components/Buttons/Button";
// import GoogleMaps from "../../components/Map";
import Map2 from "../../components/Map/Map2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RadioButton from "../../components/Inputs/RadioButton";

import "./styles.scss";

const AddressForm = (props) => {
  const [name, setname] = useState();
  const [lastName, setlastName] = useState();
  const [phone, setphone] = useState();
  const [email, setemail] = useState();
  const [country, setcountry] = useState();
  const [state, setstate] = useState();
  const [locality, setlocality] = useState();
  const [address1, setaddress1] = useState();
  const [address2, setaddress2] = useState();
  const [stateName, setstateName] = useState();

  useEffect(() => {
    window.jquery('[data-toggle="tooltip"]').tooltip();
    window.jquery('[data-toggle="popover"]').popover({
      html: true,
    });
    if (props.isSpecialStep) {
      // console.log("Dentro del IF de AddressForm isSpecial??");
      // console.log(props);
      props.addresses.map((a) => {
        if (props.selectedAddress == a.id) {
          setname(a.name);
          setlastName(a.last_name);
          setphone(a.phone);
          setemail(a.email);
          setcountry(31);
          props.states.map((s) => {
            if (s.name == a.stateName) setstate(s.id);
          });
          setlocality(a.locality);
          setaddress1(a.address1);
          setaddress2(a.address2);
          setstateName(a.stateName);
        }
      });
    }
  });

  const onChange = (event) => {
    props.onChange(event);
  };

  // const onMapPress = event => {
  //     props.onMapPress(event);
  // }

  const onToggle = () => {
    props.onToggle();
  };

  const onSuggestHandler = (t) => {
    props.setDestinationLocality(t);
    props.setSuggestions([]);

    if (
      props.branchCopacabanaSelectedST &&
      props.selectedBranchInfoSThree.attributes.city.toUpperCase() ===
        t.toUpperCase()
    )
      props.setMatchCityST(true);
    else props.setMatchCityST(false);
  };

  const onChangeRadio = (event) => {
    const { id, value } = event.target;
    // setOptPAP(parseInt(event.target.value));

    if (props.isSpecialStep) props.setServicePaPSS(parseInt(value));
    else props.setServicePaPST(parseInt(value));
    // console.log("onChangeRadio");
    // console.log(id);
    // console.log(value);
    // console.log(props);
  };

  // SelectInput branch in form
  const onChangeSpecial = (event) => {
    event.persist();
    const { id, value } = event.target;

    console.log("Error???");
    console.log(props.branchCopacabana);
    console.log(props.selectecBranches);

    props.setBranchCopacabanaSelectedSS(value);

    if (!value) {
      props.setSelectedBranchInfoSSpecial();
      props.setMatchCitySS(false);
      props.setServicePaPSS(0);
    } else {
      let city;
      props.branchCopacabana.map((b) => {
        if (b.id == value) {
          props.setSelectedBranchInfoSSpecial(b);
          city = b.attributes.city;
        }
      });

      if (city.toUpperCase() === locality.toUpperCase()) {
        props.setMatchCitySS(true);
        console.log("locality");
        console.log(locality);
        console.log(city);
      } else {
        props.setMatchCitySS(false);
        props.setServicePaPSS(0);
      }
    }
  };

  const copacabanaBranches = props.isSpecialStep
    ? props.branchCopacabana
        .filter((b) => stateName == b.attributes.dpto)
        .map((b) => {
          return {
            id: b.id,
            name: b.attributes.office_name,
            address: b.attributes.address,
            city: b.attributes.city,
            lat: b.attributes.lat,
            lng: b.attributes.lng,
          };
        })
    : props.selectecBranches
    ? props.selectecBranches.map((b) => {
        return {
          id: b.id,
          name: b.attributes.office_name,
          address: b.attributes.address,
          city: b.attributes.city,
          lat: b.attributes.lat,
          lng: b.attributes.lng,
        };
      })
    : [];

  const branchAddress = props.isSpecialStep
    ? props.selectedBranchInfoSSpecial
      ? props.selectedBranchInfoSSpecial.attributes.address
      : ""
    : props.selectedBranchInfoSThree
    ? props.selectedBranchInfoSThree.attributes.address
    : "";

  const match = props.isSpecialStep ? props.matchCitySS : props.matchCityST;

  const lat = props.isSpecialStep
    ? props.selectedBranchInfoSSpecial
      ? parseFloat(props.selectedBranchInfoSSpecial.attributes.lat)
      : -17.783311
    : props.selectedBranchInfoSThree
    ? parseFloat(props.selectedBranchInfoSThree.attributes.lat)
    : -17.783311;

  const lng = props.isSpecialStep
    ? props.selectedBranchInfoSSpecial
      ? parseFloat(props.selectedBranchInfoSSpecial.attributes.lng)
      : -63.182129
    : props.selectedBranchInfoSThree
    ? parseFloat(props.selectedBranchInfoSThree.attributes.lng)
    : -63.182129;

  const branchNameOnTheMap = props.isSpecialStep
    ? props.selectedBranchInfoSSpecial
      ? props.selectedBranchInfoSSpecial.attributes.office_name
      : ""
    : props.selectedBranchInfoSThree
    ? props.selectedBranchInfoSThree.attributes.office_name
    : "";

  const mapURL =
    "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCVMIftwvyr9kd5lmiN549N4Gp0xChd8jc";

  return (
    <div className="custom-block custom-block__form custom-block__form--style">
      {props.addresses.length > 0 && !props.isSpecialStep ? (
        <Button className="wizard-select-address" onClick={() => onToggle()}>
          <FontAwesomeIcon icon={["fas", "undo"]} size="lg" /> Seleccionar
          dirección
        </Button>
      ) : (
        <React.Fragment />
      )}

      <div className="wizard-form-box">
        <div className="wizard-form-box-header">
          <label className="l-tag-form-box-title">
            Contacto del{" "}
            {props.step == 2
              ? "remitente"
              : props.step == 3
              ? "destinatario"
              : ""}{" "}
            {/* <FontAwesomeIcon
              icon={["fas", "info-circle"]}
              data-toggle="popover"
              data-placement="auto"
              data-trigger="hover"
              data-title={props.clues.contact.title}
              data-content={props.clues.contact.content}
            /> */}
          </label>
        </div>
        <div className="wizard-form-box-input-row">
          {/* First Name */}
          <div className="wizard-form-box-input-container">
            <label className="l-tag-form-content-title">
              Nombre <span className={"text-danger"}>*</span>
            </label>
            <TextInput
              className={
                props.isSpecialStep
                  ? "input-data form-control form-control-lg style-disabled"
                  : "input-data form-control form-control-lg"
              }
              id="name"
              value={props.isSpecialStep ? name : props.name}
              placeholder={
                props.step == 2
                  ? "Escribe el nombre del remitente"
                  : "Escribe el nombre del destinatario"
              }
              onChange={onChange}
              type="text"
              required={true}
              maxLength={30}
              minLength={4}
              disabled={props.isSpecialStep ? true : false}
            />
            <div className="invalid-feedback">
              Ingrese el nombre del contacto.
            </div>
          </div>
          {/* Last Name */}
          <div className="wizard-form-box-input-container">
            <label className="l-tag-form-content-title">
              Apellido <span className={"text-danger"}>*</span>
            </label>
            <TextInput
              className={
                props.isSpecialStep
                  ? "input-data form-control form-control-lg style-disabled"
                  : "input-data form-control form-control-lg"
              }
              id="lastName"
              value={props.isSpecialStep ? lastName : props.lastName}
              placeholder={
                props.step == 2
                  ? "Escribe el apellido del remitente"
                  : "Escribe el apellido del destinatario"
              }
              onChange={onChange}
              type="text"
              required={true}
              maxLength={30}
              minLength={4}
              disabled={props.isSpecialStep ? true : false}
            />
            <div className="invalid-feedback">
              Ingrese el apellido del contacto.
            </div>
          </div>
          {/* Phone */}
          <div className="wizard-form-box-input-container">
            <label className="l-tag-form-content-title">
              Teléfono <span className={"text-danger"}>*</span>
            </label>
            <TextInput
              className={
                props.isSpecialStep
                  ? "input-data form-control form-control-lg style-disabled"
                  : "input-data form-control form-control-lg"
              }
              id="phone"
              value={props.isSpecialStep ? phone : props.phone}
              placeholder="Escribe un número de teléfono"
              onChange={onChange}
              type="tel"
              pattern="^[0-9]+$"
              maxLength={25}
              minLength={7}
              required={true}
              disabled={props.isSpecialStep ? true : false}
            />
            <div className="invalid-feedback">
              Ingrese el número del telefono.
            </div>
          </div>
          {/* Email */}
          <div className="wizard-form-box-input-container">
            <label className="l-tag-form-content-title">
              Email <span className={"text-danger"}>*</span>
            </label>
            <TextInput
              className={
                props.isSpecialStep
                  ? "input-data form-control form-control-lg style-disabled"
                  : "input-data form-control form-control-lg"
              }
              id="email"
              value={props.isSpecialStep ? email : props.email}
              placeholder="Escribe una dirección de correo"
              onChange={onChange}
              type="email"
              required={true}
              maxLength={200}
              disabled={props.isSpecialStep ? true : false}
            />
            <div className="invalid-feedback">
              Ingrese el correo electronico del contacto.
            </div>
          </div>
        </div>
      </div>

      <div className="wizard-form-box">
        <div className="wizard-form-box-header">
          <label className="l-tag-form-box-title">
            {props.step == 2
              ? "Dirección del remitente"
              : props.step == 3
              ? "Dirección del destinatario en Bolivia"
              : ""}
            {/* <FontAwesomeIcon
              icon={["fas", "info-circle"]}
              data-toggle="popover"
              data-placement="auto"
              data-trigger="hover"
              data-title={props.clues.originAddress.title}
              data-content={props.clues.originAddress.content}
            /> */}
          </label>
        </div>
        <div className="wizard-form-box-input-row">
          {/* dir 1 */}
          <div className="wizard-form-box-input-stretch-container">
            <label className="l-tag-form-content-title">
              Línea de dirección #1 <span className={"text-danger"}>*</span>
            </label>
            <TextInput
              className={
                props.isSpecialStep
                  ? "input-data form-control form-control-lg style-disabled"
                  : "input-data form-control form-control-lg"
              }
              id="address1"
              value={props.isSpecialStep ? address1 : props.address1}
              placeholder={
                props.step == 2 ? "847 Atlantic Ave" : "Zona, Avenida, Calle, #"
              }
              onChange={onChange}
              type="text"
              required={true}
              maxLength={200}
              minLength={15}
              disabled={props.isSpecialStep ? true : false}
            />
            <div className="invalid-feedback">
              Ingrese la dirección del contacto.
            </div>
          </div>

          {/* dir 2 */}
          <div className="wizard-form-box-input-second_address-container">
            <label className="l-tag-form-content-title">
              Línea de dirección #2
            </label>
            <TextInput
              className={
                props.isSpecialStep
                  ? "input-data form-control form-control-lg style-disabled"
                  : "input-data form-control form-control-lg"
              }
              id="address2"
              value={props.isSpecialStep ? address2 : props.address2}
              placeholder="Describe la dirección"
              onChange={onChange}
              type="text"
              required={false}
              maxLength={200}
              disabled={props.isSpecialStep ? true : false}
            />
          </div>

          {/* pais */}
          <div className="wizard-form-box-input-container">
            <label className="l-tag-form-content-title">
              País <span className={"text-danger"}>*</span>
            </label>
            <SelectInput
              className={
                props.isSpecialStep
                  ? "form-control form-control-lg custom-select select-options style-disabled"
                  : "form-control form-control-lg custom-select select-options"
              }
              id="country"
              value={props.isSpecialStep ? country : props.countrySelected}
              options={props.countries}
              onChange={onChange}
              required={true}
              chooseLabel={"Seleccionar País"}
              disabled={props.isSpecialStep ? true : false}
            />
            <div className="invalid-feedback">
              Seleccione el país del contacto.
            </div>
          </div>

          {/* estado / dpto*/}
          <div className="wizard-form-box-input-container">
            <label className="l-tag-form-content-title">
              {props.step == 2 ? "Estado" : "Departamento"}{" "}
              <span className={"text-danger"}>*</span>
            </label>
            <SelectInput
              className={
                props.isSpecialStep
                  ? "!style-disabled form-control form-control-lg custom-select select-options"
                  : "form-control form-control-lg custom-select select-options"
              }
              id="state"
              value={props.isSpecialStep ? state : props.stateSelected}
              options={props.states}
              onChange={onChange}
              required={true}
              chooseLabel={
                props.step == 2
                  ? "Seleccionar Estado"
                  : "Seleccionar Departamento"
              }
              disabled={props.isSpecialStep ? true : false}
            />
            <div className="invalid-feedback">
              {props.step == 2
                ? "Seleccione el Estado"
                : "Seleccione el Departamento"}
            </div>
          </div>

          {/* codigo postal */}
          {props.step == 2 ? (
            <div className="wizard-form-box-input-container">
              <label className="l-tag-form-content-title">
                Código Postal <span className={"text-danger"}>*</span>
              </label>
              <TextInput
                className={
                  props.isSpecialStep
                    ? "input-data form-control form-control-lg style-disabled"
                    : "input-data form-control form-control-lg"
                }
                id="postcode"
                value={props.postcode}
                placeholder="50001"
                onChange={onChange}
                type="text"
                pattern="^[0-9]+$"
                required={true}
                maxLength={5}
                minLength={5}
              />
              <div className="invalid-feedback">
                Ingrese el código postal del contacto.
              </div>
            </div>
          ) : (
            ""
          )}

          {/* ciudad */}
          <div className="wizard-form-box-input-container">
            <label className="l-tag-form-content-title">
              Ciudad <span className={"text-danger"}>*</span>
            </label>
            <TextInput
              className={
                props.isSpecialStep
                  ? "input-data form-control form-control-lg style-disabled"
                  : "input-data form-control form-control-lg"
              }
              id="locality"
              value={props.isSpecialStep ? locality : props.locality}
              placeholder="Ingrese la ciudad"
              onChange={onChange}
              type="text"
              pattern="^[0-9]+$"
              required={true}
              maxLength={50}
              minLength={4}
              disabled={props.isSpecialStep ? true : false}
              onBlur={
                props.step === 3
                  ? () => {
                      setTimeout(() => {
                        props.setSuggestions([]);
                      }, 200);
                    }
                  : () => {}
              }
            />
            {props.suggestions &&
              props.suggestions.map((s, i) => (
                <div
                  id="suggestions"
                  key={i}
                  className="suggestions"
                  onClick={() => onSuggestHandler(s.city)}
                >
                  {s.city}
                </div>
              ))}

            <div className="invalid-feedback">Ingrese la ciudad</div>
          </div>

          {props.step === 3 ? (
            <React.Fragment>
              {/* sucursal */}
              {/* {console.log("DESDE ADRESSFROM")}
              {console.log(props)} */}
              <div className="wizard-form-box-input-stretch-container">
                <label className="l-tag-form-content-title">
                  Sucursal Trans Copacabana{" "}
                  <span className={"text-danger"}>*</span>
                </label>
                <SelectInput
                  className="form-control form-control-lg custom-select select-options text-dark"
                  id="branchCopacabana"
                  value={
                    props.isSpecialStep
                      ? props.branchCopacabanaSelectedSS
                      : props.branchCopacabanaSelectedST
                  }
                  options={copacabanaBranches}
                  onChange={props.isSpecialStep ? onChangeSpecial : onChange}
                  required={true}
                  chooseLabel={
                    "Seleccionar Sucursal más cercana al destinatario"
                  }
                />
                <div className="invalid-feedback">"Seleccione la sucursal"</div>
                <label className="l-tag-form-content-title mt-3">
                  Dirección de la sucursal:
                  <span className={"text-primary"}> {branchAddress}</span>
                </label>
              </div>

              {match ? (
                <React.Fragment>
                  {/* PaP */}
                  <div className="wizard-form-box-input-container">
                    <label className="l-tag-form-content-title">
                      Esta sucursal tiene la opción de
                    </label>
                    <label>
                      <span className={"text-danger"}>
                        Desea que la caja llegue a la puerta del destinatario?
                        (con costo adicional)
                      </span>
                    </label>
                    <RadioButton
                      id="pap"
                      name="pap"
                      options={props.optionsPaP}
                      value={
                        props.isSpecialStep
                          ? props.servicePaPSS
                          : props.servicePaPST
                      }
                      className="radio-button"
                      onChange={onChangeRadio}
                      required={true}
                    />
                    <div className="invalid-feedback">
                      "Seleccione una opción"
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              <div className="wizard-form-box-input-stretch-container">
                <label className="l-tag-form-content-title">
                  Sucursal de la Flota Copacabana{" "}
                  <FontAwesomeIcon
                    icon={["fas", "map-marked-alt"]}
                    data-toggle="popover"
                    data-placement="auto"
                    data-trigger="hover"
                    // data-title={props.clues.map.title}
                    // data-content={props.clues.map.content}
                  />
                </label>

                <Map2
                  branchLat={lat}
                  branchLng={lng}
                  titleMarker={branchNameOnTheMap}
                />
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
