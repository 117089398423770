import React, { useEffect } from "react";

import * as actions from "../../actions/global_parameters.actions";
import * as mapUtils from "../../utils/googleMaps";
import AddressList from "./AddressList";
import AddressForm from "./AddressForm";
import axios from "axios";

const StepTwo = props => {
    useEffect(() => {
        if (props.addresses.length <= 0) {
            onFormToggle();
        }
        console.log("props");
        console.log(props);

        // actions
        //   .zipCode()
        //   .then((response) => {
        //     console.log("Test consumiendo api rest");
        //     console.log(response);
        //   })
        //   .catch((error) => {
        //     console.log(error.response);
        //   });
    }, []);

    // const currentPosition = () => {
    //     if (props.address1 == "" && props.lat == null && props.Lng == null) {
    //         let geo_options = {
    //             enableHighAccuracy: true,
    //             maximumAge: 30000,
    //             timeout: 27000
    //         };
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             mapUtils.getInfoFromLatLng({
    //                 lat: position.coords.latitude,
    //                 lng: position.coords.longitude
    //             }, onMapPress, props.setAddress1);
    //         }, () => console.log("error"), geo_options);
    //     }
    // }

    const onFormToggle = () => {
        var form = document.getElementById("mainform");
        form.classList.remove('was-validated');
        props.setNewAddress(!props.newAddress);
    }

    const onSelectedAddressChange = event => {
        props.setSelectedAddress(parseInt(event.target.value));
        console.log("Cuando se selecciona un RADIO")
        console.log(props)
    }

    const onChange = event => {
        event.persist();
        const { id, value } = event.target;
        switch (id) {
            case "name":
                if (value.length <= 50) {
                    props.setName(value);
                }
                break;
            case "lastName":
              if (value.length <= 50) {
                props.setLastName(value);
              }
                break;
            case "phone":
                if (value.length <= 200) {
                    props.setPhone(value);
                }
                break;
            case "email":
                if (value.length <= 200) {
                    props.setEmail(value);
                }
                break;
            case "address1":
                if (value.length <= 200) {
                    props.setAddress1(value);
                }
                break;
            case "address2":
                if (value.length <= 200) {
                    props.setAddress2(value);
                }
                break;
            case "postcode":
                if (value.length <= 10) {
                  // mapUtils.getLatLngFromPostcode(value, onMapPress, props.setAddress1);
                  props.setPostcode(value);
                  if (value.length === 5) {
                    console.log("Ya son 5 digitos");
                    axios
                      .get(
                        `https://www.zipcodeapi.com/rest/js-4qHsWfJTkUm4eKApWG0clsZ4WWFabd1R1sqIlX5OTJ8JScr2VtNETSwk4Dpta1PU/info.json/${value}/radians`
                      )
                      .then((response) => {
                        const { status, data } = response;
                        if (status === 200) {
                          if (data.city) {
                            props.setOriginLocality(data.city);
                            props.setIsValidZipCode(true);
                            console.log("Dentro del if");
                            console.log(response);
                          } else {
                            props.setOriginLocality();
                            props.setIsValidZipCode(false);
                            console.log("En el ELSE");
                            console.log(response);
                          }
                        }

                        console.log("Fuera del if");
                        console.log(response);
                      })
                      .catch((error) => {
                        console.log("error");
                        console.log(error);
                        props.setOriginLocality();
                        props.setIsValidZipCode(false);
                      });
                  }
                }
                break;
            case "locality":
                props.setOriginLocality(value);
                break;
            case "country":
                props.setCountrySelected(value);
                if (value == 0) {
                    props.setStates([]);
                    props.setStateSelected(0);
                } else {
                    actions.globalParameters({ filters: ["states"], country_id: value })
                        .then(response => {
                            if (response.type === "SUCCESSFULLY") {
                                const { payload } = response;
                                props.setStates(payload.states.data.map(state => {
                                    return {
                                        id: state.id,
                                        name: state.attributes.name
                                    };
                                }));
                            }
                        })
                        .catch(error => {
                            console.log(error.response);
                        });
                }
                console.log("2 - Case Country");
                console.log(props);
                break;
            case "state":
                props.setStateSelected(value);
                console.log("2 - Case States");
                console.log(props);
                break;
            default:
                break;
        }
    }

    // const onMapPress = ({ lat, lng }) => {
    //     props.setLat(lat);
    //     props.setLng(lng);
    //     props.setMarker([{
    //         position: { lat, lng },
    //         item: { name: 'New Location' }
    //     }]);
    // }

    const clues = {
        sectionTitle: "Dirección de origen",
        contact: {
            title: "Nota",
            content: "Proporcionanos información del contacto que nos entregara el paquete."
        },
        originAddress: {
            title: "Nota",
            content: "Proporcionanos información de la dirección de donde recogeremos el paquete.",
        },
        map: {
            title: "Nota",
            content: "Debes proporcionarnos una ubicacion en el mapa, haciendo click."
        }
    }

    return (
        !props.newAddress ?
            <AddressList
                options={props.addresses}
                value={props.selectedAddress}
                onChange={onSelectedAddressChange}
                onToggle={onFormToggle} /> :
            <AddressForm
                // currentPosition={currentPosition}
                onChange={onChange}
                // onMapPress={onMapPress}
                onToggle={onFormToggle}
                addresses={props.addresses}
                name={props.name}
                lastName={props.lastName}
                phone={props.phone}
                email={props.email}
                address1={props.address1}
                address2={props.address2}
                postcode={props.postcode}
                countrySelected={props.countrySelected}
                countries={props.countries}
                locality={props.locality}
                stateSelected={props.stateSelected}
                states={props.states}
                branchCopacabana={[]}
                // lat={props.lat}
                // lng={props.lng}
                // marker={props.marker}
                clues={clues}
                step={2}
            // centerMap={{
            //     lat: 39.285791564482295,
            //     lng: -101.31998070209046
            // }}
            />
    )
}

export default StepTwo;
/*
Para el control del zip code
Se ha utilizado el servicio en https://www.zipcodeapi.com/AppManagement/
credenciales:
Application Key: MBfFeG0vv51YZT40v8Y8hYpVmqUMJmIv1C9yj0xu45YbZcCFqUqUutDcPJFlwH8O
Application Contact E-mail: qubytesoftdeveloper@gmail.com

Se ha registrado el dominio: authorized.easybolbox.com, para que esté permitido realizar las consultas desde ese dominio


*/
