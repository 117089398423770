import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./styles.scss";

import * as actions from "../../actions/addresses.actions";
import * as paths from "../../config/paths";
import * as globalActions from "../../actions/global_parameters.actions";
import { ifUnauthorized } from "../../utils/authentication";
import Preloader from "../../components/Preloader";
import AddressForm from "./AddressForm";

function EditAddress(props) {
    const [showPreloader, setShowPreloader] = useState(true);
    const [id, setId] = useState(null);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [postcode, setPostcode] = useState("");
    const [locality, setLocality] = useState("");
    const [originCountries, setOriginCountries] = useState([]);
    const [destinationCountries, setDestinationCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [countrySelected, setCountrySelected] = useState(0);
    const [stateSelected, setStateSelected] = useState(0);
    const [addressScopes, setAddressScopes] = useState([]);
    const [addressScopeSelected, setAddressScopeSelected] = useState("origin");
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [marker, setMarker] = useState([]);
    const history = useHistory();

    useEffect(() => {
        ifUnauthorized(history, getAddressAndGlobalParameters);
    }, []);

    const getAddressAndGlobalParameters = () => {
        actions.getAddressDetail({ address_id: props.match.params.id })
            .then(response => {
                const { data } = response.payload;
                setId(props.match.params.id);
                setName(data.attributes.name);
                setPhone(data.attributes.phone);
                setEmail(data.attributes.email);
                setAddress1(data.attributes.street);
                setAddress2(data.attributes.street2);
                setPostcode(data.attributes.postal_code);
                setLocality(data.attributes.city_locality);
                setAddressScopes([
                    { id: "origin", name: "Origen" },
                    { id: "destination", name: "Destino" }
                ]);
                setAddressScopeSelected(data.attributes.address_scope);
                setLat(data.attributes.lat);
                setLng(data.attributes.lng);
                setMarker([{
                    position: { lat: data.attributes.lat, lng: data.attributes.lng },
                    item: { name: 'New Location' }
                }]);
                setCountrySelected(data.relationships.country.data.id);
                setStateSelected(data.relationships.state.data.id);
                var countryId = data.relationships.country.data.id;
                globalActions.globalParameters({ filters: ["states"], country_id: countryId })
                    .then(response => {
                        if (response.type === "SUCCESSFULLY") {
                            const { payload } = response;
                            setStates(payload.states.data.map(state => {
                                return {
                                    id: state.id,
                                    name: state.attributes.name
                                }
                            }));
                            globalActions.globalParameters({ filters: ["countries"], filter_mode: "origin" })
                                .then(response => {
                                    if (response.type === "SUCCESSFULLY") {
                                        const { payload } = response;
                                        setOriginCountries(payload.countries.data.map(country => {
                                            return {
                                                id: country.id,
                                                name: country.attributes.name
                                            }
                                        }));
                                        globalActions.globalParameters({ filters: ["countries"], filter_mode: "destination" })
                                            .then(response => {
                                                if (response.type === "SUCCESSFULLY") {
                                                    const { payload } = response;
                                                    setDestinationCountries(payload.countries.data.map(country => {
                                                        return {
                                                            id: country.id,
                                                            name: country.attributes.name
                                                        }
                                                    }));
                                                    setShowPreloader(false);
                                                }
                                            })
                                            .catch(error => {
                                                console.log(error.response);
                                            })
                                    }
                                })
                                .catch(error => {
                                    console.log(error.response);
                                })
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
            .catch(error => {
                console.log(error.response);
                setShowPreloader(false);
                history.push(paths.ADDRESSES.LIST);
            });
    }

    const onChange = event => {
        const { id, value } = event.target;
        switch (id) {
            case "name":
                if (value.length <= 50) {
                    setName(value)
                }
                break;
            case "phone":
                if (value.length <= 200) {
                    setPhone(value);
                }
                break;
            case "email":
                if (value.length <= 200) {
                    setEmail(value);
                }
                break;
            case "address1":
                if (value.length <= 200) {
                    setAddress1(value);
                }
                break;
            case "address2":
                if (value.length <= 200) {
                    setAddress2(value);
                }
                break;
            case "postcode":
                if (value.length <= 9) {
                    setPostcode(value);
                }
                break;
            case "locality":
                if (value.length <= 50) {
                    setLocality(value);
                }
                break;
            case "address_scope":
                setAddressScopeSelected(value);
                setCountrySelected(0);
                setStateSelected(0);
                setStates([]);
                break;
            case "country":
                setCountrySelected(value);
                if (value == 0) {
                    setStates([]);
                    setStateSelected(0);
                } else {
                    globalActions.globalParameters({ filters: ["states"], country_id: value })
                        .then(response => {
                            if (response.type === "SUCCESSFULLY") {
                                const { payload } = response;
                                setStates(payload.states.data.map(state => {
                                    return {
                                        id: state.id,
                                        name: state.attributes.name
                                    }
                                }));
                            }
                        })
                        .catch(error => {
                            console.log(error.response);
                        });
                }
                break;
            case "state":
                setStateSelected(value);
                break;

        }
    }

    const onMapPress = ({ lat, lng }) => {
        setLat(lat);
        setLng(lng);
        setMarker([{
            position: { lat, lng },
            item: { name: 'New Location' }
        }]);
    }

    const validateData = () => {
        if (countrySelected !== 0 &&
          stateSelected !== 0 &&
          name != "" &&
          address1 != "" &&
          locality != "") {
            return true;
        } else {
            return false;
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setShowPreloader(true);
        if (validateData()) {
            let data = {
                address_id: id,
                name: name,
                phone: phone,
                email: email,
                street: address1,
                street2: address2,
                postal_code: postcode,
                city_locality: locality,
                country_id: countrySelected,
                state_id: stateSelected,
                address_scope: addressScopeSelected,
                lat: lat,
                lng: lng
            }
            actions.updateAddress(data)
                .then(response => {
                    if (response.type === "SUCCESSFULLY") {
                        setShowPreloader(true);
                        history.push(paths.ADDRESSES.LIST);
                    }
                })
                .catch(error => {
                    console.log(error.response);
                    setShowPreloader(false);
                });
        } else {
            setShowPreloader(false);
            alert("Hay campos que necesita llenar para guardar la dirección.");
        }
    }

    return (
        <div className="container">
            {showPreloader && <Preloader />}
            <AddressForm
                name={name} phone={phone} email={email} address1={address1}
                address2={address2} postcode={postcode} locality={locality}
                originCountries={originCountries} destinationCountries={destinationCountries} states={states}
                countrySelected={countrySelected} stateSelected={stateSelected}
                addressScopes={addressScopes} addressScopeSelected={addressScopeSelected}
                lat={lat} lng={lng} marker={marker}
                onChange={onChange} onMapPress={onMapPress} onSubmit={onSubmit} />
        </div>
    );
}

export default EditAddress;
