import React from "react";
import { withRouter, Route, Redirect, useLocation } from "react-router-dom";

import * as path from "../../../config/paths";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  return (
    <Route
        {...rest}
        render={props =>
            localStorage.getItem("user_token") ? (
                <Component {...props} />
            ) : (
                    <Redirect to={{
                      pathname: path.AUTH.LOGIN,
                      search: location.search
                    }} />
                )
        }
    />)
}

export default withRouter(PrivateRoute);
