import axios from "axios";

import * as apiRoutes from "../config/apiRoutes";

export const globalParameters = async (params) => {
    const res = await axios.get(apiRoutes.GLOBAL_PARAMETERS, { params: params });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}

export const updateOrder = async (params) => {
    const res = await axios.post(apiRoutes.UPDATE_ORDER, params, {
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`,
            "content-type": "multipart/form-data"
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}

export const checkoutStatus = async (params) => {
    const res = await axios.get(apiRoutes.CHECKOUT_STATUS, {
        params: params,
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}

export const validateAddress = async (params) => {
    const res = await axios.post(apiRoutes.VALIDATE_ADDRESS, params, {
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type == "SUCCESSFULLY") {
        return { type: type, payload: payload }
    } else {
        throw { type: type, payload: payload }
    }
}

export const totalAmount = async (params) => {
    const res = await axios.get(apiRoutes.ORDER_TOTAL_AMOUNT, {
        params: params,
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}

