import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const NumberInput = props => (
    <input
        type="number"
        className={props.className}
        id={props.id}
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        onChange={props.onChange}
        required={props.required}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        onFocus={props.onFocus}
    />
);

NumberInput.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    pĺaceholder: PropTypes.string,
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
}

NumberInput.defaultProps = {
    className: "",
    id: "",
    placeholder: "",
    // value: 0,
    min: 0,
    max: undefined,
    step: 1,
    disabled: false,
    required: false,
    onChange: () => { },
    onFocus: () => { },
}

export default NumberInput;
