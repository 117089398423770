import React from "react";
import { Fragment } from "react";
import * as route from "../../config/apiRoutes";

const StepOne = (props) => {
  const onBoxTypeSelect = (id) => {
    if (props.boxTypeSelected == id) {
      props.setBoxTypeSelected(0);
    } else {
      props.setBoxTypeSelected(id);
    }

    // let idSpecial = 0;
    // props.boxTypes.map((box) => {
    //   if (box.attributes.name == "Caja Especial")
    //     idSpecial = box.attributes.code;
    // });

    // if (idSpecial == props.boxTypeSelected) props.setItems([]);
  };

  const boxSelected = props.boxTypes.find(
    (box) => box.id == props.boxTypeSelected
  );

  return (
    <React.Fragment>
      <div className="custom-block custom-block__option custom-block--select">
        {props.boxTypes.map((box) => {
          return (
            <div
              key={`box-${box.id}`}
              className={
                props.boxTypeSelected == box.id
                  ? "custom-block__box_selected custom-block--box-margin"
                  : "custom-block__box custom-block--box-margin"
              }
              onClick={() => onBoxTypeSelect(box.id)}
            >
              <div className="box-header-container">
                <p className="p-tag p-tag--box-title">{box.attributes.name}</p>
                {/* <p className="p-tag p-tag--box-description text-danger">
                  {box.attributes.description}
                </p> */}
              </div>
              <div className="box-image-container">
                <img
                  className="custom-block__image"
                  src={`${route.DOMAIN}${box.attributes.image_url}`}
                  alt={`box-${box.id}`}
                />
                <p className="p-tag p-tag--box-price">
                  US ${box.attributes.price}
                </p>
              </div>
              <div className="box-detail-container">
                {box.relationships.metadata_attributes.meta.data.map(
                  (feature) => {
                    return (
                      <label
                        key={`box-feature-${box.id}-${feature.id}`}
                        className="p-tag--box-detail"
                      >
                        {feature.attributes.key}: {feature.attributes.value}
                      </label>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="considerations">
        <h6 className="consejo-caja">
          Es importante que usted tome en cuenta las dimensiones y peso de su caja seleccionada.
        </h6>
        <h6 className="considerations-title">Consideraciones</h6>
        <ul>
          <li>
            El valor máximo permitido por caja con ropa es de $ 200 USD.
            {/*
              boxSelected
              ? boxSelected.relationships.metadata_attributes.meta.data.map(
                (feature) => {
                  if (feature.attributes.key == "Peso")
                    return feature.attributes.value;
                }
              )
              : "0 kg"*/}
          </li>
          {/* <li>
            La caja que usted elija puede comprarla en las oficinas de <strong>"UPS"</strong>  o comprarlas en el sitio web de{" "}
            <a href="https://www.theupsstore.com/pack-ship" target="_blank">
              The UPS Store.
            </a>{" "}
            Favor de tomar nota de las dimensiones especificadas.
          </li> */}
          <li>
            Debido a las Regulaciones de Aduana Boliviana, no es posible enviar ropa y otros accesorios en una misma caja. Favor tomar en cuenta y preparar en cajas separadas.
          </li>
          <li>
            Los datos que va ingresar de los productos de su caja pueden borrarse si refresca o cierra el navegador, o si apaga el computador.
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default StepOne;
