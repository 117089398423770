import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
// import "./App.css";

const Marker = ({ text }) => (
  <div>
    <div className="marker bg-danger"></div>
    <h5 className="mt-1 text-danger">{text}</h5>
  </div>
);

const Map = (props) => {
  // const [center, setCenter] = useState({ lat: -17.783258, lng: -63.175347 });
  // const [zoom, setZoom] = useState(16);
  // const branchName = props.selectedBranchInfo
  //   ? props.selectedBranchInfo.attributes.office_name
  //   : "";


  return (
    <div style={{ height: "40vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_MAPS_ID}` }}
        defaultCenter={{ lat: -17.783311, lng: -63.182129 }}
        center={{lat: props.branchLat, lng: props.branchLng}}
        defaultZoom={14}
      >
        <Marker lat={props.branchLat} lng={props.branchLng} text={props.titleMarker} />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
