import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import * as path from "./paths";
import PrivateRoute from "../components/Routes/PrivateRoute";
import AuthRoute from "../components/Routes/AuthRoute";
import Header from "../components/Header";

import Home from "../modules/Home/Home";
import Login from "../modules/Auth/Login";
import Wizard from "../modules/Auth/Wizard";
import Profile from "../modules/Profile/Profile";
import Contact from "../modules/Contact/Contact";
import Addresses from "../modules/Addresses/Addresses";
import NewAddress from "../modules/Addresses/NewAddress";
import EditAddress from "../modules/Addresses/EditAddress";
import Orders from "../modules/Orders/Orders";
import OrdersDetail from "../modules/Orders/Detail";
import Tracking from "../modules/Tracking/Tracking";

import Success from "../modules/Home/Success";
import Cancel from "../modules/Home/Cancel";
import StepsToSend from "../modules/Orders/StepsToSend";

const Routes = props => (
    <div className="">
        <Header />
        <Switch>
            <AuthRoute exact path={path.AUTH.LOGIN} component={Login} />
            <AuthRoute exact path={path.AUTH.WIZARD} component={Wizard} />
            <PrivateRoute exact path={path.HOME} component={Home} />
            <PrivateRoute exact path={path.PROFILE} component={Profile} />
            <PrivateRoute exact path={path.CONTACT} component={Contact} />
            <PrivateRoute exact path={path.SUCCESS} component={Success} />
            <PrivateRoute exact path={path.CANCEL} component={Cancel} />
            <PrivateRoute exact path={path.ADDRESSES.LIST} component={Addresses} />
            <PrivateRoute exact path={path.ADDRESSES.NEW} component={NewAddress} />
            <PrivateRoute exact path={path.ADDRESSES.EDIT.ROUTE} component={EditAddress} />
            <PrivateRoute exact path={path.ORDERS.LIST} component={Orders} />
            <PrivateRoute exact path={path.ORDERS.DETAIL.ROUTE} component={OrdersDetail} />
            <PrivateRoute exact path={path.ORDERS.SEND.ROUTE} component={StepsToSend} />
            <PrivateRoute exact path={path.TRACKING} component={Tracking} />
        </Switch>
    </div>
);

export default withRouter(Routes);
