import debounce from 'lodash.debounce';

export const getLatLngFromPostcode = debounce((value, onPress, setState) => {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: value }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
            onPress({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
            });
        } else {
            console.log("Request failed");
            onPress({
                lat: null,
                lng: null
            });
        }
    });
}, 1000)

export const getInfoFromLatLng = (value, onPress, setState) => {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: value }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
            onPress(value);
            setState(results[0].formatted_address)
        } else {
            console.log("Request failed");
        }
    })
}
