import React, { Component } from "react";

import Button from "./Button";
import FacebookImage from "../../assets/img/facebook.svg";

import "./styles.scss";

window.fbAsyncInit = function () {
    window.FB.init({
        appId: `${process.env.REACT_APP_FACEBOOK_ID}`,
        // appId: 237393525210299,
        cookie: true,                     // Enable cookies to allow the server to access the session.
        xfbml: true,                     // Parse social plugins on this webpage.
        version: 'v8.0'           // Use this Graph API version for this call.
    });
};

class FacebookButton extends Component {
    componentDidMount() {
        this._isMounted = true;
        const authScript = document.createElement('script');
        authScript.id = `auth-method-facebook`
        authScript.src = "https://connect.facebook.net/es_LA/sdk.js";
        authScript.async = true;
        authScript.defer = true;
        authScript.crossOrigin = 'anonymous';
        window.document.body.appendChild(authScript);
        authScript.addEventListener('load', () => {
            // console.log('Facebook auth script loaded');
        });
    }

    render() {
        return (
            <Button className={this.props.className} onClick={this.props.onClick}>
                <img className="img-tag--button" src={FacebookImage} />
                Ingresar con Facebook
            </Button>
        )
    }
}

export default FacebookButton;
