import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { ifUnauthorized } from "../../utils/authentication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as actions from "../../actions/global_parameters.actions";
import * as paths from "../../config/paths";
import Preloader from "../../components/Preloader";
import Timer from "../../components/Timer/Timer";

function Cancel(props) {
  const [showPreloader, setShowPreloader] = useState(true);
  const [idDetailOrder, setIdDetailOrder] = useState(null);
  const history = useHistory();

  useEffect(() => {
    ifUnauthorized(history, () => {});
  }, []);

  const SECONDS_TO_REDIRECT = 20;

  const verifyPayment = () => {
    const query = new URLSearchParams(props.location.search);
    const sessionId = query.get("session_id");
    if (sessionId) {
      actions
        .checkoutStatus({ session_id: `${sessionId}` })
        .then((response) => {
          if (response.type === "SUCCESSFULLY") {
            console.log("CANCEL PAYMENT");
            console.log(response);
            // setPaymentSuccess(true);
            // setOrderNumber(response.payload.data.attributes.order_number);
            // setOrderState(response.payload.data.relationships.money_transaction.meta.data.relationships.transaction_state.meta.data.attributes.name);
            setIdDetailOrder(response.payload.data.id);
          }
          setShowPreloader(false);
        })
        .catch((error) => {
          console.log(error.response);
          setShowPreloader(false);
        });
    } else {
      // history.push(paths.HOME);
      console.log("error");
    }
  };

  const onSuccessTimer = () => {
    history.push(paths.ORDERS.LIST);
  };

  return (
    <div className="container">
      <div className="custom-block custom-block__form custom-block__form--style">
        <div className="payment-message-box">
          <div className="header-payment-container cancel">
            <FontAwesomeIcon icon={["fas", "info-circle"]} size="3x" />
            <h4>Su transacción no ha sido completada.</h4>
            <h5>Debe proceder a pagar para confirmar su orden.</h5>
            <h6>Puede pagarlo desde "Mis Órdenes"</h6>
            <Timer
              seconds={SECONDS_TO_REDIRECT}
              onSuccessTimer={onSuccessTimer}
            ></Timer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Cancel);
