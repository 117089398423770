
// Notifiers

export const notifySuccess = (message = 'success') => {
    window.alertify.set('notifier', 'position', 'top-right');
    window.alertify.notify(message, 'success')
}

export const notifyWarning = (message = 'warning') => {
    window.alertify.set('notifier', 'position', 'top-right');
    window.alertify.notify(message, 'warning')
}

export const notifyError = (message = 'error') => {
    window.alertify.set('notifier', 'position', 'top-right');
    window.alertify.notify(message, 'error')
}

export const notifyMessage = (message = 'message') => {
    window.alertify.set('notifier', 'position', 'top-right');
    window.alertify.notify(message, 'message')
}

export const notifyWarningCenter = (message = 'warning') => {
  window.alertify.set('notifier', 'position', 'top-center');
  window.alertify.notify(message, 'error')
}


// Prompts

export const promptForced = (title = "title", message = "message",
    defaultValue = "type a value", onok = null, oncancel = null) => {
    window.alertify.dialog('prompt')
        .setting({
            'closableByDimmer': false,
            'closable': false,
            'title': title,
            'message': message,
            'defaultValue': defaultValue,
            'onok': onok,



            'oncancel': oncancel
        }).show();
}

export const promptOrder = (title = "title", message = "message",
    defaultValue = "type a value", onok = null, oncancel = null) => {
    window.alertify.dialog('prompt')
        .setting({
            'closableByDimmer': true, // closable true
            'closable': true,
            'title': title,
            'message': message,
            'defaultValue': defaultValue,
            'onok': onok,

            'invokeOnCloseOff': true,
            'labels': { ok: 'Confirmar', cancel: 'Cerrar' },
            'maximizable': true,
            'modal': false,
            'movable': true,
            'moveBounded': true,
            'pinnable': true, //works with modal true
            'reverseButtons': false,
            'startMaximized': false,
            'transition': 'pulse', // slide, zoom ,flipx ,flipy ,fade ,pulse
            'transitionOff': false,
            'type': 'text', //text, color, date, datetime-local, email, month, number, password ,search, tel ,time, week


            'oncancel': oncancel
        }).show();

    //invokeoncloseoff
    //labels

}

export const prompt = (title = "title", message = "message",
    defaultValue = "type a value", onok = null, oncancel = null) => {
    window.alertify.dialog('prompt')
        .setting({
            'title': title,
            'message': message,
            'defaultValue': defaultValue,
            'onok': onok,
            'oncancel': oncancel
        }).show();
}

