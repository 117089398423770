import React from "react";

import PropTypes from "prop-types";

const SelectInput = props => {
    return (
        <select
            id={props.id}
            className={props.className}
            value={props.value}
            onChange={props.onChange}
            required={props.required}
            autoComplete={props.autocomplete ? props.autocomplete : "off"}
            disabled={props.disabled}
        >
            {props.blankOption ? <option value={""}>{props.chooseLabel ? props.chooseLabel : "Seleccionar..."}</option> : null}
            {props.options.map(option => {
                return (
                    <option key={option.id} value={option.id}>{option.name}</option>
                );
            })}
        </select>
    );
}

SelectInput.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array,
    blankOption: PropTypes.bool,
    onChange: PropTypes.func,
    disabled:PropTypes.bool,
}

SelectInput.defaultProps = {
    className: "",
    id: "",
    value: "",
    options: [],
    blankOption: true,
    onChange: () => { },
    disabled: false,
}

export default SelectInput;
