import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import "./styles.scss";

import * as actions from "../../actions/tracking.actions";
import { ifUnauthorized } from "../../utils/authentication";
import TextInput from "../../components/Inputs/TextInput";
import Button from "../../components/Buttons/Button";
import Preloader from "../../components/Preloader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Tracking(props) {
    const [showPreloader, setShowPreloader] = useState(true);
    const [orderNumber, setOrderNumber] = useState("");
    const [orderNumberFromUrl, setOrderNumberFromUrl] = useState(false);
    const [trackingLog, setTrackingLog] = useState([]);
    const history = useHistory();

    useEffect(() => {
        ifUnauthorized(history, loadOrderTracking);
    }, []);

    useEffect(() => {
        if (orderNumber !== "" && orderNumberFromUrl) {
            onOrderTrack();
            setOrderNumberFromUrl(false);
        }
    }, [orderNumber])

    const loadOrderTracking = () => {
        const query = new URLSearchParams(props.location.search);
        const orderNum = query.get("order_number");
        if (orderNum) {
            setOrderNumberFromUrl(true);
            setOrderNumber(orderNum);
        }
        setShowPreloader(false);
    }

    const onChange = (event) => {
        setOrderNumber(event.target.value);
    }

    const onOrderTrack = () => {
        setShowPreloader(true);
        actions.orderTracking({ order_number: orderNumber })
            .then(response => {
                setTrackingLog(response.payload.tracking_logs.data);
                setShowPreloader(false);
            })
            .catch(error => {
                console.log(error.response);
                setShowPreloader(false);
                alert("No se encontró el envío con ese número o algún inconveniente se presentó en la solicitud.")
            })
    }

    return (
        <div className="container">
            {showPreloader && <Preloader />}
            <div className="search-container-title">
                <div className="search-title">
                    Seguimiento de ordenes
                </div>
                <div className="search-description">
                    Ingresa el nro de orden para hacer el seguimiento a tu paquete
                </div>
            </div>
            <div className="search-bar">
                <div className="search-container margin-containers">
                    <TextInput
                        className="form-control form-control-lg search-text input-data-search"
                        id="order_number"
                        value={orderNumber}
                        onChange={onChange}
                        placeholder={"Ingrese el número de su orden"}
                    />
                </div>
                <div className="button-container margin-containers">
                    <Button
                        className="search-button"
                        onClick={() => onOrderTrack()}>Seguimiento
                        {" "}
                        <FontAwesomeIcon icon={['fas', 'search-location']} />
                    </Button>
                </div>
            </div>
            <div className="tracking-box">
                {trackingLog.map((log, index) => {
                    return (
                        <div key={index} className="timeline">
                            <div className="timeline-indicator"></div>
                            {trackingLog.length - 1 > index ? <div className="line"></div> : null}
                            <b>{log.attributes.title}</b>
                            <p>{dayjs(log.attributes.tracking_datetime).format("DD/MM/YYYY - hh:mm A")}</p>
                            <p>{log.attributes.description}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Tracking;