import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./config/Routes";
import * as serviceWorker from './serviceWorker';

//jquery
import $ from "jquery";

//popper for tooltips
import "popper.js";

//bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

// fontawesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
// import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import {
  // fas, //import all solid icons
  faCheckSquare,
  faCoffee,
  faQuestionCircle as fasQuestionCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faExclamationCircle as fasExclamationCircle,
  faInfo as fasInfo,
  faInfoCircle as fasInfoCircle,
  faMapMarkedAlt as fasMarkedAlt,
  faPlusCircle as fasPlusCircle,
  faAddressBook as fasAddressBook,
  faCheckCircle as fasCheckCircle,
  faTimesCircle as fasTimesCircle,
  faClock as fasClock,
  faImages as fasImages,
  faBoxOpen as fasBoxOpen,
  faPaperclip as fasPaperClip,
  faTrash as fasTrash,
  faTrashAlt as fasTrashAlt,
  faWeightHanging as fasWeightHangig,
  faUpload as fasUpload,
  faUndo as fasUndo,
  faMoneyCheck as fasMoneyCheck,
  faMoneyCheckAlt as fasMoneyCheckAlt,
  faSearchLocation as fasSearchLocation,
  faTshirt as fasTshirt,
  faCut as fasCut,
  faMapMarkerAlt as fasMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
  // far,  //import all regular icons
  faQuestionCircle as farQuestionCircle,
  faCheckCircle as farCheckCircle,
  faTimesCircle as farTimesCircle,
  faAddressBook as farAddressBook,
  faClock as farClock,
  faImages as farImages,
  faTrashAlt as farTrashAlt,
  // faExclamationTriangle as farExclamationTriangle,
} from "@fortawesome/free-regular-svg-icons";

// Alertify Global
import 'alertifyjs/build/css/alertify.css';
import 'alertifyjs/build/css/themes/default.min.css';

const alertify = require("alertifyjs/build/alertify");
window.alertify = alertify;

window.jquery = $;


library.add(fab,
  // far,
  // fas,
  faCheckSquare,
  faCoffee,
  fasQuestionCircle,
  farQuestionCircle,
  fasExclamationTriangle,
  // farExclamationTriangle
  fasExclamationCircle,
  fasInfo,
  fasInfoCircle,
  fasMarkedAlt,
  fasPlusCircle,
  fasAddressBook,
  farAddressBook,
  fasCheckCircle,
  farCheckCircle,
  fasTimesCircle,
  farTimesCircle,
  fasClock,
  farClock,
  fasImages,
  farImages,
  fasBoxOpen,
  fasPaperClip,
  fasTrash,
  fasTrashAlt,
  farTrashAlt,
  fasWeightHangig,
  fasUpload,
  fasUndo,
  fasMoneyCheck,
  fasMoneyCheckAlt,
  fasSearchLocation,
  fasTshirt,
  fasCut,
  fasMapMarkerAlt,
);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//Tooltips
$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

//Popovers
$(function () {
  $('[data-toggle="popover"]').popover({
    html: true
  })
})

// $('.popover-dismiss').popover({
//   trigger: 'focus'
// })

// $('')
