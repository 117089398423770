import React, { useState, useEffect, useRef } from "react";

import * as alerts from "../../utils/alertify";
import TextInput from "../../components/Inputs/TextInput";
import TextArea from "../../components/Inputs/TextArea";
import NumberInput from "../../components/Inputs/NumberInput";
import Button from "../../components/Buttons/Button";
// import FileInput from "../../components/Inputs/FileInput";
import SelectInput from "../../components/Inputs/SelectInput";
// import Button from "../../components/Buttons/Button";
// import SliderNumberInput from "../../components/Inputs/SliderNumberInput";
// import RadioButton from "./../../components/Inputs/RadioButton";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Etiq from "../../assets/img/etiqueta.jpg";
import vitaminC from "../../assets/img/vitaminC.png";
import vitaminD from "../../assets/img/vitaminD.png";
import vitaminB from "../../assets/img/vitaminB.png";
import vitaminZinc from "../../assets/img/vitaminZinc.png";
import alertify from "alertifyjs";
import { each } from "jquery";

const StepFour = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [brand, setBrand] = useState("");
  const [gender, setGender] = useState("");
  const [material, setMaterial] = useState("");
  const [unitPrice, setUnitPrice] = useState(0);
  const [qty, setQty] = useState(0);
  const [canAdd, setCanAdd] = useState(false);
  const [idSpecialBox, setIdSpecialBox] = useState(0);
  const [idClothing, setIdClothing] = useState(0);
  const [idDevicesWithBattery, setIdDevicesWithBattery] = useState(0);
  const [idOtherItem, setIdOtherItem] = useState(0);
  const [isCategoryTypeSelected, setIsCategoryTypeSelected]=useState(false)

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  useEffect(() => {
    window.jquery('[data-toggle="tooltip"]').tooltip();
    window.jquery('[data-toggle="popover"]').popover({
      html: true,
    });

    props.boxTypes.map((box) => {
      if (box.attributes.name == "Caja Salud") {
        setIdSpecialBox(box.attributes.code);
      }
    });
    props.itemCategories.map((i) => {
      if (i.name == "Ropa") setIdClothing(i.id);
      else if (i.name == "Dispositivos electrónicos con batería incorporada")
        setIdDevicesWithBattery(i.id);
      else if (i.name == "Otros") setIdOtherItem(i.id);
    });

    if (props.items.length > 0) {
      setCanAdd(false);
    }
  }, []);

  const onChange = (event) => {
    event.persist();
    const { id, value } = event.target;
    switch (id) {
      case "additional_note":
        if (value.length <= 700) {
          props.setAdditionalNote(value);
        }
        break;
      case "item_category":
        console.log("value");
        console.log(value);
        if (!value) {
          setCanAdd(false);
          setIsCategoryTypeSelected(false);
        } else {
          setCanAdd(true);
          setIsCategoryTypeSelected(true);
        }
        props.setItemCategorySelected(value);
        props.setItems([]);
        clearItemData();
        break;
      // case "weight":
      //     if (event.target.value === "") {
      //         props.setWeight(0);
      //     } else {
      //         props.setWeight(parseInt(value));
      //         // props.setWeight(parseFloat(event.target.value));TODO
      //     }
      //     break;
      default:
        break;
    }
  };

  const onMeasureChange = (event) => {
    event.persist();
    const { value } = event.target;
    let measure = parseInt(value, 10);
    props.setWeightMeasure(measure);
    if (measure === 0) {
      props.setWeight((props.weight * 2.2).toFixed(2));
    } else {
      props.setWeight((props.weight / 2.2).toFixed(2));
    }
  };

  const onImageChange = (event) => {
    if (
      event.target.files &&
      event.target.files[0] &&
      props.attachments.length < 3
    ) {
      const { files } = event.target;
      let img = URL.createObjectURL(files[0]);

      let newAttachments = props.attachments;
      newAttachments.push({
        src: img,
        file: files[0],
      });
      props.setAttachments([...newAttachments]);
    }
  };

  const onImageDelete = (index) => {
    let newAttachments = props.attachments;
    newAttachments = newAttachments.filter((newAttachment, newIndex) => {
      return newIndex !== index;
    });
    props.setAttachments([...newAttachments]);
  };

  const validateItem = () => {
    if (props.itemCategorySelected == idClothing) {
      return (
        name !== "" &&
        description !== "" &&
        brand !== "" &&
        gender !== "" &&
        material !== "" &&
        qty !== 0 &&
        qty !== "" &&
        unitPrice !== 0 &&
        unitPrice !== ""
      );
    }
    return (
      name !== "" &&
      description !== "" &&
      brand !== "" &&
      qty !== 0 &&
      qty !== "" &&
      unitPrice !== 0 &&
      unitPrice !== ""
    );
  };

  const clearItemData = () => {
    setName("");
    setDescription("");
    setBrand("");
    setGender("");
    setMaterial("");
    setQty(0);
    setUnitPrice(0);
  };

  const onItemAdd = () => {
    if (validateItem()) {
      if (props.itemCategorySelected == idClothing) {
        if (clothesLimit()) {
          addItem();
        } else {
          alertify.confirm(
            "Revise su caja",
            "No puedes exceder los $ 200 en ropa.",
            function () { },
            function () {
              alerts.notifyWarning("Debe revisar su caja.");
            }
          );
        }
      }
      else {
        addItem();
      }
    } else {
      alerts.notifyWarning("Rellene los campos necesarios del producto. </>");
    }
  };

  const addItem = () => {
    props.setItems([
      {
        name: name,
        description: description,
        brand: brand,
        gender: gender,
        material: material,
        qty: qty,
        unit_price: unitPrice,
      },
      ...props.items,
    ]);
    console.log("props.items");
    console.log(props.items);
    clearItemData();
    setCanAdd(false);
  };

  const clothesLimit = () => {
    let amount = 0;
    props.items.map((item) => {
      amount += parseFloat(item.qty) * parseFloat(item.unit_price);
    });
    amount += parseFloat(qty) * parseFloat(unitPrice);
    return amount <= 200;
  };

  const onItemRemove = (index) => {
    let newItems = props.items.filter(
      (item, arrayIndex) => index != arrayIndex
    );
    props.setItems(newItems);
  };

  const onItemContentChange = (event) => {
    // let newItem = props.items[index];
    // let newItems = props.items;
    const { id, value } = event.target;
    switch (id) {
      case "name":
        if (value.length <= 200) {
          // newItem.name = value;
          setName(value);
        }
        break;
      case "description":
        if (value.length <= 200) {
          // newItem.description = value;
          setDescription(value);
        }
        break;
      case "brand":
        if (value.length <= 50) {
          // newItem.brand = value;
          setBrand(value);
        }
        break;
      case "gender":
        setGender(value);
        break;
      case "material":
        if (value.length <= 50) {
          // newItem.material = value;
          setMaterial(value);
        }
        break;
      case "quantity":
        // if (event.target.value === "") {
        // newItem.qty = undefined;/
        // } else {
        if (value && Number.isInteger(parseInt(value))) {
          // newItem.qty = parseInt(value);
          setQty(parseInt(value));
        } else {
          // newItem.qty = 0;
          setQty("");
        }
        // }
        break;
      case "unitPrice":
        if (value) {
          if (parseFloat(parseFloat(value).toFixed(2)) === parseFloat(value)) {
            // newItem.unit_price = parseFloat(value);
            setUnitPrice(parseFloat(value));
          }
        } else {
          // newItem.unit_price = "";
          setUnitPrice("");
        }
        break;
      // case `itemType${index}`:
      //     newItem.item_type = value;
      //     break;
    }
    // newItems[index] = newItem;
    // props.setItems([...newItems]);
  };

  const medicines = [
    {
      name: "Vitamina C",
      description: "1000 mg, 500 tablets",
      brand: "KIRKLAND",
      qty: 1,
      unit_price: 60,
      img: vitaminC,
    },
    {
      name: "Super B-Complex",
      description: "500 tablets",
      brand: "KIRKLAND",
      qty: 1,
      unit_price: 65,
      img: vitaminB,
    },
    {
      name: "Vitamina D3",
      description: "125 mcg, 400 caplets",
      brand: "Nature's Bounty",
      qty: 1,
      unit_price: 35,
      img: vitaminD,
    },
    {
      name: "Zinc",
      description: "50 mg, 400 caplets",
      brand: "Nature's Bounty",
      qty: 1,
      unit_price: 40,
      img: vitaminZinc,
    },
  ];

  useEffect(() => {
    //Tercer intento
    if (idSpecialBox == props.boxTypeSelected) {
      props.setItems(medicines);
      props.setAdditionalNote("");
      props.setItemCategorySelected(idOtherItem);
      props.setBoxTypeSelectedPrevious(props.boxTypeSelected);
    } else if (props.boxTypeSelectedPrevious == idSpecialBox) {
      props.setItems([]);
      props.setAdditionalNote("");
      props.setItemCategorySelected(0);
      props.setBoxTypeSelectedPrevious(props.boxTypeSelected);
    }
  }, [idSpecialBox]);

  return (
    <div className="custom-block custom-block__form custom-block__form--style">
      {/* <div className="wizard-form-box">
                <div className="wizard-form-box-header">
                    <label className="l-tag-form-box-title">Imágenes adjuntas {" "}
                        <FontAwesomeIcon icon={['fas', 'images']}
                            data-toggle="popover" data-placement="auto" data-trigger="hover"
                            data-title={"Nota"}
                            data-content={"Puedes adjuntar hasta 3 imágenes. Recuerda sacar fotos claras del contenido."}
                        />
                    </label>
                </div>

                <div className="wizard-form-box-container-item">
                <div className="wizard-form-box-input-container">
                    <div className="images-container">
                        {props.attachments.length < 1 ?
                            <div className="no-image text-center">No hay imágenes...</div> :
                            props.attachments.map((attachment, index) => {
                                return (
                                    <div key={index} className="image-upload" style={{ backgroundImage: "url(" + attachment.src + ")" }}>
                                        <div className="overlay">
                                            <FontAwesomeIcon icon={['fas', 'trash-alt']} size="2x" className="image-delete"
                                                onClick={() => onImageDelete(index)}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    </div>

                    <div className="wizard-form-box-button-container">
                        <label className="upload-button text-center align-self-center" htmlFor="attachments">Subir imagen {" "}
                            <FontAwesomeIcon icon={['fas', 'upload']} />
                        </label>
                        <FileInput
                            id="attachments"
                            onChange={onImageChange}
                        // className="custom-file-input"
                        // required={true}
                        // value={props.attachments[0] ? props.attachments[0].file : ""}
                        />
                        <div className="invalid-feedback">Adjunte al menos una imagen.</div>
                    </div>
                </div>
            </div> */}

      {idSpecialBox == props.boxTypeSelected ? (
        <div className="wizard-form-box">
          {/* Productos Medicinales */}
          <label className="l-tag-form-box-title">
            Contenido del envío{" "}
            <FontAwesomeIcon
              icon={["fas", "box-open"]}
              data-toggle="popover"
              data-placement="auto"
              data-trigger="hover"
              data-title={"Nota"}
              data-content={
                "El contenido de la caja salud es rellenada por nosotros."
              }
            />
          </label>
          <div className="step-description">
            <strong>
              (El contenido de la caja salud será rellenado y enviado por
              nosotros.)
            </strong>
            <p>
              La caja salud contiene un frasco de cada una de las siguientes
              vitaminas:
            </p>
          </div>

          <div className="wizard-form-box-input-container3">
            {props.items ? (
              props.items.map((i) => {
                return (
                  <div className="wizard-form-box-item-row" key={i.name}>
                    <div className="product-content">
                      <div className="wizard-form-box-input-container2">
                        <label className="l-tag-form-content-title">
                          Nombre:
                        </label>
                        <label>{i.name}</label>
                      </div>
                      <div className="wizard-form-box-input-container2">
                        <label className="l-tag-form-content-title">
                          Descripción:
                        </label>
                        <label>{i.description}</label>
                      </div>
                      <div className="wizard-form-box-input-container2">
                        <label className="l-tag-form-content-title">
                          Marca:
                        </label>
                        <label>{i.brand}</label>
                      </div>

                      {/* <div className="wizard-form-box-input-container2">
                        <label className="l-tag-form-content-title">
                          Cantidad:
                        </label>
                        <label>{i.qty}</label>
                      </div> */}
                      {/* <div className="wizard-form-box-input-container2">
                        <label className="l-tag-form-content-title">
                          Precio de unidad ($):
                        </label>
                        <label>{i.unit_price}</label>
                      </div> */}
                      <div className="wizard-form-box-input-container2">
                        <img src={i.img} alt="" width="150" />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>Sin items</div>
            )}
          </div>
        </div>
      ) : (
        <div>
          {/* <div className="wizard-form-box">
                <div className="wizard-form-box-header">
                    <label className="l-tag-form-box-title">Peso de la caja {" "}
                        <FontAwesomeIcon icon={['fas', 'weight-hanging']}
                            data-toggle="popover" data-placement="auto" data-trigger="hover"
                            data-title={"Nota"}
                            data-content={"Especifica su peso en lb. de la caja que trasladaremos."}
                        />
                    </label>
                </div>
                <div className="wizard-form-box-input-row">
                    <div className="wizard-form-box-input-container">
                        <label className="l-tag-form-content-title">Peso de la caja ( {props.weight} {props.weightMeasure === 0 ? "lb." : "kg."}) <span className={'text-danger'}>*</span></label>

                        <NumberInput
                            id="weight"
                            className="number-input-data form-control form-control-lg"
                            value={props.weight}
                            // ref={input}
                            // defaultValue={""}
                            step={1}
                            required={true}
                            min={0}
                            onChange={onChange}
                        />
                        <RadioButton
                            options={[
                                { id: 0, name: "Peso expresado en lb." },
                                { id: 1, name: "Peso expresado en kg." }
                            ]}
                            name="weightMeasures"
                            value={props.weightMeasure}
                            className="radio-button"
                            onChange={onMeasureChange}
                            required={true}
                        />
                        <div className="invalid-feedback">Ingresa el peso aproximado de la caja.</div>
                    </div>
                </div>
            </div> */}
          <div className="wizard-form-box">
            <div className="wizard-form-box-header">
              <label className="l-tag-form-box-title">
                Contenido del envío{" "}
                <FontAwesomeIcon
                  icon={["fas", "box-open"]}
                  data-toggle="popover"
                  data-placement="auto"
                  data-trigger="hover"
                  data-title={"Nota"}
                  data-content={
                    "Especifica el contenido del paquete que trasladaremos."
                  }
                />{" "}
              </label>
            </div>
            <div className="step-description">
              <strong>
                (El contenido de su caja será revisado para darle una mayor
                seguridad en su envío. Para estar informado de lo que puede o no
                puede enviar, vea{" "}
                <a
                  href="https://www.easybolbox.com/normas-restricciones.html"
                  target="_blank"
                >
                  aquí
                </a>
                )
              </strong>
            </div>
            <div className="wizard-form-box-input-row">
              <div className="wizard-form-box-input-container">
                <label className="l-tag-form-content-title">
                  Tipo de contenido
                </label>
                <SelectInput
                  className="form-control form-control-lg"
                  id="item_category"
                  value={props.itemCategorySelected}
                  options={props.itemCategories}
                  // blankOption={false}
                  chooseLabel={"Seleccione el tipo de contenido"}
                  required={true}
                  onChange={onChange}
                />

                {props.itemCategorySelected == idClothing ? (
                  <div className="step-description text-danger">
                    El valor máximo de todas las prendas de vestir que será
                    enviada no deberá de pasar los $ 200 USD, siguiendo las
                    regulaciones de Aduana Boliviana.
                  </div>
                ) : props.itemCategorySelected == idDevicesWithBattery ? (
                  <div>
                    <div className="step-description text-danger">
                      Por normativas de Aduana Boliviana se debe pagar un
                      certificado de importación para dispositivos con batería,
                      se le cobrará un monto de $ 60 adicional.
                    </div>
                    <div className="step-description text-danger">
                      No se permiten baterías adicionales (fuera del equipo
                      electrónico), la batería suelta es considerada como un
                      material peligroso y tiene un proceso diferente de
                      exportación el cual tiene un costo adicional.
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="wizard-form-box-container-item">
              {!canAdd && isCategoryTypeSelected ? (
                <div className="wizard-form-box-button-container">
                  <FontAwesomeIcon
                    className="wizard-add-button"
                    icon={["fas", "plus-circle"]}
                    size="2x"
                    onClick={() => setCanAdd(true)}
                  />{" "}
                  Agregar producto
                </div>
              ) : null}

              {canAdd ? (
                <div className="product-content">
                  <div className="wizard-form-box-input-container">
                    <label className="l-tag-form-content-title">
                      Nombre del artículo{" "}
                      <span className={"text-danger"}>*</span>
                    </label>
                    <TextInput
                      id="name"
                      className="input-data form-control form-control-lg"
                      value={name}
                      required={true}
                      onChange={onItemContentChange}
                      placeholder={
                        props.itemCategorySelected == idClothing
                          ? "Ej: Polera American Eagle"
                          : props.itemCategorySelected == idDevicesWithBattery
                          ? "Ej: Celular iphone 12, Laptop hp, Dron"
                          : "Ej: Licuadora Oster, Plancha Bosch"
                      }
                      maxLength={100}
                      minLength={5}
                    />
                    <div className="invalid-feedback">
                      Agrega el nombre, la marca y el modelo del producto.
                    </div>
                  </div>
                  <div className="wizard-form-box-input-container">
                    <label className="l-tag-form-content-title">
                      {props.itemCategorySelected == idClothing
                        ? "Descripción del artículo "
                        : "Número de serie "}
                      <span className={"text-danger"}>*</span>
                    </label>
                    <TextInput
                      id="description"
                      className="input-data form-control form-control-lg"
                      value={description}
                      required={true}
                      onChange={onItemContentChange}
                      placeholder={
                        props.itemCategorySelected == idClothing
                          ? "Ej: Manga larga, cuello redondo"
                          : "Ej: Número de serie, imei, código del producto"
                      }
                      maxLength={250}
                      minLength={8}
                    />
                    <div className="invalid-feedback">
                      Agrega una descripción del producto.
                    </div>
                  </div>
                  <div className="wizard-form-box-input-container">
                    <label className="l-tag-form-content-title">
                      {props.itemCategorySelected == idClothing
                        ? "Fabricado en "
                        : "Origen del producto "}
                      <span className={"text-danger"}>*</span>
                    </label>
                    <TextInput
                      id="brand"
                      className="input-data form-control form-control-lg"
                      value={brand}
                      required={true}
                      onChange={onItemContentChange}
                      placeholder={
                        props.itemCategorySelected == idClothing
                          ? "Ej: made in USA"
                          : "Ej: made in China"
                      }
                      maxLength={50}
                      minLength={0}
                    />
                    <div className="invalid-feedback">
                      {props.itemCategorySelected == idClothing
                        ? "Agrega el lugar de fabricación del producto"
                        : "Agrega la marca del producto."}
                    </div>
                  </div>
                  {props.itemCategorySelected == idClothing ? (
                    <div className="wizard-form-box-input-container">
                      <label className="l-tag-form-content-title">
                        Género <span className={"text-danger"}>*</span>
                      </label>
                      <SelectInput
                        className="form-control form-control-lg"
                        id="gender"
                        value={gender}
                        required={true}
                        options={[
                          { id: "Niña", name: "Niña" },
                          { id: "Niño", name: "Niño" },
                          { id: "Mujer", name: "Mujer" },
                          { id: "Varón", name: "Varón" },
                        ]}
                        onChange={onItemContentChange}
                        chooseLabel={"Seleccionar"}
                      />
                      <div className="invalid-feedback">
                        Selecciona el género.
                      </div>
                    </div>
                  ) : null}
                  {props.itemCategorySelected == idClothing ? (
                    <div className="wizard-form-box-input-container">
                      <label className="l-tag-form-content-title">
                        Material <span className={"text-danger"}>* </span>
                        <FontAwesomeIcon
                          icon={["fas", "tshirt"]}
                          data-toggle="popover"
                          data-placement="auto"
                          data-trigger="hover"
                          data-title={"Ejemplo de etiqueta de ropa"}
                          data-content={`<div className="container" >
                        <img src=${Etiq} width="250"/>
                      </div>`}
                        />
                      </label>
                      <TextInput
                        id="material"
                        className="input-data form-control form-control-lg"
                        placeholder="Ej: 68% algodón, 17% casimir, 12% micro acrílico, 3% licra"
                        value={material}
                        required={true}
                        onChange={onItemContentChange}
                        maxLength={50}
                        minLength={0}
                        onFocus={() => {
                          alerts.notifyWarningCenter(
                            `<strong>Ejemplo de etiqueta de ropa</strong>
                              <img src=${Etiq} width="230"/>`
                          );
                        }}
                      />
                      <div className="invalid-feedback">
                        Agrega el material del producto.
                      </div>
                    </div>
                  ) : null}
                  <div className="wizard-form-box-input-container">
                    <label className="l-tag-form-content-title">
                      Cantidad (por unidad){" "}
                      <span className={"text-danger"}>*</span>
                    </label>
                    <NumberInput
                      id="quantity"
                      className="number-input-data form-control form-control-lg"
                      value={qty}
                      // ref={"input"}
                      // defaultValue={""}
                      // step={1}
                      required={true}
                      min={1}
                      onChange={onItemContentChange}
                      onFocus={() => {
                        if (qty === 0) setQty("");
                      }}
                    />
                    <div className="invalid-feedback">
                      Ingresa la cantidad de producto.
                    </div>
                  </div>
                  <div className="wizard-form-box-input-container">
                    <label className="l-tag-form-content-title">
                      Precio de unidad ($){" "}
                      <span className={"text-danger"}>*</span>
                    </label>
                    <NumberInput
                      id="unitPrice"
                      className="number-input-data form-control form-control-lg"
                      value={unitPrice}
                      // step={0.01}
                      required={true}
                      min={0.5}
                      onChange={onItemContentChange}
                      onFocus={() => {
                        if (unitPrice === 0) setUnitPrice("");
                      }}
                    />
                    <div className="invalid-feedback">
                      Ingresa el precio por producto.
                    </div>
                  </div>

                  <div className="text-right">
                    <Button
                      className="wizard-next-button"
                      onClick={() => setCanAdd(false)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      className="wizard-next-button"
                      onClick={() => onItemAdd()}
                    >
                      Agregar
                    </Button>
                  </div>
                </div>
              ) : null}

              <div className="wizard-form-box-item-row">
                {props.items
                  ? props.items.map((item, index) => {
                      return (
                        <div key={index} className="product-content-2">
                          <div className="wizard-form-box-input-container">
                            <FontAwesomeIcon
                              className="wizard-delete-item align-self-end"
                              icon={["fas", "trash-alt"]}
                              size="lg"
                              onClick={() => onItemRemove(index)}
                            />
                          </div>
                          <div className="wizard-form-box-input-container">
                            <label className="l-tag-form-content-title">
                              Nombre, Marca y Modelo{" "}
                              <span className={"text-danger"}>*</span>
                            </label>
                            <label>{item.name}</label>
                          </div>
                          <div className="wizard-form-box-input-container">
                            <label className="l-tag-form-content-title">
                              Descripción{" "}
                              <span className={"text-danger"}>*</span>
                            </label>
                            <label>{item.description}</label>
                          </div>
                          <div className="wizard-form-box-input-container">
                            <label className="l-tag-form-content-title">
                              Fabricado en{" "}
                            </label>
                            <label>{item.brand}</label>
                          </div>
                          {props.itemCategorySelected == idClothing ? (
                            <div className="wizard-form-box-input-container">
                              <label className="l-tag-form-content-title">
                                Género{" "}
                              </label>
                              <label>{item.gender}</label>
                            </div>
                          ) : null}
                          {props.itemCategorySelected == idClothing ? (
                            <div className="wizard-form-box-input-container">
                              <label className="l-tag-form-content-title">
                                Material{" "}
                              </label>
                              <label>{item.material}</label>
                            </div>
                          ) : null}
                          {/* <div className="wizard-form-box-input-container">
                                        <label className="l-tag-form-content-title">Año de fabricación </label>
                                        <NumberInput
                                            id={`yearOfManufacture${index}`}
                                            className="number-input-data form-control form-control-lg"
                                            value={item.year_of_manufacture}
                                            // ref={input}
                                            // defaultValue={""}
                                            step={1}
                                            required={false}
                                            min={0}
                                            onChange={e => onItemContentChange(index, e)}
                                        />
                                        <div className="invalid-feedback">Ingresa la cantidad de producto.</div>
                                    </div> */}
                          <div className="wizard-form-box-input-container">
                            <label className="l-tag-form-content-title">
                              Cantidad (por unidad){" "}
                              <span className={"text-danger"}>*</span>
                            </label>
                            <label>{item.qty}</label>
                          </div>
                          <div className="wizard-form-box-input-container">
                            <label className="l-tag-form-content-title">
                              Precio de unidad ($){" "}
                              <span className={"text-danger"}>*</span>
                            </label>
                            <label>{item.unit_price}</label>
                          </div>
                          {/* <div className="wizard-form-box-input-container">
                                        <label className="l-tag-form-content-title">Tipo de producto <span className={'text-danger'}>*</span></label>
                                        <SelectInput
                                            id={`itemType${index}`}
                                            className="form-control form-control-lg custom-select select-options"
                                            value={item.item_type}
                                            options={props.itemTypes}
                                            required={true}
                                            onChange={e => onItemContentChange(index, e)}
                                        />
                                        <div className="invalid-feedback">Selecciona el tipo de producto.</div>
                                    </div> */}
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>

          {/* nueva consideraciones */}
          <div className="wizard-form-box">
            <div className="wizard-form-box-header">
              <label className="l-tag-form-box-title">
                Consideraciones{" "}
                <FontAwesomeIcon
                  icon={["fas", "question-circle"]}
                  data-toggle="popover"
                  data-placement="auto"
                  data-trigger="hover"
                  data-title={"Nota"}
                  data-content={
                    "Agrega alguna nota, acerca del contenido, una observación o ya sea si quieres especificar que la caja contiene materiales frágiles."
                  }
                />
              </label>
            </div>
            <div className="wizard-form-box-input-row">
              <div className="wizard-form-box-input-container">
                <label className="l-tag-form-content-title">
                  Nota adicional
                </label>
                <TextArea
                  className="textarea-input-data form-control form-control-lg"
                  id="additional_note"
                  value={props.additionalNote}
                  placeholder={"Materiales frágiles! manipular con cuidado."}
                  onChange={onChange}
                  maxLength={250}
                  minLength={0}
                  rows={5}
                  required={false}
                />
                <div className="invalid-feedback">
                  Escriba una nota adicional segun consideraciones del
                  contenido.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepFour;
