import * as actions from "../actions/profile.actions";
import * as paths from "../config/paths";

export const ifUnauthorized = async (history, nextFunction, urlParams = null) => {
    actions.getProfileInfo()
        .then(response => {
            console.log("El usuario si está autenticado. ");
            nextFunction();
        })
        .catch(error => {
            console.log("Ocurrió un error al verificar la autenticidad del usuario.");
            console.log(error);
            if (error.response && error.response.data.msg === "unauthorized") {
                localStorage.removeItem("user_token");
                localStorage.removeItem("user_email");
                if (urlParams) {
                    history.push({
                        pathname: paths.AUTH.LOGIN,
                        search: urlParams
                    });
                } else {
                    history.push(paths.AUTH.LOGIN);
                }
            }
        });
} 