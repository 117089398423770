import React from "react";
import { useHistory } from "react-router-dom"

import "./styles.scss";

import NavbarLogo from "../../assets/img/ebb.png";
import * as paths from "../../config/paths";
import HomeImage from "../../assets/img/home.svg";

const Header = () => {
    const history = useHistory();

    const logout = () => {
        localStorage.removeItem("user_token");
        localStorage.removeItem("user_email");
        history.push(paths.AUTH.LOGIN);
    }

    return (
        localStorage.getItem("user_token") ?
            <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/">
                    <img height={100} src={NavbarLogo} className="App-logo" alt="logo" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav mr-auto">
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link active" href={paths.HOME}>Inicio</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={paths.TRACKING}>Seguimiento</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={paths.ORDERS.LIST}>Mis Órdenes</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={paths.ADDRESSES.LIST}>Mis Direcciones</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={paths.PROFILE}>Perfil</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={paths.CONTACT}>Contacto</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#" onClick={() => logout()}>Cerrar sesión</a>
                        </li>
                    </ul>
                </div>
            </nav> :
            null
    );
}

export default Header;
