import React, { useEffect, ReactFragment, useState } from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const StepSix = (props) => {
  const [orderTypePrice, setOrderTypePrice] = useState(0);
  const [receptionTypePrice, setReceptionTypePrice] = useState(0);
  const [itemCategoryTariff, setItemCategoryTariff] = useState(0);
  const [extraTariff, setExtraTariff] = useState(0);
  const [itemValue, setItemValue] = useState(0);
  const [idSpecialBox, setIdSpecialBox] = useState(0);
  const [idClothing, setIdClothing] = useState(0);
  const [idDevicesWithBattery, setIdDevicesWithBattery] = useState(0);
  const DEVICES_WHIT_BATTERY = 60;

  const optionSelectedPaP = props.servicePaPST || props.servicePaPSS
  const [optionPaP] = props.optionsPaP.filter(option => option.id == optionSelectedPaP)
  const branchSelected = props.destinationBranchCopacabanaSelectedSThree || props.destinationBranchCopacabanaSelectedSSpecial
  const [branch] = props.destinationBranchCopacabana.filter(branchCopa => branchCopa.id == branchSelected)

  const destinationBranchPaPPrice = optionPaP
    ? optionPaP.code == "DTD"
      ? branch.attributes.door_to_door_fee
      : 0
    : 0;

  useEffect(() => {
    let stateName = props.destinationStateSelected !== 0 ?
      props.destinationStates.find(
        (e) => e.id == props.destinationStateSelected
      ).name :
      props.destinationAddresses.find(
        (e) => parseInt(e.id) === props.selectedDestinationAddress
      ).stateName

    setOrderTypePrice(
      props.boxTypes.find((e) => e.id == props.boxTypeSelected).attributes.price
    );
    setReceptionTypePrice(
      props.receptionTypes.find((e) => e.id == props.receptionTypeSelected)
        .attributes.price
    );
    setItemValue(
      props.items.reduce(
        (total, currentValue) =>
          (total = total + currentValue.unit_price * currentValue.qty),
        0
      )
    );

    let extra = props.boxTypes.find((e) => e.id == props.boxTypeSelected).attributes.extra
      .find((e) => e.state === stateName);

    setItemCategoryTariff(
      props.itemCategories.find((e) => e.id == props.itemCategorySelected)
        .tariff
    );

    setExtraTariff(
      extra ? extra.tariff : 0
    );

    props.boxTypes.map((box) => {
      if (box.attributes.name == "Caja Salud") {
        setIdSpecialBox(box.attributes.code);
      }
    });
    props.itemCategories.map((i) => {
      if (i.name == "Ropa") setIdClothing(i.id);
      else if (i.name == "Dispositivos electrónicos con batería incorporada")
        setIdDevicesWithBattery(i.id);
    });

    onItemsClear();
  }, []);

  const onItemsClear = () => {
    let newItems = props.items.filter(
      (item) =>
        item.name !== "" &&
        item.description !== "" &&
        item.qty !== 0 &&
        item.unit_price !== 0
    );
    props.setItems([...newItems]);
  };

  const locationUrl = (lat, lng) => {
    window.open(`https://maps.google.com?q=${lat},${lng}`);
  };

  const newOriginAddress = () => {
    return (
      <div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Nombre:</label>
          <label className="l-tag-summary-item-info">{props.originName}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Teléfono:</label>
          <label className="l-tag-summary-item-info">
            {props.originPhone}.
          </label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Email:</label>
          <label className="l-tag-summary-item-info">
            {props.originEmail}.
          </label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">
            Línea de dirección #1:
          </label>
          <label className="l-tag-summary-item-info">
            {props.originAddress1}.
          </label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">
            Línea de dirección #2:
          </label>
          <label className="l-tag-summary-item-info">
            {props.originAddress2}.
          </label>
        </div>

        {/* Ciudad */}
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Ciudad:</label>
          <label className="l-tag-summary-item-info">
            {props.originLocality}
          </label>
        </div>

        {/* Estado */}
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Estado:</label>
          <label className="l-tag-summary-item-info">
            {props.originStateSelected !== 0
              ? props.originStates.find(
                (e) => e.id == props.originStateSelected
              ).name
              : null}
            .
          </label>
        </div>

        {/* Codigo Postal */}
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Código Postal:</label>
          <label className="l-tag-summary-item-info">
            {props.originPostcode}.
          </label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">País:</label>
          <label className="l-tag-summary-item-info">
            {props.originCountrySelected !== 0
              ? props.originCountries.find(
                (e) => e.id == props.originCountrySelected
              ).name
              : null}
            .
          </label>
        </div>
      </div>
    );
  };

  const selectedOriginAddress = () => {
    let address = props.originAddresses.find(
      (e) => parseInt(e.id) === props.selectedOriginAddress
    );
    return (
      <div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Nombre:</label>
          <label className="l-tag-summary-item-info">{address.name}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Teléfono:</label>
          <label className="l-tag-summary-item-info">{address.phone}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Email:</label>
          <label className="l-tag-summary-item-info">{address.email}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">
            Línea de dirección #1:
          </label>
          <label className="l-tag-summary-item-info">{address.address1}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">
            Línea de dirección #2:
          </label>
          <label className="l-tag-summary-item-info">{address.address2}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Ciudad:</label>
          <label className="l-tag-summary-item-info">{address.locality}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Estado:</label>
          <label className="l-tag-summary-item-info">
            {address.stateName}.
          </label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Código postal:</label>
          <label className="l-tag-summary-item-info">{address.postcode}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">País:</label>
          <label className="l-tag-summary-item-info">
            {address.countryName}.
          </label>
        </div>
        {/* <div className="wizard-summary-address-item-container">
                    <label className="l-tag-summary-item-info-bold">Ubicación:</label>
                    <label className="l-tag-summary-item-info">
                        <a className="google-maps-location" onClick={() => locationUrl(address.lat, address.lng)}>Ver en google maps.</a>
                    </label>
                </div> */}
      </div>
    );
  };

  const newDestinationAddress = () => {
    return (
      <div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Nombre:</label>
          <label className="l-tag-summary-item-info">
            {props.destinationName}.
          </label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Teléfono:</label>
          <label className="l-tag-summary-item-info">
            {props.destinationPhone}.
          </label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Email:</label>
          <label className="l-tag-summary-item-info">
            {props.destinationEmail}.
          </label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">
            Línea de dirección #1:
          </label>
          <label className="l-tag-summary-item-info">
            {props.destinationAddress1}.
          </label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">
            Línea de dirección #2:
          </label>
          <label className="l-tag-summary-item-info">
            {props.destinationAddress2}.
          </label>
        </div>

        {/* ciudad */}
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Ciudad:</label>
          <label className="l-tag-summary-item-info">
            {props.destinationLocality}
          </label>
        </div>
        {/* dpto */}
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Departamento:</label>
          <label className="l-tag-summary-item-info">
            {props.destinationStateSelected !== 0
              ? props.destinationStates.find(
                (e) => e.id == props.destinationStateSelected
              ).name
              : null}
            .
          </label>
        </div>

        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">País:</label>
          <label className="l-tag-summary-item-info">
            {props.destinationCountrySelected !== 0
              ? props.destinationCountries.find(
                (e) => e.id == props.destinationCountrySelected
              ).name
              : null}
            .
          </label>
        </div>
      </div>
    );
  };

  const selectedDestinationAddress = () => {
    let address = props.destinationAddresses.find(
      (e) => parseInt(e.id) === props.selectedDestinationAddress
    );
    return (
      <div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Nombre:</label>
          <label className="l-tag-summary-item-info">{address.name}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Teléfono:</label>
          <label className="l-tag-summary-item-info">{address.phone}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Email:</label>
          <label className="l-tag-summary-item-info">{address.email}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">
            Línea de dirección #1:
          </label>
          <label className="l-tag-summary-item-info">{address.address1}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">
            Línea de dirección #2:
          </label>
          <label className="l-tag-summary-item-info">{address.address2}.</label>
        </div>

        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Ciudad:</label>
          <label className="l-tag-summary-item-info">{address.locality}.</label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">Departamento:</label>
          <label className="l-tag-summary-item-info">
            {address.stateName}.
          </label>
        </div>
        <div className="wizard-summary-address-item-container">
          <label className="l-tag-summary-item-info-bold">País:</label>
          <label className="l-tag-summary-item-info">
            {address.countryName}.
          </label>
        </div>
      </div>
    );
  };

  const receptionTypeSummaryLine = (reception) =>
    reception.attributes.name + " - " + reception.attributes.description;

  return (
    <div className="custom-block custom-block__form custom-block__form--style">
      <div className="wizard-summary-container wizard-summary-border-gray">
        <label className="l-tag-summary-title">Resumen de envio</label>
        <div className="h5 text-danger">
          Por favor revise la sección de productos, datos de ubicación y costos de servicios antes de realizar su pago.
        </div>
        <div>
          <label className="l-tag-summary-info-bold">
            Tipo de caja:{" "}
            <label className="l-tag-summary-additionalnote-normal ">
              {props.boxTypeSelected !== 0
                ? props.boxTypes.find((e) => e.id == props.boxTypeSelected)
                  .attributes.name
                : "No se ha seleccionado ningún tipo de envío."}
            </label>
          </label>
        </div>
        <div>
          <label className="l-tag-summary-info-bold">
            Categoría:{" "}
            <label className="l-tag-summary-additionalnote-normal">
              {props.itemCategorySelected !== 0
                ? props.itemCategories.find(
                  (e) => e.id == props.itemCategorySelected
                ).name
                : "No se ha seleccionado ningún tipo de contenido."}
            </label>
          </label>
        </div>
        {/* <div>
                    <label className="l-tag-summary-info">{props.receptionTypeSelected !== 0 ?
                        receptionTypeSummaryLine(props.receptionTypes.find(reception => reception.id == props.receptionTypeSelected)) :
                        "No se ha seleccionado ningún modo de entrega de su caja."}</label>
                </div> */}
        {/* <div className="">
                    <label className="l-tag-summary-info">
                        <span>Cantidad de documentos adjuntos: {"("}{props.attachments.length}{")"}</span>
                    </label>
                </div> */}
        {props.boxTypeSelected == idSpecialBox ? (
          ""
        ) : (
            <div>
              <label className="l-tag-summary-info-bold">
                Nota:{" "}
                <label className="l-tag-summary-additionalnote-normal">
                  {props.additionalNote}
                </label>
              </label>
            </div>
          )}

        <div className="wizard-summary-subcontainer wizard-summary-border-gray">
          <label className="l-tag-summary-info-bold">Productos:</label>
          {props.items.length > 0 ? (
            <div className="products">
              {props.items.map((item, index) => {
                return (
                  <div key={index} className="product-content relief">
                    <label className="l-tag-summary-info">
                      Item # {index + 1}
                    </label>
                    <div className="wizard-summary-address-item-container">
                      <label className="l-tag-summary-item-info-bold">
                        Nombre:
                      </label>
                      <label className="l-tag-summary-item-info">
                        {item.name}
                      </label>
                    </div>
                    <div className="wizard-summary-address-item-container">
                      <label className="l-tag-summary-item-info-bold">
                        Descripción:
                      </label>
                      <label className="l-tag-summary-item-info">
                        {item.description}
                      </label>
                    </div>
                    <div className="wizard-summary-address-item-container">
                      {props.itemCategorySelected == idClothing ? (
                        <label className="l-tag-summary-item-info-bold">
                          Fabricado en:
                        </label>
                      ) : (
                          <label className="l-tag-summary-item-info-bold">
                            Marca:
                          </label>
                        )}
                      <label className="l-tag-summary-item-info">
                        {item.brand}
                      </label>
                    </div>
                    {props.itemCategorySelected == idClothing ? (
                      <div className="wizard-summary-address-item-container">
                        <label className="l-tag-summary-item-info-bold">
                          Género:
                        </label>
                        <label className="l-tag-summary-item-info">
                          {item.gender}
                        </label>
                      </div>
                    ) : (
                        ""
                      )}
                    {/* <div className="wizard-summary-address-item-container">
                                            <label className="l-tag-summary-item-info-bold">Tamaño:</label>
                                            <label className="l-tag-summary-item-info">{item.size}.</label>
                                        </div> */}
                    {/* <div className="wizard-summary-address-item-container">
                                            <label className="l-tag-summary-item-info-bold">Material:</label>
                                            <label className="l-tag-summary-item-info">{item.material}.</label>
                                        </div> */}
                    {/* <div className="wizard-summary-address-item-container">
                                            <label className="l-tag-summary-item-info-bold">Año de producción:</label>
                                            <label className="l-tag-summary-item-info">{item.year_of_manufacture}.</label>
                                        </div> */}

                    {props.boxTypeSelected == idSpecialBox ? (
                      ""
                    ) : (
                        <div>
                          <div className="wizard-summary-address-item-container">
                            <label className="l-tag-summary-item-info-bold">
                              Cantidad:
                          </label>
                            <label className="l-tag-summary-item-info">
                              {item.qty}
                            </label>
                          </div>
                          <div className="wizard-summary-address-item-container">
                            <label className="l-tag-summary-item-info-bold">
                              Precio:
                          </label>
                            <label className="l-tag-summary-item-info">
                              $ {item.unit_price}
                            </label>
                          </div>
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          ) : (
              <div>
                <label className="l-tag-summary-info">
                  No ha ingresado ningún producto.
              </label>
              </div>
            )}
        </div>
        <div className="wizard-summary-subcontainer wizard-summary-border-gray">
          <label className="l-tag-summary-info-bold">Datos de ubicación:</label>
          <div className="wizard-summary-address-container">
            <div className="wizard-summary-address-content wizard-summary-sub-subcontainer wizard-summary-border-gray relief">
              <label className="l-tag-summary-info-bold">
                Datos de ubicación de origen
              </label>
              {props.originNewAddress
                ? newOriginAddress()
                : selectedOriginAddress()}
            </div>

            <div className="wizard-summary-address-content wizard-summary-sub-subcontainer wizard-summary-border-gray relief">
              <label className="l-tag-summary-info-bold">
                Datos de ubicación de destino
              </label>
              {props.destinationNewAddress
                ? newDestinationAddress()
                : selectedDestinationAddress()}
            </div>
          </div>
        </div>
        <div className="wizard-summary-cost-container ">
          <div className="wizard-summary-cost-content wizard-summary-sub-subcontainer wizard-summary-border-orange relief">
            <label className="l-tag-summary-info-bold">
              Costos por servicio
            </label>
            <label className="l-tag-summary-item-info">
              El costo incluye los siguientes servicios:
              <ul>
                <li>
                  Recepción de sus cajas en nuestros almacenes en EEUU Miami
                </li>
                <li>
                  La verificación del contenido y del buen estado de su caja
                </li>
                <li>El envío a Bolivia</li>
                <li>Impuestos y Trámites aduaneros</li>
                <li>Seguro</li>
                <li>
                  La entrega de su paquete al destino especificado en Bolivia
                </li>
                {
                  destinationBranchPaPPrice > 0 && <li>Servicio de entrega hasta la puerta del destinatario</li>
                }
              </ul>
            </label>
            {/* <div className="wizard-summary-cost-item-container wizard-summary-cost-item-container-margin">
              <label className="l-tag-summary-item-info-bold">
                Costo por concepto de caja
              </label>
              <label className="l-tag-summary-item-info">
                $ {Number(orderTypePrice).toFixed(2)}
              </label>
            </div>
            <div className="wizard-summary-cost-item-container wizard-summary-cost-item-container-margin">
              <label className="l-tag-summary-item-info-bold">
                Costo por aranceles aduaneros
              </label>
              <label className="l-tag-summary-item-info">
                $ {Number(itemValue * itemCategoryTariff).toFixed(2)}
              </label>
            </div> */}
            <hr />
            <div className="wizard-summary-cost-item-container wizard-summary-cost-item-container-margin">
              <label className="l-tag-summary-item-info-bold">
                Costo Total
              </label>
              {props.itemCategorySelected == idDevicesWithBattery ? (
                <label className="l-tag-summary-item-info-bold">
                  ${" "}
                  {Number(
                    orderTypePrice +
                    itemValue * itemCategoryTariff +
                    extraTariff + destinationBranchPaPPrice +
                    DEVICES_WHIT_BATTERY
                  ).toFixed(2)}{" "}
                </label>
              ) : (
                  <label className="l-tag-summary-item-info-bold">
                    ${" "}
                    {Number(
                      orderTypePrice + itemValue * itemCategoryTariff + extraTariff + destinationBranchPaPPrice
                    ).toFixed(2)}{" "}
                  </label>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StepSix.propTypes = {
  originLat: PropTypes.any,
  originLng: PropTypes.any,
  boxTypeSelected: PropTypes.any,
  destinationLat: PropTypes.any,
  destinationLng: PropTypes.any,
  boxTypes: PropTypes.any,
  onPaymentConfirm: PropTypes.any,
  additionalNote: PropTypes.any,
  attachments: PropTypes.any,
  items: PropTypes.any,
  itemTypes: PropTypes.any,
  originName: PropTypes.any,
  originPhone: PropTypes.any,
  originEmail: PropTypes.any,
  originAddress1: PropTypes.any,
  originAddress2: PropTypes.any,
  originPostcode: PropTypes.any,
  originCountrySelected: PropTypes.any,
  originCountries: PropTypes.any,
  originStateSelected: PropTypes.any,
  originStates: PropTypes.any,
  destinationName: PropTypes.any,
  destinationPhone: PropTypes.any,
  destinationEmail: PropTypes.any,
  destinationAddress1: PropTypes.any,
  destinationAddress2: PropTypes.any,
  destinationPostcode: PropTypes.any,
  destinationCountrySelected: PropTypes.any,
  destinationCountries: PropTypes.any,
  destinationStateSelected: PropTypes.any,
  destinationStates: PropTypes.any,
  destinationBranchCopacabana: PropTypes.any,
  destinationBranchCopacabanaSelectedSThree: PropTypes.any,
  destinationBranchCopacabanaSelectedSSpecial: PropTypes.any,
  optionsPaP: PropTypes.any,
  servicePaPST: PropTypes.any,
  servicePaPSS: PropTypes.any,
};

StepSix.defaultProps = {
  originLat: "",
  originLng: "",
  boxTypeSelected: 2,
  destinationLat: "",
  destinationLng: "",
  boxTypes: [{ id: 2, attributes: { name: "Caja de prueba" } }],
  onPaymentConfirm: () => { },
  additionalNote:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  attachments: [],
  items: [
    {
      id: 1,
      name: "item 1",
      description: "description",
      qty: 12,
      unit_price: 50,
      item_type: 2,
    },
    {
      id: 2,
      name: "item 2",
      description: "a short description",
      qty: 58,
      unit_price: 10,
      item_type: 2,
    },
  ],
  itemTypes: [{ id: 2, name: "tipo de item 1" }],
  originName: "nombre origen",
  originPhone: "",
  originEmail: "",
  originAddress1: "",
  originAddress2: "",
  originPostcode: "",
  originCountrySelected: 3,
  originCountries: [{ id: 3, name: "USA" }],
  originStateSelected: 3,
  originStates: [{ id: 3, name: "california" }],
  destinationName: "",
  destinationPhone: "",
  destinationEmail: "",
  destinationAddress1: "",
  destinationAddress2: "",
  destinationPostcode: "",
  destinationCountrySelected: 1,
  destinationCountries: [{ id: 1, name: "Bolivia" }],
  destinationStateSelected: 1,
  destinationStates: [{ id: 1, name: "SCZ" }],
};

export default StepSix;
