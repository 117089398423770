import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./styles.scss";

import * as actions from "../../actions/addresses.actions";
import * as paths from "../../config/paths";
import * as globalActions from "../../actions/global_parameters.actions";
import { ifUnauthorized } from "../../utils/authentication";
import Preloader from "../../components/Preloader";
import AddressForm from "./AddressForm";

function NewAddress(props) {
    const [showPreloader, setShowPreloader] = useState(true);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [postcode, setPostcode] = useState("");
    const [locality, setLocality] = useState("");
    const [originCountries, setOriginCountries] = useState([]);
    const [destinationCountries, setDestinationCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [countrySelected, setCountrySelected] = useState(0);
    const [stateSelected, setStateSelected] = useState(0);
    const [addressScopes, setAddressScopes] = useState([]);
    const [addressScopeSelected, setAddressScopeSelected] = useState("origin");
    // const [lat, setLat] = useState(null);
    // const [lng, setLng] = useState(null);
    // const [marker, setMarker] = useState([]);
    const history = useHistory();

    useEffect(() => {
        ifUnauthorized(history, getGlobalParameters);
    }, []);

    const getGlobalParameters = () => {
        globalActions.globalParameters({ filters: ["countries"], filter_mode: "origin" })
            .then(response => {
                if (response.type === "SUCCESSFULLY") {
                    const { payload } = response;
                    setOriginCountries(payload.countries.data.map(country => {
                        return {
                            id: country.id,
                            name: country.attributes.name
                        }
                    }));
                    setAddressScopes([
                        { id: "origin", name: "Origen" },
                        { id: "destination", name: "Destino" }
                    ]);
                    globalActions.globalParameters({ filters: ["countries"], filter_mode: "destination" })
                        .then(response => {
                            if (response.type === "SUCCESSFULLY") {
                                const { payload } = response;
                                setDestinationCountries(payload.countries.data.map(country => {
                                    return {
                                        id: country.id,
                                        name: country.attributes.name
                                    }
                                }));
                                setShowPreloader(false);
                            }
                        })
                        .catch(error => {
                            console.log(error.response);
                        })
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    const onChange = event => {
        const { id, value } = event.target;
        switch (id) {
            case "name":
                if (value.length <= 50) {
                    setName(value);
                }
                break;
            case "phone":
                if (value.length <= 200) {
                    setPhone(value);
                }
                break;
            case "email":
                if (value.length <= 200) {
                    setEmail(value);
                }
                break;
            case "address1":
                if (value.length <= 200) {
                    setAddress1(value);
                }
                break;
            case "address2":
                if (value.length <= 200) {
                    setAddress2(value);
                }
                break;
            case "postcode":
                if (value.length <= 9) {
                    setPostcode(value);
                }
                break;
            case "locality":
                if (value.length <= 50) {
                    setLocality(value);
                }
                break;
            case "address_scope":
                setAddressScopeSelected(value);
                setCountrySelected(0);
                setStateSelected(0);
                setStates([]);
                break;
            case "country":
                setCountrySelected(value);
                if (value == 0) {
                    setStates([]);
                    setStateSelected(0);
                } else {
                    globalActions.globalParameters({ filters: ["states"], country_id: value })
                        .then(response => {
                            if (response.type === "SUCCESSFULLY") {
                                const { payload } = response;
                                setStates(payload.states.data.map(state => {
                                    return {
                                        id: state.id,
                                        name: state.attributes.name
                                    }
                                }));
                            }
                        })
                        .catch(error => {
                            console.log(error.response);
                        });
                }
                break;
            case "state":
                setStateSelected(value);
                break;

        }
    }

    // const onMapPress = ({ lat, lng }) => {
    //     setLat(lat);
    //     setLng(lng);
    //     setMarker([{
    //         position: { lat, lng },
    //         item: { name: 'New Location' }
    //     }]);
    // }

    const validateData = () => {
        if (
          countrySelected !== 0 &&
          stateSelected !== 0 &&
          name != "" &&
          address1 != "" &&
          locality != ""
        ) {
          return true;
        } else {
          return false;
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setShowPreloader(true);
        if (validateData()) {
            let data = {
                name: name,
                phone: phone,
                email: email,
                street: address1,
                street2: address2,
                postal_code: postcode,
                city_locality: locality,
                country_id: countrySelected,
                state_id: stateSelected,
                address_scope: addressScopeSelected,
                // lat: lat,
                // lng: lng
            }
            actions.updateAddress(data)
                .then(response => {
                    if (response.type === "SUCCESSFULLY") {
                        setShowPreloader(true);
                        history.push(paths.ADDRESSES.LIST);
                    }
                })
                .catch(error => {
                    console.log(error.response);
                    setShowPreloader(false);
                });
        } else {
            alert("Hay campos que necesita llenar para guardar la dirección.");
        setShowPreloader(false);
        }
    }

    return (
        <div className="container">
            {showPreloader && <Preloader />}
            <AddressForm
                name={name} phone={phone} email={email} address1={address1}
                address2={address2} postcode={postcode} locality={locality}
                originCountries={originCountries} destinationCountries={destinationCountries} states={states}
                countrySelected={countrySelected} stateSelected={stateSelected}
                addressScopes={addressScopes} addressScopeSelected={addressScopeSelected}
                // lat={lat} lng={lng} marker={marker} onMapPress={onMapPress}
                onChange={onChange} onSubmit={onSubmit} />
        </div>
    );
}

export default NewAddress;
