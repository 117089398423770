import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const TextInput = props => (
    <input
        type={props.type}
        className={props.className}
        id={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        autoComplete={props.autocomplete ? props.autocomplete : "off"}
        maxLength={props.maxLength}
        minLength={props.minLength}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        disabled={props.disabled}
    />
);

TextInput.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    pĺaceholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    maxLength: PropTypes.number,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled:PropTypes.bool
}

TextInput.defaultProps = {
    type: "text",
    className: "",
    id: "",
    placeholder: "",
    value: "",
    onChange: () => { },
    maxLength: 200,
    onFocus: () => { },
    onBlur: () => { },
    disabled: false,
}

export default TextInput;
