import React, { Component } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./styles.scss"

class Timer extends Component { //TODO Make a function
    constructor(props) {
        super(props) //TODO deprecated
        this.time_left = this.props.seconds * 1000;
        this.timer = undefined
        const time = this.calculateTimeLeft()
        this.state = {
            hours: time.hours,
            minutes: time.minutes,
            seconds: time.seconds,
            time_over: false
        }
    }

    calculateTimeLeft = () => {
        let hours, minutes, seconds = undefined;
        if (this.time_left > 0) {
            hours = Math.floor((this.time_left / (1000 * 60 * 60)) % 24)
            minutes = Math.floor((this.time_left / 1000 / 60) % 60)
            seconds = Math.floor((this.time_left / 1000) % 60)
        } else {
            hours = 0;
            minutes = 0;
            seconds = 0;
        }
        return {
            hours: hours < 10 ? "0" + hours : hours,
            minutes: minutes < 10 ? "0" + minutes : minutes,
            seconds: seconds < 10 ? "0" + seconds : seconds
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="remaining-time-text">
                    {
                        this.state.time_over ?
                            <div>
                                {/* {`<>Not implemented yet!</> //TODO`} */}
                            </div>
                            :
                            <React.Fragment>
                                <FontAwesomeIcon icon={["far", "clock"]} />
                                {" "}Se redirigirá al detalle de la orden en:{" "}
                                {/* {`${this.state.hours}:${this.state.minutes}:${this.state.seconds}`} */}
                                {`${this.state.minutes}:${this.state.seconds}`}
                            </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.time_left < 1000) {
                clearInterval(this.timer)
                this.setState({
                    time_over: true
                })
                if (this.props.onSuccessTimer) {
                    this.props.onSuccessTimer()
                }
            } else {
                this.time_left -= 1000
                const time = this.calculateTimeLeft()
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        hours: time.hours,
                        minutes: time.minutes,
                        seconds: time.seconds
                    }
                })
            }
        }, 1000)
    }

}


export default Timer;
