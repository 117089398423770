import React from "react";

import "./styles.scss";

import TextInput from "../../components/Inputs/TextInput";
import SelectInput from "../../components/Inputs/SelectInput";
import Button from "../../components/Buttons/Button";
// import GoogleMaps from "../../components/Map";

const AddressForm = (props) => {
  const onChange = (event) => {
    props.onChange(event);
  };

  // const onMapPress = (event) => {
  //     props.onMapPress(event);
  // }

  const onSubmit = (event) => {
    props.onSubmit(event);
  };

  return (
    <form onSubmit={onSubmit} className="form-address">
    {console.log("Address Form")}
    {console.log(props)}
      <div className="form-group">
        <label>Procedencia</label>
        <SelectInput
          className="form-control form-control-lg"
          id="address_scope"
          value={props.addressScopeSelected}
          options={props.addressScopes}
          blankOption={false}
          onChange={onChange}
        />
      </div>
      <div className="form-group">
        <label>Nombre y Apellido</label>
        <TextInput
          className="form-control"
          id="name"
          value={props.name}
          maxLength={50}
          onChange={onChange}
        />
      </div>
      <div className="form-group">
        <label>Teléfono</label>
        <TextInput
          className="form-control"
          id="phone"
          value={props.phone}
          onChange={onChange}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <TextInput
          type="email"
          className="form-control"
          id="email"
          value={props.email}
          onChange={onChange}
        />
      </div>
      <div className="form-group">
        <label>Dirección #1</label>
        <TextInput
          className="form-control"
          id="address1"
          value={props.address1}
          onChange={onChange}
        />
      </div>
      <div className="form-group">
        <label>Dirección #2</label>
        <TextInput
          className="form-control"
          id="address2"
          value={props.address2}
          onChange={onChange}
        />
      </div>
      <div className="form-group">
        <label>Ciudad</label>
        <TextInput
          className="form-control form-control-lg"
          id="locality"
          value={props.locality}
          onChange={onChange}
          type="text"
        />
      </div>
      <div className="form-group">
        <label>Departamento o Estado</label>
        <SelectInput
          className="form-control form-control-lg"
          id="state"
          value={props.stateSelected}
          options={props.states}
          onChange={onChange}
        />
      </div>
      <div className="form-group">
        <label>Código postal</label>
        <TextInput
          className="form-control"
          id="postcode"
          value={props.postcode}
          maxLength={10}
          maxLength={5}
          onChange={onChange}
        />
      </div>
      <div className="form-group">
        <label>País</label>
        <SelectInput
          className="form-control form-control-lg"
          id="country"
          value={props.countrySelected}
          options={
            props.addressScopeSelected === "origin"
              ? props.originCountries
              : props.destinationCountries
          }
          onChange={onChange}
        />
      </div>

      {/* <div className="form-group">
                <label>Mapa</label>
                <GoogleMaps onMapPress={onMapPress} markers={props.marker} />
            </div> */}
      <Button
        type="submit"
        className="btn btn-primary float-right btn-wizard-confirm button-form"
      >
        Continuar
      </Button>
    </form>
  );
};

export default AddressForm;
