import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

import "./styles.scss";

import * as actions from "../../actions/global_parameters.actions";
import * as addressActions from "../../actions/addresses.actions";
import { ifUnauthorized } from "../../utils/authentication";
import Preloader from "../../components/Preloader";
import WizardSteps from "../../components/WizardSteps";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { act } from "react-dom/test-utils";
import * as alerts from "../../utils/alertify";
import alertify from "alertifyjs";



function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_ID}`);

function Home(props) {
  const [showPreloader, setShowPreloader] = useState(true);
  const [itemCategories, setItemCategories] = useState([]);
  const [itemCategorySelected, setItemCategorySelected] = useState(0);
  const [boxTypes, setBoxTypes] = useState([]);
  const [boxTypeSelected, setBoxTypeSelected] = useState(0);
  const [boxTypeSelectedPrevious, setBoxTypeSelectedPrevious] = useState(0);
  const [originNewAddress, setOriginNewAddress] = useState(false);
  const [originAddresses, setOriginAddresses] = useState([]);
  const [selectedOriginAddress, setSelectedOriginAddress] = useState(0);
  let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const [originName, setOriginName] = useState(currentUser?.name || '');
  const [originLastName, setOriginLastName] = useState(currentUser?.last_name || '');
  const [originPhone, setOriginPhone] = useState(currentUser?.phone || '');
  const [originEmail, setOriginEmail] = useState("");
  const [originAddress1, setOriginAddress1] = useState("");
  const [originAddress2, setOriginAddress2] = useState("");
  const [originPostcode, setOriginPostcode] = useState("");
  const [originIsValidZipCode, setOriginIsValidZipCode] = useState(false);
  const [originLocality, setOriginLocality] = useState("");
  const [originCountries, setOriginCountries] = useState([]);
  const [originStates, setOriginStates] = useState([]);
  const [originCountrySelected, setOriginCountrySelected] = useState(0);
  const [originStateSelected, setOriginStateSelected] = useState(0);
  // const [originLat, setOriginLat] = useState(null);
  // const [originLng, setOriginLng] = useState(null);
  // const [originMarker, setOriginMarker] = useState([]);
  const [destinationNewAddress, setDestinationNewAddress] = useState(false);
  const [destinationAddresses, setDestinationAddresses] = useState([]);
  const [selectedDestinationAddress, setSelectedDestinationAddress] = useState(0);
  const [destinationName, setDestinationName] = useState("");
  const [destinationLastName, setDestinationLastName] = useState("");
  const [destinationPhone, setDestinationPhone] = useState("");
  const [destinationEmail, setDestinationEmail] = useState("");
  const [destinationAddress1, setDestinationAddress1] = useState("");
  const [destinationAddress2, setDestinationAddress2] = useState("");
  const [destinationPostcode, setDestinationPostcode] = useState("");
  const [destinationLocality, setDestinationLocality] = useState("");
  const [destinationCountries, setDestinationCountries] = useState([]);
  const [destinationStates, setDestinationStates] = useState([]);
  const [destinationCities, setDestinationCities] = useState([]);
  const [destinationBranchCopacabana, setDestinationBranchCopacabana] = useState([]);
  const [selectecBranches, setSelectecBranches] = useState([]);
  const [destinationCountrySelected, setDestinationCountrySelected] = useState(0);
  const [destinationStateSelected, setDestinationStateSelected] = useState(0);
  const [destinationBranchCopacabanaSelectedSThree, setDestinationBranchCopacabanaSelectedSThree] = useState();
  const [destinationBranchCopacabanaSelectedSSpecial, setDestinationBranchCopacabanaSelectedSSpecial] = useState();
  const [optionsPaP, setOptionsPaP] = useState([]);
  const [servicePaPST, setServicePaPST] = useState();
  const [servicePaPSS, setServicePaPSS] = useState();
  const [matchCityST, setMatchCityST] = useState();
  const [matchCitySS, setMatchCitySS] = useState();
  // const [destinationLat, setDestinationLat] = useState(null);
  // const [destinationLng, setDestinationLng] = useState(null);
  // const [destinationMarker, setDestinationMarker] = useState([]);
  const [weight, setWeight] = useState(0);
  const [weightMeasure, setWeightMeasure] = useState(0);
  const [items, setItems] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [additionalNote, setAdditionalNote] = useState("");
  const [receptionTypes, setReceptionTypes] = useState([]);
  const [receptionTypeSelected, setReceptionTypeSelected] = useState(2);
  const [sessionId, setSessionId] = useState(null);
  const [totalAmount, setTotalAmount] = useState([]);
  const [step, setStep] = useState(1);
  const maxStep = 6;
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    let boxTypeDefault = query.get('order_selected');
    let urlParams = null;
    if (boxTypeDefault && boxTypeDefault > 0 && boxTypeDefault < 5) {
      setBoxTypeSelected(boxTypeDefault);
      localStorage.setItem("boxTypeDefault", boxTypeDefault);
      urlParams = "?order_selected=" + boxTypeDefault;
    } // else {
    //   boxTypeDefault = localStorage.getItem("boxTypeDefault") || 2
    //   setBoxTypeSelected(boxTypeDefault);
    // }
    ifUnauthorized(history, getGlobalParameters, urlParams);
  }, [])

  const getGlobalParameters = () => {
    actions.globalParameters({ filters: ["order_types", "countries", "reception_types", "item_categories","branch_offices","order_delivery_types"], filter_mode: "origin" })
      .then(response => {
        if (response.type === "SUCCESSFULLY") {
          console.log("response.payload");
          console.log(response.payload);
          const { payload } = response;
          setBoxTypes(payload.order_types.data);
          setReceptionTypes(payload.reception_types.data);
          setItemCategories(
            payload.item_categories.data.map((category) => {
              return {
                id: category.id,
                name: category.attributes.name,
                description: category.attributes.description,
                tariff: category.attributes.tariff,
              };
            })
          );
          setOriginEmail(localStorage.getItem("user_email"));
          setOriginCountries(payload.countries.data.map(country => {
            return {
              id: country.id,
              name: country.attributes.name
            }
          }));
          setOriginCountrySelected(payload.countries.data[0].id);

          // branches Copacabana and options PaP
          let cities = payload.branch_offices.data
            .map((branch) => {
              return {
                id: branch.id,
                city: branch.attributes.city,
              };
            })
            .filter(
              (city, index, self) =>
                index === self.findIndex((i) => i.city === city.city)
            );

          setDestinationCities(cities);
          setDestinationBranchCopacabana(
            payload.branch_offices.data
          );

          console.log("Antes de entrar al supuesto ERROR")
          console.log(payload)

          setOptionsPaP(
            payload.order_delivery_types.data.map((d) => {
              return {
                id: d.id,
                name: d.attributes.description,
                name2: d.attributes.name,
                code: d.attributes.code,
              };
            })
          );


          actions.globalParameters({ filters: ["countries", "states"], filter_mode: "destination", country_id: payload.countries.data[0].id })
            .then(response => {
              if (response.type === "SUCCESSFULLY") {
                const { payload } = response;
                console.log("payload countries")
                console.log(payload)
                setDestinationCountries(payload.countries.data.map(country => {
                  return {
                    id: country.id,
                    name: country.attributes.name
                  }
                }));
                setDestinationCountrySelected(payload.countries.data[0].id);
                setOriginStates(payload.states.data.map(state => {
                  return {
                    id: state.id,
                    name: state.attributes.name
                  }
                }));
                actions.globalParameters({ filters: ["states"], country_id: payload.countries.data[0].id })
                  .then(response => {
                    if (response.type === "SUCCESSFULLY") {
                      const { payload } = response;
                      setDestinationStates(payload.states.data.map(state => {
                        return {
                          id: state.id,
                          name: state.attributes.name
                        }
                      }));
                      addressActions.myAddresses()
                        .then(response => {
                          if (response.type === "SUCCESSFULLY") {
                            let addresses = response.payload.data;
                            console.log("addresses");
                            console.log(addresses);
                            addresses.map((address) => {
                              let newAddresses = [];
                              let addressObject = {
                                id: address.id,
                                name: address.attributes.name
                                  ? address.attributes.name
                                  : "Sin nombre",
                                last_name: address.attributes.last_name,
                                phone: address.attributes.phone,
                                email: address.attributes.email,
                                address1: address.attributes.street,
                                address2: address.attributes.street2,
                                postcode: address.attributes.postal_code,
                                locality: address.attributes.city_locality,
                                scope: address.attributes.address_scope,
                                countryName:
                                  address.relationships.country.meta.data
                                    .attributes.name,
                                stateName:
                                  address.relationships.state.meta.data
                                    .attributes.name,
                                // lat: address.attributes.lat,
                                // lng: address.attributes.lng,
                              };
                              if (addressObject.scope === "origin") {
                                newAddresses = originAddresses;
                                newAddresses.push(addressObject);
                                console.log("Origen");
                                console.log(addresses);
                                setOriginAddresses(newAddresses);
                              } else {
                                newAddresses = destinationAddresses;
                                newAddresses.push(addressObject);
                                console.log("Destino");
                                console.log(addresses);
                                setDestinationAddresses(newAddresses);
                              }
                            });

                            setShowPreloader(false);
                          }
                        })
                        .catch(error => {
                          console.log(error.response);
                        });
                    }
                  })
                  .catch(error => {
                    console.log(error.response);
                  });
              }
            })
            .catch(error => {
              console.log(error.response);
            })
        }
      })
      .catch(error => {
        setShowPreloader(false);
        console.log(error.response);
      })
  }

  const onOrderCreate = () => {
    setShowPreloader(true);
    let data = new FormData();
    data.append("transaction_identifier", "SUBMIT_ORDER");
    data.append("item_category_id", itemCategorySelected);
    data.append("order_type_id", boxTypeSelected);

    if (selectedDestinationAddress !== 0 && !destinationNewAddress) {
      // Order delivery type and copacabana branch at existing address
      if (matchCitySS) data.append("order_delivery_type_id", servicePaPSS);
      else data.append("order_delivery_type_id", 2);

      data.append(
        "receiving_branch_office_id",
        destinationBranchCopacabanaSelectedSSpecial
      );
    } else { // Order delivery type and copacabana branch in new address
      if (matchCityST) data.append("order_delivery_type_id", servicePaPST);
      else data.append("order_delivery_type_id", 2);
      data.append(
        "receiving_branch_office_id",
        destinationBranchCopacabanaSelectedSThree
      );
    }
    data.append("reception_type_id", receptionTypeSelected);
    data.append("additional_note", additionalNote);
    // if (weightMeasure === 0) {
    //   data.append("weight", weight);
    // } else {
    //   data.append("weight", weight * 2.2);
    // }
    if (selectedOriginAddress !== 0 && !originNewAddress) {
      data.append("origin_id", selectedOriginAddress);
    }
    data.append("origin[name]", originName);
    data.append("origin[last_name]", originLastName);
    data.append("origin[phone]", originPhone);
    data.append("origin[email]", originEmail);
    data.append("origin[street]", originAddress1);
    data.append("origin[street2]", originAddress2);
    data.append("origin[postal_code]", originPostcode);
    // data.append("origin[lat]", originLat);
    // data.append("origin[lng]", originLng);
    data.append("origin[city_locality]", originLocality);
    data.append("origin[country_id]", originCountrySelected);
    data.append("origin[state_id]", originStateSelected);
    if (selectedDestinationAddress !== 0 && !destinationNewAddress) {
      data.append("destination_id", selectedDestinationAddress);
    }
    data.append("destination[name]", destinationName);
    data.append("destination[last_name]", destinationLastName);
    data.append("destination[phone]", destinationPhone);
    data.append("destination[email]", destinationEmail);
    data.append("destination[street]", destinationAddress1);
    data.append("destination[street2]", destinationAddress2);
    data.append("destination[postal_code]", destinationPostcode);
    // data.append("destination[lat]", destinationLat);
    // data.append("destination[lng]", destinationLng);
    data.append("destination[city_locality]", destinationLocality);
    data.append("destination[country_id]", destinationCountrySelected);
    data.append("destination[state_id]", destinationStateSelected);
    items.map(item => {
      data.append("items[][name]", item.name);
      data.append("items[][description]", item.description);
      data.append("items[][brand]", item.brand);
      data.append("items[][gender]", item.gender);
      data.append("items[][material]", item.material);
      data.append("items[][qty]", item.qty);
      data.append("items[][unit_price]", item.unit_price);
      // data.append("items[][item_type]", item.item_type);
    });
    // attachments.map(attachment => {
    //   data.append("attachments[]", attachment.file);
    // });
    actions.updateOrder(data)
      .then(async (response) => {
        if (response.type === "SUCCESSFULLY") {
          setSessionId(response.payload.session_id);
          const orderId = response.payload.order.data.id;
          //

          setShowPreloader(true);
          const stripe = await stripePromise;
          stripe.redirectToCheckout({
            sessionId: response.payload.session_id,
          }).then(res => {
            // console.log("stripe response");
          }).catch(error => {
            console.log(error.response);
          });
          setShowPreloader(false);

          //
          // actions.totalAmount({ order_id: orderId })
          //   .then(response => {
          //     if (response.type == "SUCCESSFULLY") {
          //       setTotalAmount(response.payload);
          //       setShowPreloader(false);
          //     }
          //   })
          //   .catch(error => {
          //     console.log(error.response);
          //     setShowPreloader(false);
          //   });
        }
      })
      .catch(error => {
        console.log(error);
        setShowPreloader(false);
      })
  }

  const onPaymentConfirm = () => {
    // setShowPreloader(true);
    // const stripe = await stripePromise;
    // stripe.redirectToCheckout({
    //   sessionId: sessionId,
    // }).then(res => {
    //   // console.log("stripe response");
    // }).catch(error => {
    //   console.log(error.response);
    // });
    // setShowPreloader(false);

    alertify.confirm(
      "Esta a punto de pagar",
      "Si le da en Ok no podrá volver atrás para editar su pedido. </br>  Si no está seguro de los datos ingresados en su orden, puede presionar en cancelar y revisar su orden.",
      function () {
        onOrderCreate();
      },
      function () {
        alerts.notifyWarning("Aún puede editar su pedido");
      }
    );
  };

  const notice = {
    title: "El contenido de tu envío está regulado bajo la siguientes reglas",
    rules: {
      value: "El valor de tu caja no debe exceder los USD 2.000,00",
      chemical: "Productos Farmacéuticos y Bioquímicos no pueden ser enviados.",
      weapon: "Artefactos de Milicias no pueden ser enviados.",
    },
    note: "A cualquier incumplimiento de estas reglas su caja será rechazada."
  }

  return (
    <div className="">
      { showPreloader && <Preloader />}
      <div className="custom-block custom-block__option custom-block--info">
        <p className="p-tag p-tag--service-title-description">Envío de paquetes de Estados Unidos a Bolivia.{" "}
          {
            <FontAwesomeIcon icon={['fas', 'exclamation-circle']}
              data-toggle="popover" data-placement="auto" data-trigger="hover"
              data-title={`${notice.title}`}
              data-content={`<div className="container--service-notice-list" >
                <div>
                  <ul className="ul-tag--service-notice">
                    <li>${notice.rules.chemical}</li>
                    <li>${notice.rules.weapon}</li>
                  </ul>
                </div>
                <div>
                  <span>${notice.note}</span>
                </div>
              </div>`}
            />
          }
        </p>
      </div>
      <WizardSteps
        showPreloader={showPreloader}
        setShowPreloader={setShowPreloader}

        step={step}
        maxStep={maxStep}
        setStep={setStep}

        itemCategories={itemCategories}
        itemCategorySelected={itemCategorySelected} setItemCategorySelected={setItemCategorySelected}

        boxTypes={boxTypes}
        boxTypeSelected={boxTypeSelected} setBoxTypeSelected={setBoxTypeSelected}
        boxTypeSelectedPrevious={boxTypeSelectedPrevious} setBoxTypeSelectedPrevious={setBoxTypeSelectedPrevious}

        receptionTypes={receptionTypes}
        receptionTypeSelected={receptionTypeSelected} setReceptionTypeSelected={setReceptionTypeSelected}

        originNewAddress={originNewAddress} setOriginNewAddress={setOriginNewAddress}
        originAddresses={originAddresses}
        selectedOriginAddress={selectedOriginAddress} setSelectedOriginAddress={setSelectedOriginAddress}
        originName={originName} setOriginName={setOriginName}
        originLastName={originLastName} setOriginLastName={setOriginLastName}
        originPhone={originPhone} setOriginPhone={setOriginPhone}
        originEmail={originEmail} setOriginEmail={setOriginEmail}
        originAddress1={originAddress1} setOriginAddress1={setOriginAddress1}
        originAddress2={originAddress2} setOriginAddress2={setOriginAddress2}
        originPostcode={originPostcode} setOriginPostcode={setOriginPostcode}
        originIsValidZipCode={originIsValidZipCode} setOriginIsValidZipCode={setOriginIsValidZipCode}
        originLocality={originLocality} setOriginLocality={setOriginLocality}
        originCountries={originCountries} originStates={originStates} setOriginStates={setOriginStates}
        originCountrySelected={originCountrySelected} setOriginCountrySelected={setOriginCountrySelected}
        originStateSelected={originStateSelected} setOriginStateSelected={setOriginStateSelected}
        // originLat={originLat} setOriginLat={setOriginLat}
        // originLng={originLng} setOriginLng={setOriginLng}
        // originMarker={originMarker} setOriginMarker={setOriginMarker}

        destinationNewAddress={destinationNewAddress} setDestinationNewAddress={setDestinationNewAddress}
        destinationAddresses={destinationAddresses}
        selectedDestinationAddress={selectedDestinationAddress} setSelectedDestinationAddress={setSelectedDestinationAddress}
        destinationName={destinationName} setDestinationName={setDestinationName}
        destinationLastName={destinationLastName} setDestinationLastName={setDestinationLastName}
        destinationPhone={destinationPhone} setDestinationPhone={setDestinationPhone}
        destinationEmail={destinationEmail} setDestinationEmail={setDestinationEmail}
        destinationAddress1={destinationAddress1} setDestinationAddress1={setDestinationAddress1}
        destinationAddress2={destinationAddress2} setDestinationAddress2={setDestinationAddress2}
        destinationPostcode={destinationPostcode} setDestinationPostcode={setDestinationPostcode}
        destinationLocality={destinationLocality} setDestinationLocality={setDestinationLocality}
        destinationCountries={destinationCountries} destinationStates={destinationStates} setDestinationStates={setDestinationStates}
        destinationCities={destinationCities} setDestinationCities={setDestinationCities}
        destinationBranchCopacabana={destinationBranchCopacabana} setDestinationBranchCopacabana={setDestinationBranchCopacabana}
        selectecBranches={selectecBranches} setSelectecBranches={setSelectecBranches}
        destinationCountrySelected={destinationCountrySelected} setDestinationCountrySelected={setDestinationCountrySelected}
        destinationStateSelected={destinationStateSelected} setDestinationStateSelected={setDestinationStateSelected}
        destinationBranchCopacabanaSelectedSThree={destinationBranchCopacabanaSelectedSThree} setDestinationBranchCopacabanaSelectedSThree={setDestinationBranchCopacabanaSelectedSThree}
        destinationBranchCopacabanaSelectedSSpecial={destinationBranchCopacabanaSelectedSSpecial} setDestinationBranchCopacabanaSelectedSSpecial={setDestinationBranchCopacabanaSelectedSSpecial}
        optionsPaP={optionsPaP}
        servicePaPST={servicePaPST} setServicePaPST={setServicePaPST}
        servicePaPSS={servicePaPSS} setServicePaPSS={setServicePaPSS}
        matchCityST={matchCityST} setMatchCityST={setMatchCityST}
        matchCitySS={matchCitySS} setMatchCitySS={setMatchCitySS}
        // destinationLat={destinationLat} setDestinationLat={setDestinationLat}
        // destinationLng={destinationLng} setDestinationLng={setDestinationLng}
        // destinationMarker={destinationMarker} setDestinationMarker={setDestinationMarker}

        items={items} setItems={setItems}
        attachments={attachments} setAttachments={setAttachments}
        additionalNote={additionalNote} setAdditionalNote={setAdditionalNote}
        weight={weight} setWeight={setWeight}
        weightMeasure={weightMeasure} setWeightMeasure={setWeightMeasure}

        totalAmount={totalAmount}
        sessionId={sessionId}

        onPaymentConfirm={onPaymentConfirm}
        onOrderCreate={onOrderCreate}
      />
    </div>
  )
}

export default withRouter(Home);
