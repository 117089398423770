import React, { useMemo, useState } from "react";

import "./styles.scss";
import "../../modules/Home/styles.scss";
import * as alerts from "../../utils/alertify";
// import StepItemCategory from "./../../modules/Home/StepItemCategory";
import StepOne from "./../../modules/Home/StepOne";
import StepTwo from "./../../modules/Home/StepTwo";
import StepThree from "./../../modules/Home/StepThree";
import StepFour from "./../../modules/Home/StepFour";
import StepFive from "./../../modules/Home/StepFive";
import StepSix from "./../../modules/Home/StepSix";
import Contact from "./../../modules/Contact/Contact"
import AddressForm from "./../../modules/Home/AddressForm"
import Button from "../../components/Buttons/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as actions from "./../../actions/global_parameters.actions"

const WizardSteps = (props) => {

  const [selectedBranchInfoSThree, setSelectedBranchInfoSThree] = useState();
  const [selectedBranchInfoSSpecial, setSelectedBranchInfoSSpecial] = useState();
  // const [matchCity, setMatchCity] = useState(initialState);

  const NO_STEP = 0;
  const FIRST_STEP = 1;
  const SECOND_STEP = 2;
  const THIRD_STEP = 3;
  const SPECIAL_STEP = 4;
  const FOURTH_STEP = 5;
  const FIFTH_STEP = 6;
  const SIXTH_STEP = 6;
  // const SEVENTH_STEP = 7;

  const getStepTitle = useMemo(
    () => {
      switch (props.step) {
        case FIRST_STEP:
          return { title: "Selecciona el tamaño de tu caja", description: "" }
        // case SECOND_STEP:
        //     return { title: "Selecciona tu caja", description: "Escoge la caja en la que sera enviado tu pedido, toma en cuenta las dimensiones deacuerdo a tus necesidades! " }
        case SECOND_STEP:
          return { title: "Datos de origen (remitente)", description: "Selecciona una de tus direcciones de origen o agrega una nueva dirección" }
        case THIRD_STEP:
          return { title: "Datos de destino (destino final)", description: "Selecciona una de tus direcciones de destino o agrega una nueva dirección" }
        case SPECIAL_STEP:
          return { title: "Destino del paquete", description: "Selecciona una de las sucursales de flota Copacabana" }
        case FOURTH_STEP:
          return { title: "Información de contenido", description: "" }
        case FIFTH_STEP:
          return { title: "Procedimiento para el envío de su caja", description: "Tome nota de las indicaciones para hacernos llegar su caja y nosotros enviarlo al destino establecido." }
        case SIXTH_STEP:
          return { title: "Resumen y Costo de envío", description: "Proceda a revisar la información provista de su envío y realizar el pago." }
        default:
          return undefined
      }
    }, [props.step]
  )


  const form = document.getElementById("mainform");

  const previous = () => {
    if (props.step === 1) {
      window.location.replace(`https://www.easybolbox.com/`);
    } else if (props.step > 1 && !props.showPreloader) {
      form.classList.remove('was-validated')
      console.log("WIZARD");
      console.log(props);
      if (props.destinationNewAddress && props.step === FOURTH_STEP)
        props.setStep(props.step - 2);
      else props.setStep(props.step - 1);
    }
  }

  const checkTheSame = (jumpTo, step) => {
    return jumpTo == step;
  }

  const goingBack = (jumpTo) => {
    return props.step > jumpTo
  }

  const goToStep = (step) => {
    form.classList.remove('was-validated');
    // if (step === 7 && props.sessionId === null) {
    //     props.onOrderCreate();
    // }
    props.setStep(step);
  }

  const checkValidityForm = (verify = false) => {
    if (!verify) {
      form.reportValidity();
      form.classList.add('was-validated');
      return (form.checkValidity() && form.reportValidity())
    } else {
      return true;
    }
  }

  const ableToFirstStep = () => {
    return true;
  }

  const goToFirstStep = (verify = false) => {
    let pass = true;
    if (ableToFirstStep()) {
      if (!verify) {
        goToStep(FIRST_STEP);
      } else {
        return pass;
      }
    } else {
      alerts.notifyWarning("No se puede ir a la seleccion de tipo de caja. </>");
      if (verify) return !pass;
    }

  }

  const ableToSecondStep = () => {
    return (props.boxTypeSelected !== 0);
  }

  const goToSecondStep = (verify = false) => {
    if (goToFirstStep(true)) {
      let pass = true;
      if (ableToSecondStep()) {
        if (!verify) {
          goToStep(SECOND_STEP);
        } else {
          return pass;
        }
      } else {
        alerts.notifyError("Para continuar selecciona el tipo de caja!");
        if (verify) return !pass;
      }
    }
  }

  // const ableToThirdStep = () => {
  //     return (props.itemCategorySelected !== 0);
  // }

  // const goToThirdStep = (verify = false) => {
  //     if (goToSecondStep(true)) {
  //         let pass = true;
  //         if (ableToThirdStep()) {
  //             if (!verify) {
  //                 goToStep(THIRD_STEP);
  //             } else {
  //                 return pass;
  //             }
  //         } else {
  //             alerts.notifyWarning("Para continuar selecciona el tipo de contenido!");
  //             if (verify) return !pass;
  //         }
  //     }
  // }

  const ableToThirdStep = () => {
    console.log("desde ABLE")
    console.log(props.originNewAddress    )
    console.log(props.originLocality !== "");
    console.log(!props.originNewAddress);
    console.log(props.selectedOriginAddress !== 0);

    /* USE THIS IN PRODUCTION */
    return ((props.originNewAddress && props.originLocality !== "" && props.originIsValidZipCode)
      || (!props.originNewAddress && props.selectedOriginAddress !== 0))

    /* USE THIS IN DEVELOPMENT */
    // return ((props.originNewAddress && props.originLocality !== "")
    //   || (!props.originNewAddress && props.selectedOriginAddress !== 0))
  }

  const goToThirdStep = (verify = false) => {
    if (goToSecondStep(true)) {
      let pass = true;
      if (checkValidityForm(verify) && ableToThirdStep()) {

        if (!verify) {
          goToStep(THIRD_STEP);
        } else {
          return pass;
        }

      }
      else if (!props.originNewAddress) {
        alerts.notifyWarning("Seleccione una dirección de origen para continuar.");
        if (verify) return !pass;
      } else if (props.originNewAddress) {

        if (!props.originIsValidZipCode)
          alerts.notifyWarning("Código postal no válido");

        alerts.notifyWarning("Para continuar debes rellenar todos los campos");
        if (verify) return !pass;

      }
    }

  }

  const ableToSpecialStep = () => {
    return !props.destinationNewAddress;
  };

  const goToSpecialStep = (verify = false) => {
    if (goToThirdStep(true)) {
      let pass = true;
      if (checkValidityForm(verify) && ableToSpecialStep()) {

        if (!verify) {
          goToStep(SPECIAL_STEP);
        } else {
          return pass;
        }

      }
      else if (!props.destinationNewAddress) {
        alerts.notifyWarning("Seleccione una dirección de destino para continuar 2.");
        if (verify) return !pass;
      } else if (props.destinationNewAddress) {

        alerts.notifyWarning("Para continuar debes rellenar todos los campos 2");
        if (verify) return !pass;

      }
    }
  }


  // in the wizard is step 4
  const ableToFourthStep = () => {
    return ((props.destinationNewAddress && props.destinationLocality !== "")
      || (!props.destinationNewAddress && props.selectedDestinationAddress !== 0))
  }

  const goToFourthStep = (verify = false) => {
    if (goToThirdStep(true)) {
      let pass = true;
      if (checkValidityForm(verify) && ableToFourthStep()) {

        if (!verify) {
          goToStep(FOURTH_STEP);
        } else {
          return pass;
        }

      } else if (!props.destinationNewAddress) {
        alerts.notifyWarning("Seleccione una dirección de destino para continuar.");
        if (verify) return !pass;
      } else if (props.destinationNewAddress) {

        alerts.notifyWarning("Para continuar debes rellenar todos los campos");
        if (verify) return !pass;

      } else if (props.destinationBranchCopacabanaSelected!==0) {
        alerts.notifyWarning("Para continuar debes seleccionar una sucursal de la flota Copacabana 2");
        if (verify) return !pass;
      }
    }
  }

  // in the wizard is step 5
  const ableToFifthStep = () => {
    return (props.items.filter(item =>
      item.name !== "" && item.description !== "" && item.qty !== 0 &&
      item.unit_price !== 0
    ).length > 0)
  }

  const goToFifthStep = (verify = false) => {
    if (goToFourthStep(true)) {
      let pass = true;
      if (checkValidityForm(verify) && ableToFifthStep()) {

        if (!verify) {
          goToStep(FIFTH_STEP);
        } else {
          return pass;
        }

      } else {
        alerts.notifyWarning("Para continuar debe proporcionarnos información del contenido, rellenando los campos.")
        if (verify) return !pass;

      }
    }
  }

  const ableToSixthStep = () => {
    // return (props.receptionTypeSelected !== 0);
    return true;
  }

  const goToSixthStep = (verify = false) => {
    if (goToFifthStep(true)) {
      let pass = true;
      if (ableToSixthStep()) {
        if (!verify) {
          goToStep(SIXTH_STEP);
        } else {
          return pass;
        }
      } else {
        alerts.notifyWarning("Para continuar selecciona el tipo de entrega");
        if (verify) return !pass;
      }
    }
  }

  const next = (event, jumpTo = NO_STEP) => {
    event.preventDefault();
    event.stopPropagation();

    let goTo = undefined;
    let itsNext = undefined;
    let itsTheSame = false;

    if (jumpTo === NO_STEP) {
      goTo = props.step;
      itsNext = true;
    } else {
      goTo = jumpTo;
      itsNext = false;
      itsTheSame = checkTheSame(jumpTo, props.step)
    }
    if (!props.showPreloader && !itsTheSame) {
      switch (goTo) {
        case FIRST_STEP:
          if (!itsNext) {
            goToFirstStep();
          } else {
            goToSecondStep();
          }
          break;
        case SECOND_STEP:
          console.log("Desde el Wizard")
          console.log(props)
          if (!itsNext && goingBack(goTo)) {
            goToStep(goTo)
          } else {
            if (!itsNext) {
              goToSecondStep();
            } else {
              goToThirdStep();
            }
          }
          break;
        case THIRD_STEP:
          if (!itsNext && goingBack(goTo)) {
            goToStep(goTo)
          } else {
            if (!itsNext) {
              goToThirdStep();
            } else if(!props.destinationNewAddress){
              goToSpecialStep();
            }
            else{
              goToFourthStep();
            }
          }
          break;
        case SPECIAL_STEP:
          if (!itsNext && goingBack(goTo)) {
            goToStep(goTo)
          } else {
            if (!itsNext) {
              goToSpecialStep();
            } else {
              goToFourthStep();
            }
          }
          break;
        case FOURTH_STEP:
          if (!itsNext && goingBack(goTo)) {
            goToStep(goTo)
          } else {
            if (!itsNext) {
              goToFourthStep();
            } else {
              goToFifthStep();
            }
          }
          break;
        case FIFTH_STEP:
          if (!itsNext && goingBack(goTo)) {
            goToStep(goTo)
          } else {
            if (!itsNext) {
              goToFifthStep();
            } else {
              goToSixthStep();
            }
          }
          break;
        case SIXTH_STEP:
          if (!itsNext && goingBack(goTo)) {
            goToStep(goTo)
          } else {
            if (!itsNext) {
              goToSixthStep();
            } else {
              alerts.notifyError("Error this step dont exist");
            }
          }
          break;
        // case SEVENTH_STEP:
        //     if (!itsNext && goingBack(goTo)) {
        //         goToStep(goTo)
        //     } else {
        //         if (!itsNext) {
        //             goToSeventhStep();
        //         } else {
        //             alerts.notifyError("Error this step dont exist");
        //         }
        //     }
        //     break;
        default:
          break;
      }
    }
  }


  // const branches=()=>{
  //   actions
  //   .globalParameters({ filters: ["branch_offices"] })
  //   .then((response) => {
  //     if (response.type === "SUCCESSFULLY") {
  //       const { payload } = response;
  //       let dptoSelected;
  //       // event.target.options[event.target.selectedIndex].text;
  //       props.destinationAddresses.map((address) => {
  //         if (props.selectedDestinationAddress == address.id)
  //           dptoSelected = address.stateName;
  //         console.log("Global Parameters")
  //         console.log(address)
  //         console.log(dptoSelected)
  //       });
  //       let branches = payload.branch_offices.data.filter(
  //         (b) => b.attributes.dpto == dptoSelected
  //       );

  //       // props.setBranchCopacabana(branches);
  //       props.setBranchCopacabanaSelected(0);
  //       // setMatchCity(false);
  //       return branches
  //     }
  //   });
  // }

  return (
    <form
      className="needs-validation"
      autoComplete="off"
      id="mainform"
      onSubmit={next}
      noValidate
    >
      <div className="custom-block custom-block__option custom-block--wizard">
        <div className="flex-container">
          <div
            className={props.step === FIRST_STEP ? "circle-selected" : "circle"}
            onClick={(event) => {
              next(event, FIRST_STEP);
            }}
          >
            <div
              className={
                props.step === FIRST_STEP ? "indicator-selected" : "indicator"
              }
            >
              {FIRST_STEP}
            </div>
          </div>
          <div
            className={
              props.step === SECOND_STEP ? "circle-selected" : "circle"
            }
            onClick={(event) => {
              next(event, SECOND_STEP);
            }}
          >
            <div
              className={
                props.step === SECOND_STEP ? "indicator-selected" : "indicator"
              }
            >
              {SECOND_STEP}
            </div>
          </div>
          <div
            className={
              props.step === THIRD_STEP || props.step === SPECIAL_STEP
                ? "circle-selected"
                : "circle"
            }
            onClick={(event) => {
              next(event, THIRD_STEP);
            }}
          >
            <div
              className={
                props.step === THIRD_STEP || props.step === SPECIAL_STEP
                  ? "indicator-selected"
                  : "indicator"
              }
            >
              {THIRD_STEP}
            </div>
          </div>
          <div
            className={
              props.step === FOURTH_STEP ? "circle-selected" : "circle"
            }
            onClick={(event) => {
              next(event, FOURTH_STEP);
            }}
          >
            <div
              className={
                props.step === FOURTH_STEP ? "indicator-selected" : "indicator"
              }
            >
              {
                //FOURTH_STEP
                4
              }
            </div>
          </div>
          <div
            className={props.step === FIFTH_STEP ? "circle-selected" : "circle"}
            onClick={(event) => {
              next(event, FIFTH_STEP);
            }}
          >
            <div
              className={
                props.step === FIFTH_STEP ? "indicator-selected" : "indicator"
              }
            >
              {
                //FIFTH_STEP
                5
              }
            </div>
          </div>
          {/* <div
              className={
                props.step === SIXTH_STEP ? "circle-selected" : "circle"
              }
              onClick={(event) => {
                next(event, SIXTH_STEP);
              }}
            >
              <div
                className={
                  props.step === SIXTH_STEP ? "indicator-selected" : "indicator"
                }
              >
                {SIXTH_STEP}
              </div>
            </div> */}
          {/* <div className={props.step === SEVENTH_STEP ? "circle-selected" : "circle"} onClick={(event) => { next(event, SEVENTH_STEP) }}>
                        <div className={props.step === SEVENTH_STEP ? "indicator-selected" : "indicator"}>{SEVENTH_STEP}</div>
                    </div> */}
        </div>
        <div className="title-section">
          <div className="step-title">{getStepTitle.title}</div>
          <div className="step-description">
            {/* <strong>(Este documento tiene valor de declaración jurada.)</strong> */}
            <strong>
              (Ahora le ayudaremos a realizar su declaración jurada. La
              mercadería que enviará será verificada, en caso de haber una
              diferencia entre el contenido y lo declarado, la caja será
              devuelta al remitente y se deducirán los costos operativos.)
            </strong>
          </div>
          {props.step === SPECIAL_STEP ? (
            <h4 className="text-danger mt-3">{getStepTitle.description}</h4>
          ) : (
            <div className="step-description">{getStepTitle.description}</div>
          )}
        </div>
        {props.step === FIRST_STEP ? (
          <StepOne
            boxTypes={props.boxTypes}
            boxTypeSelected={props.boxTypeSelected}
            setBoxTypeSelected={props.setBoxTypeSelected}
          />
        ) : null}
        {/* {props.step === SECOND_STEP ?
                    <StepItemCategory
                        itemCategories={props.itemCategories}
                        itemCategorySelected={props.itemCategorySelected} setItemCategorySelected={props.setItemCategorySelected}
                    /> : null} */}
        {props.step === SECOND_STEP ? (
          <StepTwo
            newAddress={props.originNewAddress}
            setNewAddress={props.setOriginNewAddress}
            addresses={props.originAddresses}
            selectedAddress={props.selectedOriginAddress}
            setSelectedAddress={props.setSelectedOriginAddress}
            name={props.originName}
            setName={props.setOriginName}
            lastName={props.originLastName}
            setLastName={props.setOriginLastName}
            phone={props.originPhone}
            setPhone={props.setOriginPhone}
            email={props.originEmail}
            setEmail={props.setOriginEmail}
            address1={props.originAddress1}
            setAddress1={props.setOriginAddress1}
            address2={props.originAddress2}
            setAddress2={props.setOriginAddress2}
            postcode={props.originPostcode}
            setIsValidZipCode={props.setOriginIsValidZipCode}
            setPostcode={props.setOriginPostcode}
            countries={props.originCountries}
            states={props.originStates}
            setStates={props.setOriginStates}
            countrySelected={props.originCountrySelected}
            setCountrySelected={props.setOriginCountrySelected}
            locality={props.originLocality}
            setOriginLocality={props.setOriginLocality}
            stateSelected={props.originStateSelected}
            setStateSelected={props.setOriginStateSelected}
            // lat={props.originLat} setLat={props.setOriginLat}
            // lng={props.originLng} setLng={props.setOriginLng}
            // marker={props.originMarker} setMarker={props.setOriginMarker}
          />
        ) : null}
        {props.step === THIRD_STEP ? (
          <StepThree
            newAddress={props.destinationNewAddress}
            setNewAddress={props.setDestinationNewAddress}
            addresses={props.destinationAddresses}
            selectedAddress={props.selectedDestinationAddress}
            setSelectedAddress={props.setSelectedDestinationAddress}
            name={props.destinationName}
            setName={props.setDestinationName}
            lastName={props.destinationLastName}
            setLastName={props.setDestinationLastName}
            phone={props.destinationPhone}
            setPhone={props.setDestinationPhone}
            email={props.destinationEmail}
            setEmail={props.setDestinationEmail}
            address1={props.destinationAddress1}
            setAddress1={props.setDestinationAddress1}
            address2={props.destinationAddress2}
            setAddress2={props.setDestinationAddress2}
            postcode={props.destinationPostcode}
            setPostcode={props.setDestinationPostcode}
            countries={props.destinationCountries}
            states={props.destinationStates}
            setStates={props.setDestinationStates}
            countrySelected={props.destinationCountrySelected}
            setCountrySelected={props.setDestinationCountrySelected}
            locality={props.destinationLocality}
            setDestinationLocality={props.setDestinationLocality}
            city={props.destinationCities}
            stateSelected={props.destinationStateSelected}
            setStateSelected={props.setDestinationStateSelected}
            branchCopacabana={props.destinationBranchCopacabana}
            setBranchCopacabana={props.setDestinationBranchCopacabana}
            selectecBranches={props.selectecBranches}
            setSelectecBranches={props.setSelectecBranches}
            branchCopacabanaSelectedST={props.destinationBranchCopacabanaSelectedSThree}
            setBranchCopacabanaSelectedST={
              props.setDestinationBranchCopacabanaSelectedSThree
            }
            selectedBranchInfoSThree={selectedBranchInfoSThree}
            setSelectedBranchInfoSThree={setSelectedBranchInfoSThree}
            optionsPaP={props.optionsPaP}
            servicePaPST={props.servicePaPST} setServicePaPST={props.setServicePaPST}
            matchCityST={props.matchCityST} setMatchCityST={props.setMatchCityST}
            // lat={props.destinationLat} setLat={props.setDestinationLat}
            // lng={props.destinationLng} setLng={props.setDestinationLng}
            // marker={props.destinationMarker} setMarker={props.setDestinationMarker}
          />
        ) : null}

        {props.step === SPECIAL_STEP ? (
          <AddressForm
            // currentPosition={() => { }}
            onChange={() => {}}
            // onMapPress={onMapPress}
            onToggle={true}
            // addresses={[]}
            name={props.name}
            phone={props.phone}
            email={props.email}
            address1={props.address1}
            address2={props.address2}
            postcode={props.postcode}
            countrySelected={props.countrySelected}
            countries={props.destinationCountries}
            locality={props.locality}
            listCities={props.city}
            suggestions={[]}
            setSuggestions={() => {}}
            setDestinationLocality={props.setDestinationLocality}
            stateSelected={props.stateSelected}
            states={props.destinationStates}
            branchCopacabana={props.destinationBranchCopacabana}
            setBranchCopacabana={props.setDestinationBranchCopacabana}
            branchCopacabanaSelectedSS={props.destinationBranchCopacabanaSelectedSSpecial}
            setBranchCopacabanaSelectedSS={
              props.setDestinationBranchCopacabanaSelectedSSpecial
            }
            selectedBranchInfoSSpecial={selectedBranchInfoSSpecial}
            setSelectedBranchInfoSSpecial={setSelectedBranchInfoSSpecial}
            optionsPaP={props.optionsPaP}
            servicePaPSS={props.servicePaPSS}
            setServicePaPSS={props.setServicePaPSS}
            matchCitySS={props.matchCitySS} setMatchCitySS={props.setMatchCitySS}
            step={3}
            //     centerMap={{
            //     lat: -17.126697412577503,
            //     lng: -65.37759186875
            // }}
            addresses={props.destinationAddresses}
            selectedAddress={props.selectedDestinationAddress}
            isSpecialStep={true}
          />
        ) : null}

        {props.step === FOURTH_STEP ? (
          <StepFour
            items={props.items}
            setItems={props.setItems}
            // attachments={props.attachments} setAttachments={props.setAttachments}
            additionalNote={props.additionalNote}
            setAdditionalNote={props.setAdditionalNote}
            // weight={props.weight} setWeight={props.setWeight}
            // weightMeasure={props.weightMeasure} setWeightMeasure={props.setWeightMeasure}
            // itemTypes={props.itemTypes}
            itemCategories={props.itemCategories}
            itemCategorySelected={props.itemCategorySelected}
            setItemCategorySelected={props.setItemCategorySelected}
            boxTypes={props.boxTypes}
            boxTypeSelected={props.boxTypeSelected}
            boxTypeSelectedPrevious={props.boxTypeSelectedPrevious}
            setBoxTypeSelectedPrevious={props.setBoxTypeSelectedPrevious}
          />
        ) : null}
        {/* {props.step === FIFTH_STEP ? (
          <StepFive
            receptionTypes={props.receptionTypes}
            receptionTypeSelected={props.receptionTypeSelected}
            setReceptionTypeSelected={props.setReceptionTypeSelected}
            boxTypes={props.boxTypes}
            boxTypeSelected={props.boxTypeSelected}
          />
        ) : null} */}
        {props.step === FIFTH_STEP ? (
          <StepSix
            itemCategories={props.itemCategories}
            itemCategorySelected={props.itemCategorySelected}
            boxTypes={props.boxTypes}
            boxTypeSelected={props.boxTypeSelected}
            receptionTypes={props.receptionTypes}
            receptionTypeSelected={props.receptionTypeSelected}
            additionalNote={props.additionalNote}
            items={props.items}
            setItems={props.setItems}
            itemTypes={props.itemTypes}
            attachments={props.attachments}
            originNewAddress={props.originNewAddress}
            originAddresses={props.originAddresses}
            selectedOriginAddress={props.selectedOriginAddress}
            originName={props.originName}
            originPhone={props.originPhone}
            originEmail={props.originEmail}
            originAddress1={props.originAddress1}
            originAddress2={props.originAddress2}
            originPostcode={props.originPostcode}
            originCountries={props.originCountries}
            originStates={props.originStates}
            originCountrySelected={props.originCountrySelected}
            originLocality={props.originLocality}
            originStateSelected={props.originStateSelected}
            // originLat={props.originLat}
            // originLng={props.originLng}
            destinationNewAddress={props.destinationNewAddress}
            destinationAddresses={props.destinationAddresses}
            selectedDestinationAddress={props.selectedDestinationAddress}
            destinationName={props.destinationName}
            destinationPhone={props.destinationPhone}
            destinationEmail={props.destinationEmail}
            destinationAddress1={props.destinationAddress1}
            destinationAddress2={props.destinationAddress2}
            destinationPostcode={props.destinationPostcode}
            destinationCountries={props.destinationCountries}
            destinationStates={props.destinationStates}
            destinationCountrySelected={props.destinationCountrySelected}
            destinationLocality={props.destinationLocality}
            destinationStateSelected={props.destinationStateSelected}
            destinationBranchCopacabana={props.destinationBranchCopacabana}
            destinationBranchCopacabanaSelectedSThree={props.destinationBranchCopacabanaSelectedSThree}
            destinationBranchCopacabanaSelectedSSpecial={props.destinationBranchCopacabanaSelectedSSpecial}
            optionsPaP={props.optionsPaP}
            servicePaPST={props.servicePaPST}
            servicePaPSS={props.servicePaPSS}
            // destinationLat={props.destinationLat}
            // destinationLng={props.destinationLng}
            weight={props.weight}
            weightMeasure={props.weightMeasure}
            totalAmount={props.totalAmount}
          />
        ) : null}
        <div className="custom-block__row custom-block--buttons">
          {props.step > 0 ? (
            <Button className="wizard-back-button" onClick={() => previous()}>
              Atrás
            </Button>
          ) : (
            <React.Fragment />
          )}
          {props.step === 6 ? (
            <Button
              className="stripe-payment-button"
              onClick={() => props.onPaymentConfirm()}
            >
              Proceda a pagar <FontAwesomeIcon icon={["fas", "money-check"]} />
            </Button>
          ) : (
            <Button className="wizard-next-button" type="submit" name="next">
              Siguiente
            </Button>
          )}
        </div>
      </div>
    </form>
  );
}

export default WizardSteps;
