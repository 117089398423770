import React, { useEffect, useState } from "react";

import * as actions from "../../actions/global_parameters.actions";
import * as mapUtils from "../../utils/googleMaps";
import AddressList from "./AddressList";
import AddressForm from "./AddressForm";

const StepThree = props => {
  const [suggestions, setSuggestions]=useState([])

    useEffect(() => {
        if (props.addresses.length <= 0) {
            onFormToggle();
        }
        console.log("props");
        console.log(props);
    }, []);

    const clues = {
        sectionTitle: "Dirección de destino",
        contact: {
            title: "Nota",
            content: "Proporcionanos información del contacto que recibira el paquete."
        },
        originAddress: {
            title: "Nota",
            content: "Proporcionanos información de la dirección de donde llevaremos el paquete.",
        },
        map: {
            title: "Nota",
            content: "Debes proporcionarnos una ubicación en el mapa, haciendo click."
        }
    }

    const onFormToggle = () => {
        props.setNewAddress(!props.newAddress);
    }

    const onSelectedAddressChange = event => {
        props.setSelectedAddress(parseInt(event.target.value));
    }

    const onChange = event => {
        event.persist();
        const { id, value } = event.target;
        switch (id) {
          case "name":
            props.setName(value);
            break;
          case "lastName":
            props.setLastName(value);
            break;
          case "phone":
            props.setPhone(value);
            break;
          case "email":
            props.setEmail(value);
            break;
          case "address1":
            props.setAddress1(value);
            break;
          case "address2":
            props.setAddress2(value);
            break;
          case "postcode":
            // mapUtils.getLatLngFromPostcode(value, onMapPress, props.setAddress1);
            props.setPostcode(value);
            break;
          case "locality":
            props.setDestinationLocality(value);

            let matches = [];
            if (value.length > 0) {
              matches = props.city.filter((c) => {
                const regex = new RegExp(`${value}`, "gi");
                return c.city.match(regex);
              });
            }
            setSuggestions(matches);

            if (
              props.branchCopacabanaSelectedST !== 0 &&
              props.selectedBranchInfoSThree.attributes.city.toUpperCase() ===
                value.toUpperCase()
            )
              props.setMatchCityST(true);
            else props.setMatchCityST(false);


            break;
          case "country":
            props.setCountrySelected(value);
            if (!value) {
              props.setStates();
              props.setStateSelected(0);
              props.setSelectecBranches();
              props.setBranchCopacabanaSelectedST(0);
              props.setSelectedBranchInfoSThree()
              props.setMatchCityST(false)
              props.setServicePaPST(0)
            } else {
              actions
                .globalParameters({ filters: ["states"], country_id: value })
                .then((response) => {
                  if (response.type === "SUCCESSFULLY") {
                    const { payload } = response;
                    props.setStates(
                      payload.states.data.map((state) => {
                        return {
                          id: state.id,
                          name: state.attributes.name,
                        };
                      })
                    );

                    console.log("Case Country");
                    console.log(props);
                  }
                })
                .catch((error) => {
                  console.log(error.response);
                });
            }
            break;
          case "state":
            props.setStateSelected(value);

            if (!value) {
              props.setStateSelected(0);
              props.setSelectecBranches();
              props.setBranchCopacabanaSelectedST(0);
              props.setSelectedBranchInfoSThree();
              props.setMatchCityST(false);
              props.setServicePaPST(0);
            } else {
              let dptoSelected =
                event.target.options[event.target.selectedIndex].text;
              let branches = props.branchCopacabana.filter(
                (b) => b.attributes.dpto == dptoSelected
              );
              props.setSelectecBranches(branches);
              props.setBranchCopacabanaSelectedST(0);
              props.setSelectedBranchInfoSThree();
              props.setMatchCityST(false);
              props.setServicePaPST(0);
            }
            console.log("Viendo Sucursales");
            console.log(props);

            break;
          case "branchCopacabana":
            props.setBranchCopacabanaSelectedST(value);

            if (!value) {
              props.setSelectedBranchInfoSThree();
              props.setMatchCityST(false);
              props.setServicePaPST(0);
            } else {
              let city;
              props.branchCopacabana.map((b) => {
                if (b.id == value) {
                  props.setSelectedBranchInfoSThree(b);
                  city = b.attributes.city;
                }
              });

              console.log("Case Branches");
              console.log(props);
              console.log(city, props.locality);
              if (city.toUpperCase() === props.locality.toUpperCase())
                props.setMatchCityST(true);
              else {
                props.setMatchCityST(false);
                props.setServicePaPST(0);
              }
            }
            break;
          default:
            break;
        }
    }

    // const onMapPress = ({ lat, lng }) => {
    //     props.setLat(lat);
    //     props.setLng(lng);
    //     props.setMarker([{
    //         position: { lat, lng },
    //         item: { name: 'New Location' }
    //     }]);
    // }

    return (
        !props.newAddress ?
            <AddressList
                options={props.addresses}
                value={props.selectedAddress}
                onChange={onSelectedAddressChange}
                onToggle={onFormToggle} /> :
            <AddressForm
                // currentPosition={() => { }}
                onChange={onChange}
                // onMapPress={onMapPress}
                onToggle={onFormToggle}
                addresses={props.addresses}
                name={props.name}
                lastName={props.lastName}
                phone={props.phone}
                email={props.email}
                address1={props.address1}
                address2={props.address2}
                postcode={props.postcode}
                countrySelected={props.countrySelected}
                countries={props.countries}
                locality={props.locality}
                listCities={props.city}
                suggestions={suggestions}
                setSuggestions={setSuggestions}
                setDestinationLocality={props.setDestinationLocality}
                stateSelected={props.stateSelected}
                states={props.states}
                selectecBranches={props.selectecBranches}
                branchCopacabanaSelectedST={props.branchCopacabanaSelectedST}
                setBranchCopacabanaSelectedST={props.setBranchCopacabanaSelectedST}
                selectedBranchInfoSThree={props.selectedBranchInfoSThree}
                setSelectedBranchInfoSThree={props.setSelectedBranchInfoSThree}
                optionsPaP={props.optionsPaP}
                servicePaPST={props.servicePaPST} setServicePaPST={props.setServicePaPST}
                matchCityST={props.matchCityST} setMatchCityST={props.setMatchCityST}
                // lat={props.lat}
                // lng={props.lng}
                // marker={props.marker}
                clues={clues}
                step={3}
            //     centerMap={{
            //     lat: -17.126697412577503,
            //     lng: -65.37759186875
            // }}
            isSpecialStep={false}
            />
    )
}

export default StepThree;
