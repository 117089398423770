import React from "react";

import RadioButton from "./../../components/Inputs/RadioButton";
import Button from "./../../components/Buttons/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AddressList = props => {
    const onToggle = () => {
        props.onToggle();
    }

    return (
      <React.Fragment>
        <Button
          className="wizard-add-address d-flex align-items-center"
          onClick={() => onToggle()}
        >
          <FontAwesomeIcon icon={["fas", "address-book"]} size="lg" />
          Agregar Nueva Dirección
        </Button>
        <div className="container-list-radios mt-5">
          <h5 className="d-flex justify-content-center">
            <strong>Direcciones previamente usadas</strong>
          </h5>
          <RadioButton
            options={props.options}
            name="addresses"
            value={props.value}
            className="radio-button"
            onChange={props.onChange}
            required={true}
          />
        </div>
      </React.Fragment>
    );
}

export default AddressList;
