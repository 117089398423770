import axios from "axios";

import * as apiRoutes from "../config/apiRoutes";

export const orderTracking = async (params) => {
    const res = await axios.get(apiRoutes.TRACKING_LOG, {
        params: params,
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}