import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import "./styles.scss";

import * as actions from "../../actions/orders.actions";
import * as paths from "../../config/paths";
import { ifUnauthorized } from "../../utils/authentication";
import Preloader from "../../components/Preloader";

function Orders(props) {
    const [showPreloader, setShowPreloader] = useState(true);
    const [orders, setOrders] = useState([]);
    const history = useHistory();

    useEffect(() => {
        ifUnauthorized(history, getOrders);
    }, []);

    const getOrders = () => {
        actions.myOrders()
            .then(response => {
                if (response.type === "SUCCESSFULLY") {
                    console.log(response);
                    setOrders(response.payload.data.map(order => {
                        return {
                            id: order.id,
                            datetime: order.attributes.order_datetime,
                            orderNumber: order.attributes.order_number,
                            itemCategory: order.relationships.item_category.meta.data.attributes.name,
                            orderType: order.relationships.order_type.meta.data.attributes.name,
                            orderState: {
                                name: order.relationships.order_state.meta.data.attributes.name,
                                color: order.relationships.order_state.meta.data.attributes.color,
                            },
                            moneyTransaction: {
                                name: order.relationships.money_transaction.meta.data.relationships.transaction_state.meta.data.attributes.name,
                                color: order.relationships.money_transaction.meta.data.relationships.transaction_state.meta.data.attributes.color,
                            },
                        }
                    }));
                    setShowPreloader(false);
                }
            })
            .catch(error => {
                console.log(error);
                setShowPreloader(false);
            });
    }

    const goToOrderTracking = (orderNum) => {
        history.push({
            pathname: paths.TRACKING,
            search: `?order_number=${orderNum}`,
        })
    }

    const goToOrderDetail = (id) => {
        history.push(paths.ORDERS.DETAIL.PATH(id))
    }

    return (
        <div className="div-tag div-tag--order-border-top">
            {showPreloader && <Preloader />}
            {console.log("ORDER LIST")}
            {console.log(props)}
            <div className="div-tag div-tag--order-container">
                <h2 className="h2-tag h2-tag--order-title-1">Mis Órdenes</h2>
                <div className="div-tag div-tag--orders-list">
                    {orders.map((order, index) => {
                        return (
                            <div key={index} className="div-tag div-tag--order-block">
                                <div className="div-tag--order-info-block">
                                    <p className="p-tag p-tag--order-1">
                                        {order.orderNumber}
                                    </p>
                                    <p className="p-tag p-tag--order-2">{dayjs(order.datetime).format("DD/MM/YYYY - hh:mm A")}</p>
                                    <p className="p-tag p-tag--order-2"><b>Tipo de contenido:</b> {order.itemCategory}</p>
                                    <p className="p-tag p-tag--order-2"><b>Tipo de orden:</b> {order.orderType}</p>
                                    <p className="p-tag p-tag--order-2"><b>Estado de la orden:</b> {order.orderState.name}</p>
                                    <p className="p-tag p-tag--order-2"><b>Estado de la transacción:</b> {order.moneyTransaction.name}</p>
                                </div>
                                <div className="div-tag div-tag--order-settings">
                                    <a
                                        className="a-tag a-tag--order-tracking"
                                        onClick={() => goToOrderTracking(order.orderNumber)} >
                                        Ver seguimiento
                                    </a>
                                    <div className="div-tag div-tag--order-settings-separator"></div>
                                    <a
                                        className="a-tag a-tag--order-detail"
                                        onClick={() => goToOrderDetail(order.id)}>
                                        Detalle
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default Orders;
