import axios from "axios";

import * as apiRoutes from "../config/apiRoutes";

export const myOrders = async () => {
    const res = await axios.get(apiRoutes.GET_ORDERS, {
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}

export const getOrderDetail = async (params) => {
    const res = await axios.get(apiRoutes.ORDER_DETAIL, {
        params: params,
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        return { type: type, payload: payload };
    }
}

export const getOrderDetailActions = async (params) => {
    const res = await axios.get(apiRoutes.ORDER_DETAIL_ACTIONS, {
        params: params,
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        return { type: type, payload: payload };
    }
}

export const getOrderTrackingActions = async (params) => {
    const res = await axios.get(apiRoutes.ORDER_TRACKING_ACTIONS, {
        params: params,
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        return { type: type, payload: payload };
    }
}

export const changeOrderState = async (params) => {
    const res = await axios.post(apiRoutes.CHANGE_ORDER_STATE, params, {
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        return { type: type, payload: payload };
    }
}