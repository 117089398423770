export const HOME = "/";
export const AUTH = {
    LOGIN: "/auth/login",
    WIZARD: "/auth/wizard"
};
export const PROFILE = "/profile";
export const CONTACT = "/contact";
export const SUCCESS = "/success";
export const CANCEL = "/cancel";
export const ORDERS = {
    LIST: "/orders",
    DETAIL: {
        ROUTE: "/orders/:id",
        PATH: (id) => `/orders/${id}`
    },
    SEND:{
      ROUTE: "/send/:id",
      PATH:(id) => `/send/${id}`
    }
};
export const ADDRESSES = {
    LIST: "/addresses",
    NEW: "/addresses/new",
    EDIT: {
        ROUTE: "/addresses/:id/edit",
        PATH: (id) => `/addresses/${id}/edit`
    }
};
export const TRACKING = "/tracking";
