import axios from "axios";

import * as apiRoutes from "../config/apiRoutes";

export const myAddresses = async () => {
    const res = await axios.get(apiRoutes.GET_ADDRESSES, {
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}

export const getAddressDetail = async (params) => {
    const res = await axios.get(apiRoutes.ADDRESS_DETAIL, {
        params: params,
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}

export const updateAddress = async (params) => {
    const res = await axios.post(apiRoutes.UPDATE_ADDRESS, params, {
        headers: {
            "authorization": `bearer ${localStorage.getItem("user_token")}`
        }
    });
    const { type, payload } = res.data;
    if (type === "SUCCESSFULLY") {
        return { type: type, payload: payload };
    } else {
        throw { type: type, payload: payload };
    }
}